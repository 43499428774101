<template lang="pug">
    #app(:class="[{ 'helper-active': tipsActive }]")
        transition(name="fadeIn" mode="out-in")
            // Если у юзера есть токен в localStorage
            // гарантируем, что на странице данные его юзера готовы
            components(:is="layout" v-if="userReady")
            loading(v-else)

        portal-target(name="modal-container")

        transition(name="fadeIn")
            tips(v-if="tipsActive")

        app-message(
            v-show="msgBoxStatus",
            :title="msgBoxTitle",
            :message="msgBoxText",
            :type="msgBoxType"
            :eventId="msgBoxEventId"
        )

        description-popup
        template(v-if="isUserAuth && myUser.uuid")
            referral-modal
            image-modal
            promocode-modal
            report-modal(:event-id="msgBoxEventId")
            payout-modal
            a-i-subscriptions-modal
            subscription-modal
            face-swap-choose-photo-modal
            unsubscribe-modal
            start-assistant-modal
            midjourney-modal
            portal-target(name="modals")
</template>

<script>
import mainLayout from "./layouts/main-layout";
import analyzeLayout from "./layouts/analyze-layout";
import welcomeLayout from "./layouts/welcome-layout/welcome-layout";
import AILayout from "./layouts/ai-layout.vue";
import loginLayout from "./layouts/login-layout";
import errorLayout from "./layouts/error-layout";
import landingLayout from "./layouts/landing-layout";
import AppMessage from "./components/app/app-message/app-message";
import { mapGetters } from "vuex";
import ReferralModal from "./components/modals/referral-modal/referral-modal";
import PayoutModal from "./components/modals/payout-modal/payout-modal";
import SubscriptionModal from "@/components/modals/subscription-modal/subscription-modal";
import Tips from "./components/tips/tips";
import { onlineMixin } from "@/mixins/online.mixin";
import DescriptionPopup from "@/components/app/description-popup/description-popup";
import ReportModal from "@/components/modals/report-modal/report-modal.vue";
import AISubscriptionsModal from "@/components/modals/ai-subscriptions-modal/ai-subscriptions-modal.vue";
import ImageModal from "./components/modals/image-modal/image-modal.vue";
import PromocodeModal from "./components/modals/promocode-modal/promocode-modal.vue";
import UnsubscribeModal from "./components/modals/unsubscribe-modal/unsubscribe-modal.vue";
import getMySubscriptionsQuery from "@/graphql/queries/getMySubscriptions.query.graphql";
import increaseCreditSubscription from "@/graphql/subscriptions/increaseCredit.subscription.graphql";

import gql from "graphql-tag";
import StartAssistantModal from "@/components/modals/start-assistant/start-assistant.vue";
import FaceSwapChoosePhotoModal from "@/components/modals/face-swap-choose-photo/face-swap-choose-photo.vue";
import MidjourneyModal from "@/components/modals/midjourney-modal/midjourney-modal.vue";
import botsListQuery from "@/graphql/queries/botsList.query.graphql";

export default {
    name: "App",
    components: {
        MidjourneyModal,
        FaceSwapChoosePhotoModal,
        StartAssistantModal,
        UnsubscribeModal,
        PromocodeModal,
        ImageModal,
        AISubscriptionsModal,
        ReportModal,
        DescriptionPopup,
        Tips,
        SubscriptionModal,
        PayoutModal,
        ReferralModal,
        AppMessage,
        mainLayout,
        analyzeLayout,
        landingLayout,
        AILayout,
        loginLayout,
        welcomeLayout,
        errorLayout,
    },
    mixins: [onlineMixin],
    metaInfo() {
        return {
            title: "Merlin AI — быстрый доступ к ChatGPT 4.0, Midjourney, Stable Diffusion и Pika Labs в одном окне",
            meta: [
                {
                    name: "description",
                    content:
                        "Без vpn и скачиваний приложений. Создавай тексты, уникальные изображения и качественную анимацию с помощью нейросетей.",
                    vmid: "description",
                },
                {
                    property: "og:description",
                    content:
                        "Без vpn и скачиваний приложений. Создавай тексты, уникальные изображения и качественную анимацию с помощью нейросетей.",
                    vmid: "og_description",
                },
                {
                    name: "keywords",
                    content:
                        "MERLIN, AI, искусственный интеллект, характер, темперамент, мышление, ММА, " +
                        "совместимость, похожесть",
                    vmid: "keywords",
                },
                {
                    property: "og:site_name",
                    content: "Merlin AI искусственный интеллект",
                },
                { property: "og:locale", content: "ru_RU" },
                {
                    property: "og:url",
                    content: "https://merlinface.com" + this.$route.path,
                },
                {
                    skip: typeof this.name === "undefined",
                    property: "og:type",
                    content: "website",
                    vmid: "og_type",
                },
            ],
            link: [
                {
                    rel: "canonical",
                    href: document.location.origin + this.$route.path,
                },
                {
                    rel: "apple-touch-icon",
                    sizes: "57x57",
                    href: "/icons/apple-icon-57x57.png",
                },
                {
                    rel: "apple-touch-icon",
                    sizes: "60x60",
                    href: "/icons/apple-icon-60x60.png",
                },
                {
                    rel: "apple-touch-icon",
                    sizes: "72x72",
                    href: "/icons/apple-icon-72x72.png",
                },
                {
                    rel: "apple-touch-icon",
                    sizes: "76x76",
                    href: "/icons/apple-icon-76x76.png",
                },
                {
                    rel: "apple-touch-icon",
                    sizes: "114x114",
                    href: "/icons/apple-icon-114x114.png",
                },
                {
                    rel: "apple-touch-icon",
                    sizes: "120x120",
                    href: "/icons/apple-icon-120x120.png",
                },
                {
                    rel: "apple-touch-icon",
                    sizes: "144x144",
                    href: "/icons/apple-icon-144x144.png",
                },
                {
                    rel: "apple-touch-icon",
                    sizes: "152x152",
                    href: "/icons/apple-icon-152x152.png",
                },
                {
                    rel: "apple-touch-icon",
                    sizes: "180x180",
                    href: "/icons/apple-icon-180x180.png",
                },
                {
                    rel: "icon",
                    sizes: "192x192",
                    type: "image/png",
                    href: "/icons/android-icon-192x192.png",
                },
                {
                    rel: "icon",
                    sizes: "16x16",
                    type: "image/png",
                    href: "/icons/favicon-16x16.png",
                },
                {
                    rel: "icon",
                    sizes: "32x32",
                    type: "image/png",
                    href: "/icons/favicon-32x32.png",
                },
                {
                    rel: "icon",
                    sizes: "96x96",
                    type: "image/png",
                    href: "/icons/favicon-96x96.png",
                },
                {
                    rel: "manifest",
                    href: "/manifest.json",
                },
            ],
        };
    },
    data() {
        return {
            showInstallAppModal: false,
            userReady: false,
        };
    },
    computed: {
        ...mapGetters({
            userPerson: "user/person",
            msgBoxTitle: "msgBoxTitle",
            msgBoxText: "msgBoxText",
            msgBoxType: "msgBoxType",
            msgBoxEventId: "msgBoxEventId",
            msgBoxStatus: "msgBoxStatus",
            celebrity: "user/celebrity",
            tipsActive: "tips/show",
            token: "auth/token",
        }),
        layout: function () {
            return this.$route.meta.layout + "Layout";
        },
    },
    watch: {
        userReady(status) {
            if (status) {
                if (this.isUserAuth) {
                    this.getOnBoardingTypesFromMixin();
                }
            }
        },
        token(token) {
            if (token) {
                this.getOnBoardingTypesFromMixin();
            }
        },
    },
    beforeCreate() {
        this.$store.dispatch("setWindowWidth", window.innerWidth);
    },
    async created() {
        document.documentElement.style.setProperty(
            "--vh",
            `${window.innerHeight * 0.01}px`,
        );
        window.addEventListener("resize", () => {
            this.$store.dispatch("setWindowWidth", window.innerWidth);
            document.documentElement.style.setProperty(
                "--vh",
                `${window.innerHeight * 0.01}px`,
            );
        });
    },
    async mounted() {
        const authToken = this.$store.getters["auth/token"];
        const lang = localStorage.getItem("lang");
        if (lang) {
            this.$root.$i18n.locale = lang;
        } else {
            localStorage.setItem("lang", "ru");
        }

        if (authToken) {
            await this.loadMyUser();
            await this.getMySubscriptions();
            await this.getMyBots();
            await this.subscribeToIncreaseMessage();
            if (!this.userPerson.uuid) {
                this.$msgBox(
                    "Сессия истекла",
                    "В целях безопасности ваша сессия была завершена. Пожалуйста, войдите заново.",
                );
                await this.$router.push({ name: "Login" });
            } else {
                await this.redirectController();
            }
        }

        const redirectAfterPay = this.$cookies.get("redirectAfterPay");

        if (redirectAfterPay) {
            this.$cookies.remove("redirectAfterPay");
            this.$cookies.remove("redirectAfterPay", "/", "merlinface.com");
            window.location.href = `https://${redirectAfterPay}.merlinface.com/welcome/pay-check`;
        }

        this.userReady = true;
    },
    destroyed() {
        window.removeEventListener("resize");
    },
    methods: {
        async getMyBots() {
            await this.$apollo
                .query({
                    query: gql(botsListQuery),
                    variables: {
                        page: 1,
                        itemsPerPage: 20,
                        onlyMyBots: true,
                        published: false,
                    },
                    fetchPolicy: "no-cache",
                })
                .then((r) => {
                    this.$store.dispatch("user/set", {
                        name: "assistantsCount",
                        value: r.data.botsList.list.length,
                    });
                });
        },
        async decodeToken(t) {
            console.log("decode token");
            const jwtToken = t;

            const tt = jwtToken.split(".");

            // Декодируем base64-кодированную полезную нагрузку
            const decodedPayload = JSON.parse(atob(tt[1]));

            if (decodedPayload.person_id) {
                console.log("new token");
                return;
            }
            console.log("old token");

            await this.$store
                .dispatch("auth/obtainNewToken", {
                    gatewayClient: this.$apollo,
                })
                .then((response) => {
                    const responseData = response.data.refreshToken;
                    this.$store.dispatch("auth/successAuth", {
                        jwt: responseData.jwt,
                        refresh_token: responseData.refresh_token,
                        user_id: responseData.user_uuid,
                    });
                    return responseData.jwt;
                });
        },
        async subscribeToIncreaseMessage() {
            const observer = this.$apollo.subscribe({
                query: gql(increaseCreditSubscription),
                variables: {
                    userId: this.myUser.uuid,
                },
                client: "chatClient",
            });

            const state = this;

            observer.subscribe({
                next(r) {
                    const { count, isFirstMessage } = r.data.increaseCredit;
                    state.$store.dispatch(
                        "user/increaseMessageAttempts",
                        count
                    );
                    if (isFirstMessage) {
                        state.VKGoal("Konv_2_action");
                    }
                },
                error(error) {
                    console.log(error);
                },
            });
        },
        async getMySubscriptions() {
            await this.$apollo
                .query({
                    query: gql(getMySubscriptionsQuery),
                })
                .then((r) => {
                    this.$store.dispatch("user/set", {
                        name: "mySubscriptions",
                        value: r.data.getMySubscriptions,
                    });
                });
        },
        signout: function () {
            this.$store.dispatch("auth/signout").then(() => {
                this.$router.push(this.$router.resolve({ name: "Login" }).href);
                this.$router.go();
            });

            // clear client state, reference:
            // https://www.apollographql.com/docs/react/networking/authentication/#reset-store-on-logout
            // TODO: test behaviour on production, mb replace resetStore() to clearStore()?
            this.$apollo.getClient().resetStore();
        },
    },
};
</script>

<style lang="scss">
@import "styles";
@import "~vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css";

.ps__thumb-y {
    width: 2px;
    background: $gold;
}

.ps .ps__rail-x:hover,
.ps .ps__rail-y:hover,
.ps .ps__rail-x:focus,
.ps .ps__rail-y:focus,
.ps .ps__rail-x.ps--clicking,
.ps .ps__rail-y.ps--clicking {
    background: transparent;
}

.ps__rail-y:hover > .ps__thumb-y,
.ps__rail-y:focus > .ps__thumb-y,
.ps__rail-y.ps--clicking .ps__thumb-y {
    background: $gold;
}
</style>
