/* eslint-disable */

export default {
    "newbie": "Без имени",
    "logout": "Выход",
    "days": "дн.",
    "onBoardingMixin": {
        "title-1": "Определение психотипа",
        "title-2": "Профтипирование",
        "description-1":
            "После того как Merlin AI определит Вам психотип, Вы и все гости вашей страницы будут его видеть. А также Merlin начнет рекомендовать Вас другим пользователям социальной сети",
        "description-2": "Вы действительно хотите пройти профтипирование?",
    },
    "errors": {
        "unknown-error": "Неизвестная ошибка",
        "error": "Произошла ошибка",
        "unknownError": "Неизвестная ошибка",
        "attention": "Внимание",
        "wrongPhotoTitle": "Фото не подошло",
        "noAccess": "Нет доступа",
        "alreadyFriendsTitle": "Вы уже друзья!",
        "info": "Информация",
        "reEnter": "Перезайдите в свой аккаунт или свяжитесь с нами.",
        "notEnoughMoneyTitle": "Недостаточно средств",
        "compareNotAllowedTitle": "Сравнение невозможно",
        "retry": "Произошла ошибка, повторите попытку позже.",
        "noAccessText":
            "Вы пытаетесь воспользоваться функцией, которая вам недоступна.",
        "personDataNotReceived":
            "Произошла ошибка при получении данных личности, возможно Вам потребуется перезагрузить страницу.",
        "wrongProfilePhoto":
            "Фото в профиль не подходит, загрузите другое фото. Обратите внимание на эталонные варианты.",
        "wrongPhotoToTheRight":
            "Вы повернули голову слишком сильно вправо, попробуйте еще раз.",
        "wrongPhotoToTheLeft":
            "Фото не подошло, вы повернули голову слишком сильно влево, попробуйте еще раз.",
        "wrongPhotoToTheBottom":
            "Фото не подошло, вы наклонили голову слишком низко, попробуйте еще раз.",
        "wrongPhotoFewPersons":
            "На фото обнаружено несколько лиц, попробуйте обрезать фотографию, оставив только нужное лицо",
        "wrongPhotoToTheUp":
            "Фото не подошло, вы подняли голову слишком высоко, попробуйте еще раз.",
        "wrongPhotoSame":
            "Вы загрузили одинаковые фото в анфас и профиль, загрузите другое фото",
        "wrongPhotoFeatures":
            "Не удалось получить черты лица с ваших фотографий. Пожалуйста замените обе фотографии.",
        "wrongPhotoYouAreNot":
            "На новой фотографии не было найдено ваше лицо. Загрузите фото с вашим лицом",
        "wrongAnfasPhoto":
            "Фото в анфас не подходит, загрузите другое фото. Обратите внимание на эталонные варианты.",
        "wrongCelebrityPhoto":
            "При анализе фотографий выбранной личности произошла ошибка. Как только пользователь загрузит корректные фотографии - сравнение и описание станут доступны",
        "photoNotUploaded":
            "Не удалось загрузить фотографию, попробуйте еще раз позже...",
        "myPhotoNotUploaded":
            "Не удалось загрузить ваши фотографии. Пожалуйста, обновите страницу.",
        "paymentsListNotReceived":
            "Не удается получить список платежей, попробуйте позже.",
        "incorrectPaymentData":
            "Некорректные платежные данные. Пожалуйста, попробуйте позже.",
        "paymentGateWayNotRespond":
            "Платежный шлюз не отвечает. Пожалуйста, попробуйте позже.",
        "friendStatusNotReceived":
            "Не удается получить статус друга, попробуйте позже.",
        "pageError":
            "С данной страницей возникла неизвестная проблема, попробуйте обновить страницу и попробовать совершить действие снова.",
        "friendRequestError":
            "Запрос в друзья не может быть осуществлен, попробуйте позже.",
        "alreadyFriends":
            "Если вы видите кнопку \"Добавить в друзья\", пожалуйста обновите страницу.",
        "alreadySendFriendRequest":
            "Вы уже подавали заявку ранее. Пожалуйста, дождитесь когда Ваш друг примет заявку.",
        "friendRequestDecline":
            "К сожалению, Ваша заявка в друзья была отклонена. Вы можете попробовать подать заявку еще раз через 24 часа после последней попытки.",
        "notificationsNotReceived":
            "Не удается получить список ваших уведомлений, попробуйте позже.",
        "servicePriceNotReceived":
            "Не удалось получить цену на услугу. Обновите страницу и повторите попытку",
        "needAuth": "Для совершения данного действия требуется авторизация",
        "notEnoughMoney":
            "Пожалуйста, пополните счёт любым удобным вам способом.",
        "personListNotReceived":
            "Произошла ошибка при получении списка личностей, повторите попытку позже.",
        "friendListNotReceived":
            "Произошла ошибка при получении списка друзей, повторите попытку позже.",
        "badName":
            "Выбранное имя не подошло, попробуйте другие или повторите попытку позже.",
        "nameLimit": "Смена имени доступно только раз в 24 часа.",
        "friendsCount":
            "Произошла ошибка при получении количества друзей. Повторите запрос позже.",
        "friendRequestsCount":
            "Произошла ошибка при получении количества заявок в друзья. Повторите запрос позже.",
        "compareNotAllowed":
            "Для того, чтобы сравнивать себя с другими личностями, необходимо загрузить свои фотографии",
        "photoUploadPersonIsNotFound":
            "Страница, на которую вы устанавливаете фотографию не найдена",
        "photoUploadPersonIsNotYours":
            "Страница, на которую вы пытаетесь установить фотографию не принадлежит вам.",
        "yourFaceIsNotFound":
            "Ваше лицо не было найдено на новой фотографии, загрузите пожалуйста фотографию с вашим лицом.",
        "partnerStatusNotReceived": "Невозможно получить статус партнера.",
        "uuidError":
            "Передан некорректный ID пользователя, обратитесь к администратору.",
        "alreadyPartner": "Пользователь уже является вашим партнером.",
        "waitingResponse":
            "Запрос был отправлен ранее, дождитесь решения партнера.",
        "partnerRequestDeclined":
            "Ваш запрос был отклонён, повторить запрос можно через 24 часа после последнего запроса.",
        "partnerResponseAlreadySend":
            "Запрос не найден. Возможно вы его уже приняли.",
        "partnerResponseNotSend":
            "Отправлены не верные данные. Обратитесь в техническую поддержку",
        "createDescriptionError":
            "Не удалось создать описание вашей личности. Обратитесь в техническую поддержку",
        "firstUploadAnfas": "Сначала необходимо загрузить фото в анфас",
        "descriptionNotReady":
            "Нельзя загружать фото в профиль, пока Merlin не считал черты лица с фотографии в анфас. Повторите попытку через минуту.",
        "REACH_THE_GOAL_MESSAGE_DAILY_LIMIT_REACHED":
            "Предложение закрыть цель можно отправлять не чаще чем раз в сутки",
        "BUSINESS_PROPOSAL_MESSAGE_DAILY_LIMIT_REACHED":
            "Деловые предложения можно отправлять не чаще чем раз в сутки",
        "AVATAR_LIMIT_REACHED":
            "Вы достигли лимита по загрузке фотографий в сутки. Повторите попытку завтра.",
    },
    "cookies": {
        "prefix-text": "Мы собираем ",
        "text": "Cookies",
        "btn-text": "OK",
    },
    "DescriptionPopup": {
        "learn-about": "Вы читаете про личность:",
        "light-background": "Светлый фон",
        "dark-background": "Тёмный фон",
        "start-learn-profession":
            "Начните осваивать подходящую профессию уже сегодня:",
        "start-learn-profession-btn-text": "Начать",
    },
    "MerlinDating": {
        "title":
            "Хотите получить пошаговый алгоритм для знакомства c личностью {name}",
        "subtitle": "Скачивайте наше приложение",
    },
    "FooterLinks": {
        "about": "О проекте",
        "contacts": "Контакты",
    },
    "FriendsFilter": {
        "gender": {
            "all": "Все",
            "male": "Мужчины",
            "female": "Женщины",
        },
        "age": {
            "any": "Любой",
            "interval_1": "От 18 до 24",
            "interval_2": "От 25 до 34",
            "interval_3": "От 35 до 44",
            "interval_4": "45 и старше",
        },
        "typeList": [
            {
                "id": "ALL",
                "name": "Любой",
            },
            {
                "id": "ADMINISTRATOR",
                "name": "Администратор",
            },
            {
                "id": "ACTIVIST",
                "name": "Активист",
            },
            {
                "id": "ALGORITHMIST",
                "name": "Алгоритмист",
            },
            {
                "id": "ARTIST",
                "name": "Артист",
            },
            {
                "id": "MASTERMIND",
                "name": "Вдохновитель",
            },
            {
                "id": "HEDONIST",
                "name": "Гедонист",
            },
            {
                "id": "GENERATOR",
                "name": "Генератор",
            },
            {
                "id": "DIPLOMAT_INVADER",
                "name": "Дипломат захватчик",
            },
            {
                "id": "IDEOLOGIST",
                "name": "Идейник",
            },
            {
                "id": "IDEOLOGICAL_MASTERMIND",
                "name": "Идейный вдохновитель",
            },
            {
                "id": "IDEOLOGICAL_LEADER",
                "name": "Идейный лидер",
            },
            {
                "id": "IMAGE_MAKER",
                "name": "Имиджмейкер",
            },
            {
                "id": "ENGINEER",
                "name": "Инженер",
            },
            {
                "id": "INITIATOR",
                "name": "Инициатор",
            },
            {
                "id": "INSPECTOR_PERFECTIONIST",
                "name": "Инспектор-перфекционист",
            },
            {
                "id": "INTEGRATOR",
                "name": "Интегратор",
            },
            {
                "id": "RESEARCHER",
                "name": "Исследователь",
            },
            {
                "id": "RESEARCHER_ANALYST",
                "name": "Исследователь-аналитик",
            },
            {
                "id": "COMMUNICATOR",
                "name": "Коммуникатор",
            },
            {
                "id": "CONNECTOR",
                "name": "Коннектор",
            },
            {
                "id": "CONTROLLER",
                "name": "Контроллер",
            },
            {
                "id": "CRITIC",
                "name": "Критик",
            },
            {
                "id": "LEGIONARY",
                "name": "Легионер",
            },
            {
                "id": "LEADER",
                "name": "Лидер",
            },
            {
                "id": "LOBBYIST",
                "name": "Лоббист",
            },
            {
                "id": "MASS_MEDIA_ENTERTAINER",
                "name": "Массовик затейник",
            },
            {
                "id": "MASTER_IMPLEMENTOR",
                "name": "Мастер-реализатор",
            },
            {
                "id": "MATERIALIST",
                "name": "Материалист",
            },
            {
                "id": "MAECENAS",
                "name": "Меценат",
            },
            {
                "id": "DREAMER",
                "name": "Мечтатель",
            },
            {
                "id": "MORALIST",
                "name": "Моралист",
            },
            {
                "id": "MENTOR",
                "name": "Наставник",
            },
            {
                "id": "ASSERTIVE_INVADER",
                "name": "Напористый захватчик",
            },
            {
                "id": "INFORMAL_LEADER_MANIPULATOR",
                "name": "Неформальный лидер манипулятор",
            },
            {
                "id": "INNOVATOR",
                "name": "Новатор",
            },
            {
                "id": "INNOVATOR_ENTHUSIAST",
                "name": "Новатор энтузиаст",
            },
            {
                "id": "OPTIMIZER",
                "name": "Оптимизатор",
            },
            {
                "id": "OPTIMIST",
                "name": "Оптимист",
            },
            {
                "id": "ORGANIZER",
                "name": "Организатор",
            },
            {
                "id": "EDUCATOR",
                "name": "Педагог",
            },
            {
                "id": "PERFECTIONIST",
                "name": "Перфекционист",
            },
            {
                "id": "POLITICIAN",
                "name": "Политик",
            },
            {
                "id": "MEDIATOR_DIPLOMAT",
                "name": "Посредник дипломат",
            },
            {
                "id": "PRACTITIONER",
                "name": "Практик",
            },
            {
                "id": "PRACTITIONER_CONSERVATIVE",
                "name": "Практик консерватор",
            },
            {
                "id": "PRACTITIONER_MANAGER",
                "name": "Практик-управитель",
            },
            {
                "id": "ENTREPRENEUR_INNOVATOR",
                "name": "Предприниматель новатор",
            },
            {
                "id": "ADHERENT",
                "name": "Приверженец",
            },
            {
                "id": "FORECASTER",
                "name": "Прогнозист",
            },
            {
                "id": "PREACHER_MENTOR",
                "name": "Проповедник наставник",
            },
            {
                "id": "RATIONALIZATOR",
                "name": "Рационализатор",
            },
            {
                "id": "IMPLEMENTATOR",
                "name": "Реализатор",
            },
            {
                "id": "ROMANTIC",
                "name": "Романтик",
            },
            {
                "id": "EMINENCE_GRISE",
                "name": "Серый кардинал",
            },
            {
                "id": "SYSTEMATIZER",
                "name": "Систематизатор",
            },
            {
                "id": "SKEPTIC",
                "name": "Скептик",
            },
            {
                "id": "SOCIALIZER",
                "name": "Социализатор",
            },
            {
                "id": "STABILIZER",
                "name": "Стабилизатор",
            },
            {
                "id": "TECHIE",
                "name": "Технарь",
            },
            {
                "id": "HANDYMAN",
                "name": "Умелец",
            },
            {
                "id": "MANAGER",
                "name": "Управленец",
            },
            {
                "id": "KEEPER",
                "name": "Хранитель",
            },
            {
                "id": "EXPERIMENTER",
                "name": "Экспериментатор",
            },
            {
                "id": "ENTHUSIAST",
                "name": "Энтузиаст",
            },
        ],
        "searchSortList": [
            {
                "id": "DATE",
                "name": "Дате добавления",
            },
            {
                "id": "NAME",
                "name": "Имени",
            },
        ],
        "searchList": [
            {
                "id": "DATE",
                "name": "Дате добавления",
            },
            {
                "id": "NAME",
                "name": "Имени",
            },
            {
                "id": "COMPATIBILITY",
                "name": "Совместимости",
            },
            {
                "id": "SIMILARITY",
                "name": "Схожести",
            },
            {
                "id": "BUSINESS",
                "name": "Бизнес совместимости",
            },
            {
                "id": "INTERACTION_BUSINESS",
                "name": "Деловому взаимодействию",
            },
            {
                "id": "INTERACTION_INTERPERSONAL",
                "name": "Межличностному взаимодействию",
            },
        ],
        "any": "Любой",
        "all": "Все",
        "by-age-title": "По возрасту:",
        "by-person-type-title": "Тип личности:",
        "by-person-category-title": "Категория личности:",
        "sort-by-title": "Сортировать по:",
        "sort-by-default": "Дате добавления",
        "sort-by-gender-title": "По полу:",
        "reset-text": "Сбросить фильтр",
    },
    "InviteFriends": {
        "btn-text": "Добавить друга",
    },
    "LeftAside": {
        "user-not-uploaded-photo": "Пользователь не загрузил фотографии!",
        "feedback": "Написать в поддержку",
        "referral-program": "Реферальная программа",
        "bonus-program": "Бонусная программа",
        "legal-info-title": "Правовая информация",
        "contacts-and-requisites": "Контакты и реквизиты",
        "about": "О компании",
        "service-pay": "Оплата услуг",
        "term-of-use": "Пользовательское соглашение",
        "privacy-policy": "Политика конфиденциальности",
    },
    "RightAsideSocial": {
        "title": "Поделиться",
        "vk": "ВКонтакте",
        "ok": "Oдноклассники",
    },
    "loading": {
        "network-error":
            "Проверьте интернет соединение <br/> и перезагрузите страницу",
    },
    "MainHeader": {
        "back-to-profile": "Вернуться в профиль",
        "createAccount": "Создать свою страницу",
        "support": "Служба поддержки",
        "logout": "Выход",
    },
    "MobileMenu": {
        "support": "ПОДДЕРЖКА",
    },
    "NotificationsModule": {
        "all": "Все уведомления",
        "partner": "Вам предложили стать партнером",
        "deposit": "Пополнение счёта",
        "bonus": "Вы получили бонус",
        "reward": "Награда в рамках реферальной программы",
        "referral": "{name} стал вашим рефералом<br/>в Merlin Dating",
        "free-period":
            "Ваш реферал {name} активировал пробный период подписки {message} в Merlin Dating",
        "cancel-subscribe":
            "К сожалению, ваш реферал {name} отменил подписку {message} в Merlin Dating",
        "pay-subscribe":
            "Вы получили бонус. Ваш реферал {name} оплатил подписку {message} в Merlin Dating",
        "subscribe-error":
            "Ваш реферал {name} не смог продлить подписку {message} в Merlin Dating по какой-то причине",
        "mapp_celebrity_compare":
            "Награда за покупку совместимости в Merlin Dating",
        "bonus_coach_partner": "Бонус за действие вашего партнёра",
        "person_compare": "С вами кто-то проверил",
        "compatibility": "совместимость",
        "similarity": "схожесть",
        "business": "совместимость на бизнес-партнерство",
        "person_indicator": "Кто-то узнал",
        "interaction_business": "тип делового взаимодействия",
        "interaction_interpersonal": "тип межличностного взаимодействия",
        "mma_champion": "потенциал чемпиона",
        "mma_potential": "потенциал в единоборствах",
        "aggression_common": "вашу общую агрессию",
        "aggression_emotional": "вашу эмоциональную агрессию",
        "aggression_social": "вашу социальную агрессию",
        "aggression_intellectual": "вашу интеллектуальную агрессию",
        "aggression_physical": "вашу физическую агрессию",
        "analyse_item_unlock": "Кто-то прочитал ваш пункт описания в категории",
        "professionaltype": "профтип",
        "neurotype": "нейротип",
        "leadertype": "лидерство",
        "character": "характер",
        "relations": "отношения и семья",
        "motivation": "мотивация и энергия",
        "selfAssessmen": "вашу самооценку и уверенность",
        "professional": "профессия и самореализация",
        "person_review_created": "Обзор личности готов",
        "new_in_top": "Ваш топ совместимости обновлен: ",
        "phone_confirm": "Вы получили бонус {count} Р",
        "upload_photos_request": "Кто-то просит вас загрузить фотографии",
        "upload_photos_completed": "{user} загрузил(а) свое фото",
        "coach_partner_request": "{name} предложил вам стать партнером",
        "coach_partner_request_accept": "Принять",
        "coach_partner_request_decline": "Отклонить",
        "analyse_item_unlock_reward": "Вы получили бонус {bonus} Р",
        "new_in_top_show": "Нажмите, чтобы посмотреть",
        "phone_confirm_reward": "За подтверждение номера телефона",
        "loading": "Загрузка...",
        "no-more": "Больше уведомлений не найдено",
        "no-notifications": "У Вас нет новых уведомлений.",
        "show-all": "Показать все",
    },
    "OfflineIndicator": {
        "title": "Отсутствует подключение к интернету.",
        "retry": "Пожалуйста, попробуйте позже...",
    },
    "OnboardingDescriptionPopup": {
        "text": "Тут написано, почему Вам может быть интересно этим заниматься и какие Ваши качества могут помочь в этом.",
        "error": "Вы проголосовали не за все профессии:",
    },
    "Search": {
        "findFriends": "Найти друзей",
        "found": "Найдено пользователей:",
        "limit": "Введите хотя бы 3 символа",
        "not-found": "Ничего не найдено",
        "show-all": "ПОКАЗАТЬ ВСЕХ",
    },
    "SearchPeople": {
        "full-name": "Введите имя или фамилию",
        "all": "Все",
        "friends": "Друзья",
        "celebrity": "Знаменитости",
        "fans": "Поклонники",
        "type-is-not-available": "Тип личности пока не открыт",
        "limit": "Введите хотя бы 3 символа",
        "empty-filter": "По заданным параметрам людей не найдено",
    },
    "Select": {
        "nothing": "Нет совпадений по запросу",
        "choose": "Выбрать",
    },
    "ServiceMenu": {
        "with-friends": "Сравнитесь с друзьями",
        "about-me": "Узнай больше о себе",
        "about-another": "Узнай больше про:",
        "aggression": "Узнать уровень<br/> агрессии",
        "aggression-another": "Уровень агрессии у:",
        "person-aggression": "Уровень агрессии у:",
    },
    "UserNav": {
        "my-page": "Моя страница",
        "friends": "Друзья",
        "compare-with-me": "Сравнились со мной",
        "celebrities": "Знаменитости",
        "messages": "Сообщения",
        "analyze": "Анализ Merlin AI",
        "recommendations": "Рекомендации",
        "settings": "Настройки",
        "manager": "Упр. личностями",
    },
    "Auth": {
        "back": "Назад",
        "another-phone": "Ввести другой номер телефона",
        "prev-step": " Вернуться назад",
        "auth": "Авторизация",
        "confirm-phone":
            "Нужно убедиться, что вы реальный человек. Пожалуйста, введите свой номер телефона:",
        "retry-after":
            "Вы недавно уже запрашивали звонок, поэтому новый вы сможете запросить через {timer} сек.",
        "not-found": "Ничего не найдено",
        "country-code": "Код страны",
        "phone": "Номер телефона",
        "agree_1":
            "Вводя свой номер телефона, вы подтверждаете, что ознакомлены с ",
        "agree_and": "и",
        "agree_2": "условиями использования",
        "agree_3": "политикой конфиденциальности",
        "telegram":
            "Чтобы завершить регистрацию, пожалуйста поделитесь контактом - он будет использован, в случае потери доступа к телеграм.",
        "call": "Входящий звонок",
        "message": "Входящее сообщение",
        "blocked":
            "Ваш номер был недавно заблокирован, Вы сможете начать сначала через {timer} сек.",
        "retry-error":
            "Если по какой-либо причине до Вас не дозвонились или не дошло СМС сообщение, то Вы сможете запросить код повторно через {timer} сек.",
        "telegramTitle": "Запрос от бота получен. Выполняется авторизация",
        "telegramTitle2":
            "В течение минуты Мерлин отправит вам сообщение в телеграмме",
        "enter-call-code":
            "Введите ниже последние 4&nbsp;цифры входящего номера",
        "enter-message-code": "Введите код из СМС",
        "code": "Код подтверждения:",
        "confirm": "Подтвердить",
        "request": "Запрос...",
        "enter": "Войти",
        "loading": "Загрузка...",
        "requestError":
            "Ошибка отправки запроса звонка, попробуйте снова через несколько секунд.",
        "phoneError":
            "Укажите правильный номер телефона в международном формате. Пример для России: +79174246633",
        "codeExpired":
            "Вы не успели ввести последние четыре цифры в течении 60 сек. Пожалуйста, начните процедуру подтверждения заново.",
        "codeError": "Неправильные цифры, осталось попыток: {attempts}  из 3",
    },
    "PhoneForm": {
        "back": "Назад",
        "another-phone": "Ввести другой номер телефона",
        "prev-step": " Вернуться назад",
        "auth": "Авторизация",
        "confirm-phone":
            "Нужно убедиться, что вы реальный человек. Пожалуйста, введите свой номер телефона:",
        "retry-after":
            "Вы недавно уже запрашивали звонок, поэтому новый вы сможете запросить через {timer} сек.",
        "not-found": "Ничего не найдено",
        "country-code": "Код страны",
        "phone": "Номер телефона",
        "agree_1":
            "Вводя свой номер телефона, вы подтверждаете, что ознакомлены с ",
        "agree_and": "и",
        "agree_2": "условиями использования",
        "agree_3": "политикой конфиденциальности",
        "telegram":
            "Чтобы завершить регистрацию, пожалуйста поделитесь контактом - он будет использован, в случае потери доступа к телеграм.",
        "call": "Входящий звонок",
        "message": "Входящее сообщение",
        "blocked":
            "Ваш номер был недавно заблокирован, Вы сможете начать сначала через {timer} сек.",
        "retry-error":
            "Если по какой-либо причине до Вас не дозвонились или не дошло СМС сообщение, то Вы сможете запросить код повторно через {timer} сек.",
        "telegramTitle": "Запрос от бота получен. Выполняется авторизация",
        "telegramTitle2":
            "В течение минуты Мерлин отправит вам сообщение в телеграмме",
        "enter-call-code":
            "Введите ниже последние 4&nbsp;цифры входящего номера",
        "enter-message-code": "Введите код из СМС",
        "code": "Код подтверждения:",
        "confirm": "Подтвердить",
        "request": "Запрос...",
        "enter": "Войти",
        "loading": "Загрузка...",
        "requestError":
            "Ошибка отправки запроса звонка, попробуйте снова через несколько секунд.",
        "phoneError":
            "Укажите правильный номер телефона в международном формате. Пример для России: +79174246633",
        "codeExpired":
            "Вы не успели ввести последние четыре цифры в течении 60 сек. Пожалуйста, начните процедуру подтверждения заново.",
        "codeError": "Неправильные цифры, осталось попыток: {attempts}  из 3",
    },
    "ChooseWant": {
        "choose-1-1": "Деньги,",
        "choose-1-2": "профессия,",
        "choose-1-3": "самореализация,",
        "choose-2-1": "Энергия",
        "choose-2-2": "мотивация,",
        "choose-2-3": "смысл,",
        "choose-3-1": "Отношения,",
        "choose-3-2": "семья,",
        "choose-3-3": "коллеги",
        "choose-4-1": "Уверенность,",
        "choose-4-2": "самооценка,",
        "choose-4-3": "спокойствие",
    },
    "CelebrityProgram": {
        "compare":
            "Проверь совместимость со знаменитостью и если ты попадешь в ТОП, то вы можете встретиться лично",
        "desc": "Эта программа дает возможность людям и знаменитостям:",
        "get-know": "Знакомиться",
        "collaborate": "Сотрудничать",
        "talk": "Беседовать",
        "date": "Ходить на свидания",
        "all": "Все знаменитости в программе",
    },
    "MessagesHistory": {
        "accessError":
            "Вы не можете писать этому пользователю. Если считаете, что это ошибка, пожалуйста, обратитесь в службу поддержки.",
        "no-messages": "Нет сообщений",
    },
    "MessageHistoryHead": {
        "delete-chat": "Удалить чат",
        "new-chat": "Новый чат",
        "history": "Список чатов",
        "disable-notifications": "Выключить уведомления",
        "enable-notifications": "Включить уведомления",
        "attempts-left": "Осталось {0} кредитов",
    },
    "ChatMessage": {
        "myMessageTitle": "Вы",
        "upscale": "Увеличить {0}",
        "seconds": " сек.",
        "generated-by-stable-diffusion":
            "Изображение сгенерировано через Stable Diffusion",
        "generated-by-midjorney": "Изображение сгенерировано через Midjorney",
        "upscale-by-midjorney": "Изображение сгенерировано через Midjorney",
        "generated-by-midjorney-error":
            "Создать изображение не получилось. Не волнуйтесь, мы уже знаем о проблеме и скоро решим её. Попробуйте немного позже",
        "midjorney-parameters-error": "Переданы неизвестные параметры:",
        "midjorney-params-position-error":
            "Параметры генерации должны передаваться ПОСЛЕ текста запроса",
        "midjourney-queue": "Ожидайте, вы {0} в очереди на создание картинки",
        "midjorney-stress-error":
            "Нагрузка на сервер слишком большая. Повторите попытку через 1 минуту",
        "midjorney-moderator-error":
            "Прости, но твой запрос на картинку нарушает правила. \n" +
            "Я не создаю картинки, которые:\n" +
            "- Могут обидеть кого-то или ввести в заблуждение.\n" +
            "- Содержат обижающие слова или насилие.\n" +
            "- Изображают кого-то без одежды или в неуместных ситуациях.\n" +
            "- Могут считаться неуважительными в разных культурах.",
        "midjorney-moderator-error-1":
            "Прости, но твой запрос на картинку нарушает правила. \n" +
            "Я не создаю картинки, которые:\n" +
            "- Могут обидеть кого-то или ввести в заблуждение.\n" +
            "- Содержат обижающие слова или насилие.\n" +
            "- Изображают кого-то без одежды или в неуместных ситуациях.\n" +
            "- Могут считаться неуважительными в разных культурах.",
        "midjorney-moderator-error-2":
            "Второй раз подряд вы используйте запрещенный контекст или слова при создании изображения. Будьте внимательнее, либо нам придется ограничить вам доступ к нейросети.",
        "midjourney-moderation-blocked":
            "Вы 3 раза нарушили правила использования Midjourney, доступ к нейросети будет восстановлен через {0} минут",
        "dalle-moderation-error":
            "Прости, но твой запрос на картинку нарушает правила. \n" +
            "Я не создаю картинки, которые:\n" +
            "- Могут обидеть кого-то или ввести в заблуждение.\n" +
            "- Содержат обижающие слова или насилие.\n" +
            "- Изображают кого-то без одежды или в неуместных ситуациях.\n" +
            "- Могут считаться неуважительными в разных культурах.",
        "midjorney-unknown-error":
            "Ой, кажется, что-то пошло не так с вашим запросом на создание картинки. \n" +
            "\n" +
            "Примеры частных ошибок:\n" +
            "1️⃣ Использование параметра --version 5.2 только с --style: raw. \n" +
            "2️⃣ Корректность введенных параметров. Подозрительно выглядят такие параметры как -ar, 21:9, -style, raw, -q2, -seed, 302280510.\n" +
            "3️⃣ Правильность указанного соотношения сторон. Должно быть в формате Ш:В, например 16:9, а не 'da'.\n" +
            "\n" +
            "Это просто примеры что может быть не так. Пожалуйста, проверьте ваш запрос и попробуйте еще раз.",
        "midjorney-single-image-error":
            "Генерация только по переданному изображению невозможна, добавьте текстовый запрос и повторите попытку",
        "midjorney-image-filter-error":
            "Ваше фотография не прошла фильтр Midjourney. Попробуйте использовать другое фото.",
        "midjorney-unknown-style-error":
            "В параметр --style передан неизвестный стиль. Попробуйте скорректировать его значение.",
        "midjorney-sref-error":
            "В параметр --sref необходимо вставить ссылку на референс стиля изображения. Добавьте её и попробуйте еще раз.",
        "midjorney-sref-text-error":
            "В параметр --sref необходимо вставить ссылку на референс стиля изображения. Добавьте её и попробуйте еще раз.",
        "midjorney-cref-error":
            "В параметр --cref необходимо вставить ссылку на референс персонажа. Добавьте её и попробуйте еще раз.",
        "midjorney-cref-text-error":
            "В параметр --cref необходимо вставить ссылку на референс персонажа. Добавьте её и попробуйте еще раз.",
        "midjorney-image-timeout-error":
            "Вероятно Вы пробуете загрузить большое изображение. Попробуйте уменьшить качество и повторите попытку.",
        "midjorney-duplicate-image-error":
            "Вы отправили 2 одинаковых фотографии. Попробуйте отправить только 1 вариант.",
        "midjorney-image-access-error":
            "Вы отправили ссылку на фото, к которому нет доступа или доступ имеете только вы. Попробуйте загрузить фото из общедоступного места или загрузить его на Merlinface",
        "midjorney-something-wrong-error":
            "Мы получили неизвестную ошибку от Midjourney на ваш запрос. Попробуйте использовать другое фото или переформулировать текст.",
        "midjorney-error":
            "Ошибка",
        "midjorney-expected-argument-error": `Вы не вписали значение в параметр {0}. Добавьте его и попробуйте еще раз.`,
        "midjorney-cw-sref-error": `Параметр --cw нужно использовать только в паре с параметром --cref, вы используете его с параметром --sref`,
        "midjorney-cw-cref-error": `Параметр --cw нужно использовать только в паре с параметром --cref`,
        "gpt-error-try-again": "Что-то пошло не так. Попробуйте еще раз",
        "optimization-midjorney": "Произвожу оптимизацию изображений",
        "generated-by-pika": "Видео сгенерировано через Pika",
        "generated-by-dalle": "Изображение сгенерировано через DALLE-3",
        "pika-generating": "Генерирую видео...",
        "face-swap-generating": "Произвожу замену лица...",
        "pika-queue": "Ожидайте, вы {0} в очереди на генерацию видео",
        "pika-unknown-error":
            "Ой, кажется, что-то пошло не так с вашим запросом на создание картинки. Пожалуйста, попробуйте еще раз.",
        "download": "Скачать",
        "midjourney-describe": "Генерирую описание фотографии...",
        "midjourney-invalid-image-link":
            "Ссылка, которую Вы прикрепили к сообщению не подходит. Для загрузки изображения, кликните на иконку слева от поля ввода текста",
        "midjorney-purchase-error":
            "К сожалению, произошла ошибка. Повторите свой запрос через 1 минуту.",
        "generated-by-face-swap":
            "Фотография сделана с помощью технологии FaceSwap",
        "face-swap-queue": "Ожидайте, Вы {0} в очереди",
        "face-swap-detection-failed":
            "На фотографии не было найдено Ваше лицо. Обратитесь в службу поддержки.",
        "face-swap-detection-target-failed":
            "На фотографии на которую Вы хотите вставить своё лицо - не было найдено ни одного лица. Попробуйте загрузить другое фото.",
        "assistant-server-error":
            "На сервере произошла ошибка, повторите запрос через минуту",
        "assistant-limit-error":
            "Произошла ошибка. Обратитесь в службу поддержки, повторные запросы не дадут иной результат.",
        "assistant-prompt-error":
            "Ошибка в запросе. Перефразируйте Ваш запрос и повторите его",
        "runway-waiting": "Генерация видео может занять до 3 минут",
        "runway-progress": "Ваше видео сгенерировано на {0}%",
        "runway-waiting-start":
            "Ваше видео начнёт генерироваться примерно через {0} секунд",
        "generated-by-runway": "Видео сгенерировано с помощью Runway",
        "runway-error":
            "При обработке Вашего запроса произошла ошибка. Повторите попытку через 5 минут.",
    },
    "AddMessageForm": {
        "inputPlaceholder": "Введите сообщение...",
    },
    "Chat": {
        "loading": "Загрузка диалога...",
        "write": "печатает...",
        "online": "В сети",
        "offline": "Был в сети",
        "partner": "Вы сделали деловое предложение",
        "partner-for-you": "Вам сделали деловое предложение",
        "target": "Вы предложили закрыть цель",
        "target-for-you": "Вам предложили закрыть цель",
        "AIPlaceholder": "Введите строку для генерации изображения",
        "edit": "Редактировать",
        "copy": "Копировать",
        "delete": "Удалить",
        "read": "Прочитано",
        "notRead": "Не прочитано",
        "empty-error": "Сообщение не может быть пустым",
        "length-error-min": "Сообщение не должно быть короче {min} символов",
        "length-error-max": "Длина сообщения не более {max} символов",
        "target-message": "Вы предлагаете закрыть цель",
        "partner-message": "Вы делаете деловое предложение",
        "stable-diffusion-message":
            "Режим генерации изображений через Stable Diffusion",
        "midjorney-message": "Режим генерации изображений через Midjorney",
        "editing": "Редактирование",
        "confirmTitle": "Подтвердите действие",
        "confirmDeleteMessageText":
            "Вы действительно хотите удалить сообщение?",
        "confirmDeleteAllMessagesText":
            "Вы действительно хотите удалить всю переписку?",
        "manyMessage5seconds":
            "Слишком много сообщений за короткое время, подождите 5 секунд.",
        "manyMessage60seconds":
            "Не более 30 сообщений в минуту, подождите пожалуйста примерно 60 секунд.",
        "userDisable":
            "Пользователь ограничил возможность получения сообщений.",
        "sendError": "Ошибка отправки сообщения, попробуйте еще раз.",
        "gpt-message-to-long": "Слишком длинное сообщение",
    },
    "Dialogs": {
        "search": "Поиск по друзьям",
        "open": "Открыть чат",
        "nothing": "У вас нет активных чатов",
        "image": "Картинка",
        "new-chat": "Новый чат",
        "delete": {
            "title": "Подтвердите удаление",
            "desc": "После удаления чата, все сообщения в нём будут удалены",
            "btn": "Удалить чат",
        },
        "attempts-left": "Осталось {0} кредитов",
    },
    "Messages": {
        "loading": "Загрузка чата...",
        "chooseAI": "Выберите нейросеть, которой хотите воспользоваться",
        "chooseChat": "Выберите чат для того, чтобы просматривать сообщения",
    },
    "ChatMenu": {
        "title": "Меню",
        "stableDiffusionTitle": "Stable Diffusion",
        "stableDiffusionDescription":
            "Генерация фотореалистичных изображений на основе любого текста",
        "midjorneyTitle": "Midjorney",
        "midjorneyDescription":
            "Генерация фотореалистичных изображений на основе любого текста",
        "enable": "Включить",
        "disable": "Выключить",
    },
    "PayoutModal": {
        "balance": "Ваш баланс:",
        "title": "Вывод средств:",
        "sum": "Сумма вывода:",
        "sumRequiredError": "Укажите сумму вывода",
        "sumMinError":
            "Извините, мы не можем принять заявку. Минимальная сумма для вывода - {min} рублей. Продолжайте также активно и скоро сможете вывести заработанные деньги.",
        "sumMaxError": "Максимальная сумма для вывода {max}",
        "success":
            "Заявка на вывод средств принята! В скором времени с вами свяжется менеджер",
        "newFormat":
            "Мы перешли на формат подписки, ваш текущий баланс вы можете потратить на сравнения с друзьями или знаменитостями. А чтобы пользоваться всеми фишками - подпишитесь на один или несколько разделов",
        "deposits": "Пополнения",
        "payments": "Покупки",
        "bonuses": "Бонусы",
        "table": {
            "date": "Дата",
            "sum": "Сумма",
            "description": "Описание",
            "remainder": "Остаток",
            "loading": "Загрузка...",
            "noMore": "Больше нет записей",
            "no": "Транзакций не найдено",
        },
    },
    "purchaseDescriptions": {
        "category": {
            "deposit": "Пополнение счёта",
            "analyse_item_unlock": "Покупка описания в категории",
            "bonus_analyse_item_unlock":
                "Кто-то разблокировал ваш пункт описания в категории",
            "referral_reward": "Награда в рамках реферальной программы",
            "merlinapp_referral_created":
                "Награда за реферала в мобильном приложении",
            "person_compare": "Вы проверили",
            "bonus_person_compare": "С вами кто-то проверил",
            "person_compare_photos": "Анализ по фотографиям на",
            "person_indicator": "Открыт индикатор:",
            "bonus_person_indicator": "Кто-то открыл ваш индикатор:",
            "phone_confirm": "Бонус за подтверждение номера телефона",
            "bonus_coach_partner": "Бонус по программе Коуч-партнёр",
            "payout": "Вывод средств",
            "refund": "Возмещение средств",
        },
        "subcategory": {
            "professionaltype": "профтип",
            "neurotype": "нейротип",
            "leadertype": "лидерство",
            "character": "характер",
            "relations": "отношения и семья",
            "motivation": "мотивация и энергия",
            "selfAssessmen": "самооценка и уверенность",
            "professional": "профессия и самореализация",
            "compatibility": "совместимость",
            "similarity": "схожесть",
            "business": "совместимость на бизнес-партнерство",
            "interaction_business": "тип делового взаимодействия",
            "interaction_interpersonal": "тип межличностного взаимодействия",
            "mma_champion": "потенциал чемпиона",
            "mma_potential": "потенциал в единоборствах",
            "aggression_common": "общая агрессия",
            "aggression_physical": "физическая агрессия",
            "aggression_emotional": "эмоциональная агрессия",
            "aggression_social": "социальная агрессия",
            "aggression_intellectual": "интеллектуальная агрессия",
        },
    },
    "serviceTypes": {
        "COMPATIBILITY": "Совместимость в отношениях",
        "COMPATIBILITY_PLAN": "Отношения и семья",
        "SIMILARITY": "Схожесть характеров",
        "SIMILARITY_PLAN": "Самооценка и уверенность",
        "BUSINESS": "Деловая совместимость",
        "BUSINESS_PLAN": "Профессия и предназначение",
        "INTERACTION_BUSINESS": "Деловые взаимоотношения",
        "INTERACTION_BUSINESS_MY": "Как я веду себя в делах",
        "INTERACTION_BUSINESS_OTHER": "Как ведёт себя в делах",
        "INTERACTION_BUSINESS_PLAN": "Профессия и предназначение",
        "INTERACTION_INTERPERSONAL": "Отношения с людьми",
        "INTERACTION_INTERPERSONAL_MY": "Как я веду себя с людьми",
        "INTERACTION_INTERPERSONAL_OTHER": "Как ведёт себя с людьми",
        "INTERACTION_INTERPERSONAL_PLAN": "Отношения и семья",
        "AGGRESSION_COMMON": "Общая агрессия",
        "AGGRESSION_COMMON_PLAN": "Отношения и семья",
        "AGGRESSION_PHYSICAL": "Физическая агрессия",
        "AGGRESSION_PHYSICAL_PLAN": "Профессия и предназначение",
        "AGGRESSION_EMOTIONAL": "Эмоциональная агрессия",
        "AGGRESSION_EMOTIONAL_PLAN": "Профессия и предназначение",
        "AGGRESSION_SOCIAL": "Социальная агрессия",
        "AGGRESSION_SOCIAL_PLAN": "Профессия и предназначение",
        "AGGRESSION_INTELLECTUAL": "Интеллектуальная агрессия",
        "AGGRESSION_INTELLECTUAL_PLAN": "Отношения и семья",
        "INTELLIGENCE_LEVEL": "Уровень интеллекта",
    },
    "buyActions": {
        "compareUnlimited": "Количество сравнений не ограничено",
        "compareLimit": "Осталось сравнений: {limit}",
        "indicatorUnlimited": "Количество проверок не ограничено",
        "indicatorLimit": "Осталось попыток: {limit}",
        "compareCelebrity": "знаменитостью",
        "compareUser": "пользователем",
        "indicatorCelebrity": "знаменитости",
        "indicatorUser": "пользователя",
        "compareConfirmTitle": "Подтвердите сравнение",
        "compareConfirmText": `Вы действительно хотите сравнить себя с {userType} {name} в категории "{activeType}"?`,
        "compareConfirmBtn": "Подтвердить покупку",
        "indicatorConfirmTitle": "Подтвердите покупку",
        "indicatorConfirmText": `Вы действительно хотите открыть категорию "{activeType}" у {userType} {name}?`,
        "indicatorConfirmBtn": "Подтвердить покупку",
    },
    "compare-result-dialog": {
        "more": "Узнать больше о человеке",
    },
    "ConfirmDialog": {
        "price": "Стоимость:",
        "timer": "До проверки в рамках подписки:",
        "cancel": "Отменить",
        "apply": "Подтвердить",
    },
    "LegalLinksMobile": {
        "referral": "Реферальная программа",
        "legal-title": "Правовая информация",
        "requisites": "Контакты и реквизиты",
        "about": "О компании",
        "payments": "Оплата услуг",
        "term-of-use": "Пользовательское соглашение",
        "privacy-policy": "Политика конфиденциальности",
        "payout": "Бонусная программа",
        "support": "Написать в поддержку",
        "logout": "Выйти",
    },
    "AnotherPerson": {
        "rating": "Ваши оценки",
        "person-rate": "Оцените человека",
        "compare-with-me": "Сравнить с собой",
        "more": "Узнать больше",
        "aggression": "Уровень агрессии",
    },
    "AnotherPersonActions": {
        "sendMessage": "НАПИСАТЬ СООБЩЕНИЕ",
        "sendTarget": "ПРЕДЛОЖИТЬ ЗАКРЫТЬ ЦЕЛЬ",
        "sendBusiness": "ДЕЛОВОЕ ПРЕДЛОЖЕНИЕ",
        "targetConfirmTitle": "Обратите внимание",
        "targetConfirmText":
            "Для того, чтобы совершить это действие, нужно сначала добавить его в друзья",
        "businessConfirmText":
            "Для того, чтобы сделать человеку деловое предложение, нужно подписаться на категорию \"Профессия и предназначение\"",
        "add": "Добавить в друзья",
        "subscribe": "Подписаться",
    },
    "AnotherPersonInfo": {
        "getType": "Определить мой психотип",
        "typeHidden": "Тип личности пока не открыт",
        "web": "Веб-сайт",
        "webUndefined": "Не указан",
        "selfEsteem": "Что повышает самооценку",
        "selfEsteemUndefined": "Не указано",
        "occupation": "Статус",
        "occupationUndefined": "Не указан",
        "personalInfo": "Личные данные",
        "age_1": "год",
        "age_2": "года",
        "age_3": "лет",
        "height": "см",
        "weight": "кг",
        "noChildren": "Нет детей",
        "children_1": "ребёнок",
        "children_2": "ребёнка",
        "children_3": "детей",
    },
    "AnotherPersonBusinessCard": {
        "merlin": "От Merlin",
        "aboutMe": "{name} о себе",
        "blocked": "Заблокировано",
        "unlock": "Разблокировать",
        "more": "Читать далее",
        "mrln_strengths": "Сильные стороны",
        "mrln_talents": "Таланты",
        "mrln_self_esteem": "Что повышает самооценку",
        "mrln_motivation": "Что повышает мотивацию",
        "mrln_tasks": "Задачи, которые он выполняет успешно",
        "mrln_activities": "Сферы деятельности, к которым он предрасположен",
        "self_esteem": "Что повышает самооценку",
        "user_like": "Что любит делать",
        "user_good": "В чем хорош",
        "user_usually": "Чем обычно занят",
        "user_work": "Кем хочет работать",
        "user_goal": "Хочет зарабатывать",
        "user_skill": "Хочет освоить",
        "career": "ОПЫТ",
        "education": "ОБРАЗОВАНИЕ",
        "self_esteemUndefined": "Ничего",
        "user_likeUndefined": "Ничего",
        "user_goodUndefined": "Ни в чём",
        "user_usuallyUndefined": "Ни чем",
        "user_workUndefined": "Ни кем",
        "user_goalUndefined": "30 руб./час",
        "user_skillUndefined": "Левую часть кровати",
        "fromNow": "по наст. время",
        "categoryProfession": "Профессия и предназначение",
        "categorySelfEsteem": "Самооценка и уверенность",
        "categoryMotivation": "Мотивация и эффективность",
        "needSubscribe": "Требуется подписка",
        "needSubscribeText":
            "Для разблокировки этого пункта требуется подписка на категорию \"{category}\"",
        "subscribe": "Подписаться",
    },
    "AvailableSocial": {
        "notSelected": "НЕ УКАЗАНО",
    },
    "AvatarGallery": {
        "retry": "Попробовать еще раз",
        "new": "Загрузить новый аватар",
    },
    "ChangeName": {
        "placeholder": "Например: Иван",
        "error": "Имя должно содержать больше 2 символов",
        "saving": "Сохранение...",
        "save": "Продолжить",
    },
    "ChangePhotos": {
        "another": "Загрузить другое",
        "anotherAnfasDrag": "Перетащить другое <br/> фото в анфас сюда",
        "anotherProfileDrag": "Перетащить другое <br/> фото в профиль сюда",
        "there": "сюда",
        "didntFit": "Фото не подошло",
        "limit": "Лимит загрузок на <br/> сегодня исчерпан",
        "example": "Пример эталонных фото",
        "anfas": {
            "title":
                "Ваши фотографии увидит только нейросетевой алгоритм. Фото не доступны третьим лицам.",
            "desktopText": "Перетащите Ваше фото в анфас сюда",
            "mobileText": "Загрузите Ваше фото в анфас сюда",
            "dragText": "Перетащите Ваше фото в анфас сюда",
        },
        "profile": {
            "desktopText": "Перетащите Ваше фото в профиль сюда",
            "mobileText": "Загрузите Ваше фото в профиль сюда",
            "dragText": "Перетащите Ваше фото в профиль сюда",
        },
        "avatar": {
            "title":
                "Загрузите аватар, он виден всем кто заходит на вашу страницу.",
            "text": "Загрузите аватар",
            "dragText": "Перетащите аватар сюда",
            "help": "Советую загрузить фото, на котором тебя лучше видно. Это фото будут видеть другие пользователи. Я не смогу продвигать тебя с чужими фотографиями.",
        },
    },
    "CompareWithPhoto": {
        "back": "Вернуться к описанию личности",
        "another-type": "Выбрать другой тип сравнения",
        "choose-in-list": "Выберите из списка друзей",
        "searchFriends": "Поиск по друзьям",
        "noFriends":
            "У пока нет друзей. Вы можете пригласить друзей и получить бонусы если они присоединятся",
        "invite":
            "К сожалению этого человека еще нет в списке ваших друзей. Вы можете его пригласить и получить бонус если он присоединится",
        "loading": "Загрузка...",
        "no": "Больше никого не найдено :)",
        "noFriendsShort": "У вас нет друзей",
        "upload-friend-photo": "загрузите фото друга",
    },
    "MobileAvatarGallery": {
        "photo-uploaded": "Фото загружено",
        "choose-main": "Сделать основной",
        "confirmDeleteTitle": "Подтвердите удаление",
        "confirmDeleteText": "Вы действительно хотите удалить фотографию?",
        "confirmDeleteBtn": "Удалить",
    },
    "PersonCompareMobile": {
        "compare": {
            "myProfile": "Сравнитесь с друзьями",
            "withUser": "Сравните себя с <span>{user}</span>",
        },
        "indicator": {
            "myProfile": "Узнай больше о себе",
            "withUser": "Узнай больше про:<br/> {user}",
        },
    },
    "PersonDescription": {
        "back": "Назад",
        "choose-type": "Выберите интересующее вас направление",
        "need-subscribe": "Требуется подписка",
        "need-subscribe-text":
            "Для того, чтобы прочитать описание по выбранному разделу, необходимо оформить подписку на категорию \"{category}\"",
        "btn": "Оформить подписку",
    },
    "PersonDescriptionAlternative": {
        "back": "Назад",
        "choose-type": "Выберите интересующее вас направление",
        "need-subscribe": "Требуется подписка",
        "need-subscribe-text":
            "Для того, чтобы прочитать описание по выбранному разделу, необходимо оформить подписку на категорию \"{category}\"",
        "btn": "Оформить подписку",
    },
    "PersonInfo": {
        "age": "Возраст",
        "age_1": "год",
        "age_2": "года",
        "age_3": "лет",
        "no": "Не указан",
        "nothing": "Ничего",
        "children": "Дети",
        "no_children": "Нет детей",
        "children_1": "ребёнок",
        "children_2": "ребёнка",
        "children_3": "детей",
        "height": "Рост",
        "weight": "Вес",
        "subscriptions": "Подписки",
        "photo-error":
            "Если у Вас будет несколько своих фото - визитка будет выглядеть привлекательнее        ",
    },
    "PersonMobileBox": {
        "history": "История",
    },
    "PersonMobileBusinessCard": {
        "merlin": "От Merlin",
        "user": "От {user}",
        "career": "Карьера",
        "education": "Образование",
    },
    "PersonMobileBusinessCardPreview": {
        "title": "Как будет выглядеть моя визитка <br/> для другого человека",
        "send": "ОТПРАВИТЬ ССЫЛКУ НА МОЮ СТРАНИЦУ",
    },
    "PersonMobileHeader": {
        "noName": "Имя не указано",
        "changeName": "Задать имя",
        "my-type": "Определить мой психотип",
        "type-not-available": "Тип личности пока не открыт",
    },
    "PersonalRecommendations": {
        "title": "Личные рекомендации",
    },
    "PersonalRecommendationsDialog": {
        "title": "Персональные рекомендации для Вас",
        "canOpen": "Можно открыть",
        "number": "Рекомендация №",
        "openAfter": "Можно открыть через: ",
    },
    "PhotoExamples": {
        "title": "Примеры эталонных фотографий",
        "text": "Чем ближе фото к эталону, тем точнее нейросеть расскажет про вас. <br/> Фотографии будут доступны только вам.",
        "anfas": "Фото в анфас",
        "profile": "Фото в профиль",
        "btn": "Загрузить свои фотографии",
    },
    "AboutSettingsForm": {
        "user_like": "Что Вы любите делать?",
        "user_good": "В чём вы хороши?",
        "user_usually": "Чем обычно вы занимаетесь?",
        "user_work": "Кем вы хотите работать?",
        "user_goal": "Сколько вы хотите зарабатывать?",
        "user_skill": "Что хотите освоить?",
        "saved": "Сохранено",
    },
    "CareerSettingsForm": {
        "company-name": "Название компании",
        "search-title": "Возможно вы имели ввиду:",
        "company-name-error": "Введите название компании",
        "company-position": "Должность",
        "company-position-error": "Введите должность",
        "company-start": "Начало работы",
        "company-start-error": "Введите дату начала работы",
        "company-end": "Окончание работы",
        "company-end-now":
            "Если вы до сих пор работаете в компании, оставьте это поле пустым",
        "saved": "Сохранено",
        "save": "Сохранить",
        "andContinue": " и продолжить",
        "delete": "Удалить место работы",
        "add": "Добавить место работы",
    },
    "EducationSettingsForm": {
        "country": " Страна:",
        "selectCountry": "Выберите страну",
        "alreadySelectCountry": "Сначала выберите страну",
        "alreadySelectCity": "Сначала выберите город",
        "city": "Город: ",
        "searchCity": "Поиск по городам",
        "no-cities": "По вашему запросу не найден ни один город",
        "education-place": "Название учебного заведения",
        "education-place-nothing":
            "По вашему запросу не найдено ни одного учебного заведения",
        "education-palce-error": " Введите название учебного заведения",
        "education-position": "Специальность",
        "education-position-error": "Введите специальность обучения",
        "education-start": "Год начала обучения",
        "education-start-error": "Введите год начала обучения",
        "education-end": "Год окончания обучения",
        "education-end-now":
            "If you are still studying, leave this field blank",
        "saved": "Сохранено",
        "save": "Сохранить",
        "andContinue": " и продолжить",
        "delete": "Удалить место обучения",
        "add": "Добавить место обучения",
    },
    "BlockedUI": {
        "title": "Merlin AI определит Ваши таланты",
        "description":
            "Загрузите свои фотографии, чтобы получить полный доступ к сайту",
        "upload": "Загрузить фотографии",
    },
    "Settings": {
        "lang": "Язык сайта",
        "information": "Основное",
        "added-info": "Информация",
        "about": "Интересы",
        "career": "Карьера",
        "education": "Образование",
        "photos": "Фотографии",
        "successPhotoTitle": "Фотографии загружены",
        "successPhotoDescription":
            "Теперь Вы можете пользоваться всеми функциями сайта",
        "name": "Имя",
        "person-name": "Введите имя личности",
        "name-error": "Имя должно быть короче 60 символов",
        "address": "Адрес страницы",
        "address-error":
            "Адрес страницы может содержать только латинские буквы, цифры и знаки «_» и «-» и не может быть короче 3-х или более 30-ти символов",
        "address-length-error": "Адрес не должен быть длиннее {min} символов",
        "birthday": "Дата рождения",
        "birthday-error": "Выберите дату рождения",
        "marital": "Семейное положение",
        "maritalList": [
            "Не замужем",
            "Замужем",
            "Не женат",
            "Женат",
            "В разводе",
        ],
        "select": "Выберите из списка",
        "children": "Дети",
        "children-error": "Введите целое число или оставьте поле пустым",
        "height": "Рост",
        "maxHeightError": "Максимально допустимое значение - 200",
        "maxWeightError": "Максимально допустимое значение - 200",
        "heightUnit": "см",
        "weight": "Вес",
        "weightUnit": "кг",
        "height-error": "Введите ваш рост",
        "weight-error": "Введите ваш вес",
        "gender": "Пол",
        "genderList": [
            {
                "id": "M",
                "name": "Мужской",
            },
            {
                "id": "F",
                "name": "Женский",
            },
        ],
        "eye-color": "Цвет глаз",
        "saved": "Сохранено в",
        "delete-account": "Удалить аккаунт",
        "exit": "Выйти из аккаунта",
        "nameTimeError": "Смена имени возможна один раз в 24 часа",
    },
    "InfoSettingsForm": {
        "web": "Ваш сайт",
        "selfEsteem": "Что повышает вашу самооценку?",
        "address-incorrect":
            "Введите корректную ссылку, в формате: https://merlinface.com",
        "self-esteem-error": "Не более 50 символов, Вы ввели {count}",
        "social-status": "Ваш социальный статус:",
        "socialList": [
            "Школьник",
            "Студент",
            "Безработный",
            "Самозанятый",
            "Работник компании",
            "(Со)владелец компании",
        ],
        "country": "Страна:",
        "city": "Город",
        "selectCountry": "Выберите страну",
        "searchCities": "Поиск по городам",
        "saved": "Сохранено",
    },
    "SocialRating": {
        "start": "Как{suffix}",
        "end": "я",
    },
    "UserRecommendationsMobile": {
        "analyze":
            "Производится анализ рекомендованных людей. Это может занять до 24 часов.",
        "category_1": "Профессия и&nbsp;предназначение",
        "category_2": "Отношения и&nbsp;семья",
        "category_3": "Мотивация и&nbsp;эффективность",
        "category_4": "Самооценка и&nbsp;уверенность",
    },
    "Person": {
        "you": "Вы",
        "user": "User",
        "compare": {
            "fromMe": "Сравнитесь с друзьями",
            "fromUnnamedUser":
                "Сравните себя с <span>безымянной личностью</span>",
            "fromNamedUser": "Сравните себя с <span>{user}</span>",
        },
        "indicator": {
            "fromMe": "Узнай больше о себе",
            "fromUnnamedUser":
                "Узнайте больше про: <span>безымянную личность</span>",
            "fromNamedUser": "Узнайте больше про: <span>{user}</span>",
        },
        "aggression": {
            "fromMe": "Узнать свой уровень агрессии",
            "fromUnnamedUser":
                "Уровень агрессии у: <span>безымянной личности</span>",
            "fromNamedUser": "Уровень агрессии у: <span>{user}</span>",
        },
    },
    "PersonList": {
        "celebrities": "Знаменитости",
        "fans": "Поклонники",
        "friends": "Все друзья",
        "friends-requests": "Заявки в друзья",
        "select-all": "Выбрать всех друзей",
        "no-with-params": "По заданным параметрам знаменитостей не найдено",
        "no-friends": "У Вас пока нет друзей.",
        "no-requests": "У Вас пока нет заявок в друзья.",
        "no-celebrities": "По заданным параметрам знаменитостей не найдено",
        "search": {
            "celebrity": "Поиск по знаменитостям",
            "friends": "Поиск по друзьям",
            "requests": "Поиск по заявкам в друзья",
            "users": "Поиск по личностям",
        },
    },
    "PersonShortCard": {
        "typeLocked": "Тип личности пока не открыт",
        "sendMessage": "Написать сообщение",
        "compare": "Сравнить",
        "hide": "Скрыть",
        "removeFromFriends": "Удалить из друзей",
        "acceptRequest": "Добавить",
        "declineRequest": "Отклонить",
        "confirmDeleteFriendTitle": "Подтвердите действие",
        "confirmDeleteFriendText": `Вы действительно хотите удалить из друзей личность "{user}"?`,
        "confirmDeleteFriendBtn": "Удалить",
    },
    "Promocode": {
        "btn": "У меня есть промокод",
        "enter": "Введите промокод",
    },
    "RecommendedPeople": {
        "no-persons": "Merlin пока не подобрал людей для Вас",
        "tabs": {
            "partner": "Деловые знакомства",
            "love": "Отношения",
            "coming": "Скоро",
        },
        "type-undefined": "Не определён",
        "toProfile": "В ПРОФИЛЬ",
        "age_1": "год",
        "age_2": "года",
        "age_3": "лет",
        "status": "Социальный статус",
        "no-status": "Не указан",
        "no-goal": "Не указан",
        "city": "Город",
        "no-city": "Не указан",
        "noAge": "Возраст не указан",
        "money-target": "Моя цель по заработку",
        "spheres": "Сферы деятельности",
        "no-spheres": "Не указаны",
        "next": "Дальше",
        "loading": "Загрузка...",
        "category_1": "Профессия и&nbsp;предназначение",
        "category_2": "Отношения и&nbsp;семья",
        "category_3": "Мотивация и&nbsp;эффективность",
        "category_4": "Самооценка и&nbsp;уверенность",
        "next-recommendation": "Следующий человек",
    },
    "SubscriptionsCategoriesItem": {
        "active": "подписка активна",
    },
    "SubscriptionsContent": {
        "active": "Подписка активна до {date}",
        "category_1": "Профессия и предназначение, FREE",
        "category_2": "Отношения и семья, FREE",
        "category_3": "Мотивация и эффективность, FREE",
        "category_4": "Самооценка и уверенность, FREE",
        "cancelTitle": "Отмена подписки",
        "cancelText":
            "Вы подтверждаете, что хотите отменить {period} подписку в категории \"{category}\"?",
        "cancelBtn": "Отменить подписку",
        "mounthly": "ежемесячную",
        "annual": "ежегодную",
    },
    "SubscriptionsTableForm": {
        "payBtn": "Перейти к оплате",
        "withBalance": "Оплата будет списана с баланса на сайте",
    },
    "SubscriptionsTableInfo": {
        "discount": "Ваша скидка: {discount}&nbsp;₽",
        "chooseAnnual":
            "Выберите ежегодную подписку<br/>и сэкономьте от {discount}&nbsp;₽",
        "active": "Активная подписка",
    },
    "Subscriptions": {
        "title": "Выберите, интересующую категорию подписки",
        "canceled": "Подписка успешно отменена!",
        "succeeded": "Подписка успешно оформлена!",
        "choosePlan": "Выберите тарифный план:",
    },
    "about": {
        "title": "Приветствуем вас на проекте merlinface.com!",
        "text-1": "Общие положения",
        "text-2":
            "Наша команда создала Нейросеть, с помощью которой вы можете получить текстовые и графические диагностики внимания, памяти, эмоционально-волевой сферы, поведения, а также множество индивидуальных рекомендаций, которые основаны на данных показателях.",
        "text-3":
            "Также над диагностиками Пользователей работают квалифицированные специалисты: нейропсихологи, когнитивно-поведенческие терапевты и специалисты других профилей.",
        "text-4": "Полезного вам ознакомления!",
        "vk": "Наша группа ВКонтакте:",
    },
    "cookie": {
        "title": "Политика использования cookie-файлов",
        "text-1":
            "Cookie-файлы — небольшой фрагмент текста, отправленный сервером и хранимый на компьютере Пользователя. Cookie выполняют множество функций, например, сохраняют настройки, созданные Пользователем, позволяют перемещаться между страницами после авторизации и в целом упрощают работу с сайтом.",
        "text-2": "Мы используем cookie-файлы для:",
        "text-3":
            "1. Идентификации — файлы cookie позволяют распознать ваше устройство и ваш Аккаунт, если вы ввели логин и пароль и не запрашивать их каждый раз при переходе на другую страницу.",
        "text-4":
            "2. Статистики — файлы cookie позволяют собирать данные о просмотре тех или иных страниц.",
        "text-5":
            "Пользователь может запретить браузеру принимать cookie, но это существенно ограничит его возможности по использованию Платформы.",
    },
    "payments": {
        "title": "Оплата и получение заказа",
        "text-1": "Оплата банковской картой",
        "text-2":
            "Прием платежей на сайте обеспечивает процессинговый центр Robokassa — официальный сервис-провайдер VISA и MasterCard.",
        "text-3":
            "Для онлайн-оплаты можно использовать банковские карты МИР, Visa, Visa Electron, MasterCard и Maestro. Если ваша карта подписана на 3D-Secure, авторизация вашего платежа будет проведена с помощью одноразового пароля.",
        "text-4":
            "Ввод и обработка конфиденциальных платежных данных производится на стороне процессингового центра. Платежные данные передаются в банк в зашифрованном виде по защищенным каналам. Никто, даже продавец, не может получить введенные клиентом реквизиты банковской карты, что гарантирует полную безопасность его денежных средств и персональных данных.",
        "text-5":
            "После успешного прохождения оплаты на электронную почту плательщика направляется электронная квитанция, подтверждающая совершение платежа и содержащая его уникальный идентификатор.",
        "text-6":
            "В случае необходимости совершения возврата средств, деньги возвращаются на ту же карту, с которой была совершена оплата.",
        "text-7":
            "Все вопросы, связанные с процессом оплаты, можно задать специалистам круглосуточной службы поддержки Robokassa по телефону +7 499 110 57 25 или написав письмо на support@robokassa.ru",
        "text-8": "Оплата с помощью Яндекс.Деньги, Webmoney, Qiwi",
        "text-9":
            "Вы можете оплатить заказ прямо на сайте интернет-магазина merlinface.com через электронные кошельки Яндекс.Деньги, WebMoney, QIWI. Безопасность платежей обеспечивает процесинговый центр Robokassa.",
        "text-10":
            "В случае необходимости совершения возврата средств, деньги возвращаются на тот же электронный кошелек, с которого была совершена оплата.",
        "text-11":
            "В случае возникновения вопросов, связанных с оплатой через электронный кошелек, вы можете обратиться в круглосуточную службу поддержки Robokassa по телефону +7 499 110 57 25",
        "text-12": "Список информационных услуг для покупки:",
        "text-13":
            "1. Услуга \"Профессия и самореализация\". Предоставление описания Пользователя на тему самореализации и рекомендаций подходящих профессий. Стоимость от 150 до 500 рублей.",
        "text-14":
            "2. Услуга \"Самооценка\". Предоставление описания Пользователя и рекомендаций на тему самооценки. Стоимость от 150 до 200 рублей.",
        "text-15":
            "3. Услуга \"Мотивация и энергия\". Предоставление описания Пользователя и рекомендаций на тему самооценки. Стоимость от 150 до 500 рублей.",
        "text-16":
            "4. Услуга \"Отношения и семья\". Предоставление описания Пользователя и рекомендаций на тему отношений с другими людьми. Стоимость от 100 до 400 рублей.",
        "text-17":
            "5. Услуга \"Совместимость в отношениях\" показывает уровень в процентах. Стоимость 50 рублей.",
        "text-18":
            "6. Услуга \"Схожесть характеров\" показывает уровень в процентах. Стоимость 25 рублей.",
        "text-19":
            "7. Услуга \"Деловая совместимость\" показывает уровень в процентах. Стоимость 100 рублей.",
        "text-20":
            "8. Услуга \"Как ведет себя в делах\" показывает уровень в процентах. Стоимость 50 рублей.",
        "text-21":
            "9. Услуга \"Как ведет себя с людьми\" показывает уровень в процентах. Стоимость 50 рублей.",
        "text-22":
            "10. Услуга \"Потенциал в единоборствах\" показывает уровень в процентах. Стоимость 50 рублей.",
        "text-23":
            "11. Услуга \"Потенциал чемпиона\" показывает уровень в процентах. Стоимость 50 рублей.",
        "text-24":
            "Сумма за разовые покупки при сравнении с каждым Пользователем платформы или открытия интересующего блока описания для каждого Пользователя списывается единоразово с баланса внутри системы.",
        "text-25":
            "После успешной оплаты услуга открывается автоматически на странице пользователя на сайте merlinface.com. Услуги предоставляются на бессрочной основе.",
        "text-26":
            "Вопросы возврата денежных средств регламентируются Законом РФ от 07.02.1992 № 2300-I \"О защите прав потребителей\"",
    },
    "privacy-policy": {
        "title": "",
        "text-1": "Общие положения",
        "text-2":
            "Настоящая политика конфиденциальности составлена в соответствии с требованиями Федерального закона от 27.07.2006. №152-ФЗ «О персональных данных» и определяет порядок обработки персональных данных и меры по обеспечению безопасности персональных данных, предпринимаемые ООО \"Мерлин АИ\" (далее – Администрация).",
        "text-3":
            "1.1. Администрация ставит своей важнейшей целью и условием осуществления своей деятельности соблюдение прав и свобод человека и гражданина при обработке его персональных данных, в том числе защиты прав на неприкосновенность частной жизни, личную и семейную тайну.",
        "text-4":
            "1.2. Настоящая политика Администрации в отношении обработки персональных данных (далее – Политика) применяется ко всей информации, которую Администрация может получить о посетителях веб-сайта https://merlinface.com и мобильного приложения Merlin Dating",
        "text-5": "2. Основные понятия, используемые в Политике",
        "text-6":
            "Блокирование персональных данных – временное прекращение обработки персональных данных (за исключением случаев, если обработка необходима для уточнения персональных данных);",
        "text-7":
            "Сайт – совокупность графических и информационных материалов, а также программ для ЭВМ и баз данных, обеспечивающих их доступность в сети интернет по сетевому адресу https://merlinface.com и мобильного приложения Merlin Dating.",
        "text-8":
            "Информационная система персональных данных — совокупность содержащихся в базах данных персональных данных, и обеспечивающих их обработку информационных технологий и технических средств;",
        "text-9":
            "Обезличивание персональных данных — действия, в результате которых невозможно определить без использования дополнительной информации принадлежность персональных данных конкретному Пользователю или иному субъекту персональных данных;",
        "text-10":
            "Обработка персональных данных – любое действие (операция) или совокупность действий (операций), совершаемых с использованием средств автоматизации или без использования таких средств с персональными данными, включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание, блокирование, удаление, уничтожение персональных данных;",
        "text-11":
            "Администрация – юридическое или физическое лицо, самостоятельно или совместно с другими лицами организующие и (или) осуществляющие обработку персональных данных, а также определяющие цели обработки персональных данных, состав персональных данных, подлежащих обработке, действия (операции), совершаемые с персональными данными;",
        "text-12":
            "Персональные данные – любая информация, относящаяся прямо или косвенно к определенному или определяемому Пользователю веб-сайта https://merlinface.com и мобильного приложения Merlin Dating",
        "text-13":
            "Пользователь – любой посетитель веб-сайта https://merlinface.com и мобильного приложения Merlin Dating",
        "text-14":
            "Предоставление персональных данных – действия, направленные на раскрытие персональных данных определенному лицу или определенному кругу лиц;",
        "text-15":
            "Распространение персональных данных – любые действия, направленные на раскрытие персональных данных неопределенному кругу лиц (передача персональных данных) или на ознакомление с персональными данными неограниченного круга лиц, в том числе обнародование персональных данных в средствах массовой информации, размещение в информационно-телекоммуникационных сетях или предоставление доступа к персональным данным каким-либо иным способом;",
        "text-16":
            "Трансграничная передача персональных данных – передача персональных данных на территорию иностранного государства органу власти иностранного государства, иностранному физическому или иностранному юридическому лицу;",
        "text-17":
            "Уничтожение персональных данных – любые действия, в результате которых персональные данные уничтожаются безвозвратно с невозможностью дальнейшего восстановления содержания персональных данных в информационной системе персональных данных и (или) уничтожаются материальные носители персональных данных.",
        "text-18":
            "Платеж — вознаграждение Администрации, денежные средства, уплачиваемые Пользователем в пользу Администрации за предоставления неисключительной лицензии.",
        "text-19":
            "3. Администрация может обрабатывать следующие Персональные данные Пользователя:",
        "text-20": "3.1. Фамилия, имя, отчество;",
        "text-21": "3.2. Электронный адрес;",
        "text-22": "3.3. Номера телефонов;",
        "text-23": "3.4. Год, месяц, дата и место рождения;",
        "text-24": "3.5. Фотографии;",
        "text-25":
            "3.6. Сведения об образовании, профессии, специальности и квалификации, реквизиты документов об образовании;",
        "text-26":
            "3.7. Также на Сайте происходит сбор и обработка обезличенных данных о посетителях (в т.ч. файлов «cookie») с помощью сервисов интернет-статистики (Яндекс Метрика и Гугл Аналитика и других).",
        "text-27":
            "3.8. Сайт может автоматически или с участием Пользователя собирать дополнительную информацию, включая IP-адрес, версию браузера, страну, язык, параметры utm, теги партнера, адрес страницы, с которой Пользователь произвел переход на Сайт, и другие технические данные, которые могут использоваться для идентификации Пользователя без применения дополнительных мер.",
        "text-28":
            "3.9. У Пользователя может быть запрошено разрешение на предоставление доступа к учетным записям сторонних Сервисов, включая, среди прочего, общедоступное хранилище файлов, службу мгновенных сообщений, социальные сети и т. д., для того, чтобы использовать различные Сервисы Сайта. В этом случае Сайт может получать от третьих лиц дополнительные персональные данные, включая, но не ограничиваясь, пол, местоположение, аватар пользователя (userpic) и т. д. Вся информация, доступная через стороннего поставщика услуг, обрабатывается и хранится в соответствии с Пользовательским соглашением и Политикой конфиденциальности.",
        "text-29": "4. Цели обработки Персональных данных",
        "text-30":
            "4.1. Цель обработки Персональных данных Пользователя — создания Аккаунта и Личного кабинета Пользователя для работы с Сайтом и Мобильным приложением; информирование Пользователя посредством отправки электронных писем или сообщений; Осуществления клиентского сервиса и технической поддержки; предоставление доступа Пользователю к сервисам, информации и/или материалам, содержащимся на Сайте и в Приложении.",
        "text-31":
            "4.2. Также Администрация имеет право направлять Пользователю уведомления о новых продуктах и услугах, специальных предложениях и различных событиях. Пользователь всегда может отказаться от получения информационных сообщений, направив Администрации письмо на адрес электронной почты info@merlinface.com с пометкой «Отказ от уведомлений о новых продуктах и услугах и специальных предложениях».",
        "text-32":
            "4.3. В зависимости от того, как Пользователь взаимодействует с Сайтом, ему могут отправляться индивидуальные маркетинговые сообщения о продуктах и услугах, а также информация о взаимодействии с Сайтом, что позволяет корректировать рекламные кампании, например, не показывать рекламу в социальных сетях тем, кто уже является Пользователем.",
        "text-33":
            "4.4. Обезличенные данные Пользователей, собираемые с помощью сервисов интернет-статистики, служат для сбора информации о действиях Пользователей на сайте, улучшения качества сайта и его содержания.",
        "text-34":
            "4.5. При выполнении Пользователем действий в Личном кабинете в целях безопасности и для предупреждения мошеннических действий логируется следующая активность: дата и время факта регистрации и авторизаций, дата и время оформления подписки и покупки остальных видов предоставляемых услуг, дата и время отправки сообщений, дата и время факта удаления страницы профиля.",
        "text-35": "5. Правовые основания обработки Персональных данных",
        "text-36":
            "5.1. Администрация обрабатывает Персональные данные Пользователя только в случае их заполнения и/или отправки Пользователем самостоятельно через специальные формы, расположенные на Сайте и его мобильной платформе. Заполняя соответствующие формы и/или отправляя свои персональные данные Администрации, Пользователь выражает свое согласие с данной Политикой.",
        "text-37":
            "5.2. Администрация обрабатывает обезличенные данные о Пользователе в случае, если это разрешено в настройках браузера Пользователя (включено сохранение файлов «cookie» и использование технологии JavaScript).",
        "text-38":
            "5.3. Пользователь может по своей воле предоставлять Персональные данные при подключении и использовании сторонних сервисов, интегрированных в Сайт.",
        "text-39":
            "6. Порядок сбора, хранения, передачи и других видов обработки Персональных данных",
        "text-40":
            "6.1. Безопасность персональных данных, которые обрабатываются Администрацией, обеспечивается путем реализации правовых, организационных и технических мер, необходимых для выполнения в полном объеме требований действующего законодательства в области защиты Персональных данных.",
        "text-41":
            "6.2. Администрация принимает все необходимые технические и организационные меры предосторожности для защиты персональных данных пользователя от несанкционированного или случайного доступа, удаления или изменения, блокировки, копирования, разглашения или других несанкционированных действий третьих лиц. В случае потери или разглашения персональных данных Пользователя Администрация уведомляет Пользователя о факте потери или раскрытия его персональных данных.",
        "text-42":
            "6.3. У Пользователя могут быть запрошены Персональные данные и иная информация третьими лицами, например, когда необходимо совершить платеж, или добавить дополнительные функции, используя сторонние сервисы, интегрированные с Сайтом. Пользователь добровольно передает персональные данные и иную информацию. Все персональные данные, запрашиваемые третьими лицами, должны быть обработаны и храниться в соответствии с с Пользовательским соглашением и Политикой конфиденциальности указанных третьих лиц.",
        "text-43":
            "6.4. Администрация может передавать Персональные данные поставщикам, которые оказывают услуги от имени Администрации. Например, Администрация может привлекать третьих лиц для обеспечения поддержки клиентов, управления объявлениями на сторонних ресурсах, отправления маркетинговых и иных сообщений от имени Администрации или оказания помощи в хранении данных. Указанным третьим лицам запрещено использовать Персональные данные Пользователей для рекламных целей.",
        "text-44":
            "6.5. В случае выявления неточностей в Персональных данных, Пользователь может актуализировать их самостоятельно, путем направления Администрации уведомление на адрес электронной почты Администрации law@merlinface.com с пометкой «Актуализация персональных данных».",
        "text-45":
            "6.6. Срок обработки Персональных данных является неограниченным. Пользователь может в любой момент отозвать свое согласие на обработку Персональных данных, направив Администрации уведомление посредством электронной почты на электронный адрес law@merlinface.com с пометкой «Отзыв согласия на обработку персональных данных». По техническим причинам информация может быть удалена не сразу, а с задержкой. Также необходимо принять во внимание, что возможно сохранение части информации в той мере, в которой это необходимо для исполнения юридических обязательств, урегулирования споров, предотвращения мошенничества и защиты законных интересов Администрации.",
        "text-46":
            "6.7. Фотографии третьих лиц, загруженные Пользователем, не хранятся и не используются сервисом для персонализации данных или для установления личности субъекта Персональных данных.",
        "text-47":
            "6.8. Сайт является оператором Персональных данных только в отношении тех данных, которые получает непосредственно от Пользователя Сайтом и использует для поиска только информацию, загруженную Пользователем.",
        "text-48":
            "6.9. Использование Пользовательской информации и загруженных фотографий производится путём передачи фотографии на Сервер. Алгоритм Merlin проставляет точки на лице для определения параметров и выраженности черт. Фотографии пользователей не хранятся на Сервере и не используются Сервисом и/или третьими лицами. На Сервере хранятся параметры размера и выраженности черт без передачи третьим лицам.",
        "text-49":
            "6.10 Сервис аккумулирует и использует данные параметров размера и выраженности черт для того, чтобы:",
        "text-50":
            "сравнивать параметры черт двух людей для выявления совместимость, похожести и других индикаторов согласно авторской методике, заложенной в алгоритм Merlin;",
        "text-51":
            "осуществлять подбор совместимых личностей для Пользователя согласно авторской методике, заложенной в алгоритм Merlin;",
        "text-52":
            "искать личность по параметрам черт в рамках базы данных Сервиса;",
        "text-53":
            "сохранять результаты совместимости для будущих Пользователей Сайта и Приложения.",
        "text-54": "7. Политика оплаты",
        "text-55":
            "7.1. Оплата Подписки, в соответствии с которым Пользователь использует сервисы Сайта, осуществляется посредством использования одной из платежных систем, таких как, но не ограничиваясь: Visa, MasterCard, Maestro, МИР, Яндекс.Деньги, Webmoney, Qiwi, ApplePay, GooglePay, PayPal. Указанные платежные системы собирают и хранят финансовую информацию в соответствии с их Пользовательским соглашением и Политикой конфиденциальности.",
        "text-56":
            "7.2. Администрация не хранит полные реквизиты карты и не обрабатывает платежи, получая от платежной системы лишь уведомление о факте успешной оплаты.",
        "text-57":
            "7.3. В процессе получения оплаты Подписки Администрация может собирать дополнительную информацию, относящуюся к платежу, осуществляемому Пользователем, включая, среди прочего, номер транзакции, время транзакции, тип и срок действия карты, используемой для платежей, а также последние четыре цифры номера карты, имя владельца карты, страну и город, в которых произошло списание денежных средств с карты.",
        "text-58": "8. Трансграничная передача персональных данных",
        "text-59":
            "8.1. Администрация до начала осуществления трансграничной передачи Персональных данных обязана убедиться в том, что иностранным государством, на территорию которого предполагается осуществлять передачу Персональных данных, обеспечивается надежная защита прав субъектов Персональных данных.",
        "text-60":
            "8.2. Трансграничная передача Персональных данных на территории иностранных государств, не отвечающих вышеуказанным требованиям, может осуществляться только в случае наличия согласия в письменной форме субъекта Персональных данных на трансграничную передачу его Персональных данных и/или исполнения договора, стороной которого является субъект Персональных данных.",
        "text-62": "9. Ответственность сторон.",
        "text-63":
            "9.1.  В случае осуществления Пользователем обработки персональных данных третьих лиц, Пользователь самостоятельно несет единоличную ответственность за соблюдение надлежащих мер по защите персональных данных согласно регламенту о персональных данных (General Data Protection Regulation, GDPR), Федеральному закону № 152-ФЗ от 27.07.2006 “О персональных данных” и иным законам и подзаконным актам, в том числе в части получения соответствующих разрешений, размещения на Сайте Пользователя необходимых документов и информации.",
        "text-64":
            "9.2. В случае невыполнения своих обязательств Администрация принимает на себя ответственность за любые убытки, но не более, чем в пределах стоимости, уплаченной Пользователем в рамках действующего тарифа на Подписку, понесенные Пользователем в результате несанкционированного использования его персональных данных, в соответствии с законодательством Российской Федерации за исключением случаев, когда персональные данные:",
        "text-65": "а) были предоставлены властям соответствующей юрисдикции;",
        "text-66":
            "б) были раскрыты третьей стороной после того, как они были переданы Администрацией с согласия Пользователя;",
        "text-67":
            "в) стали публичными до того, как они были утеряны или раскрыты;",
        "text-68":
            "г) были получены от третьего лица до предоставления их Администрации;",
        "text-69": "д) были раскрыты с согласия Пользователя;",
        "text-70": "e) были раскрыты в результате форс-мажорных обстоятельств;",
        "text-71":
            "ж) были раскрыты в результате возникновения обоснованной претензии в адрес Пользователя о нарушении прав третьих лиц и/или Пользовательского соглашения.",
        "text-72": "10. Заключительные положения",
        "text-73":
            "10.1. Пользователь может получить любые разъяснения по интересующим вопросам, касающимся обработки его персональных данных, обратившись к Администрации с помощью электронной почты law@merlinface.com.",
        "text-74":
            "10.2. В данном документе будут отражены любые изменения политики обработки персональных данных Администрацией. Политика действует бессрочно до замены ее новой версией.",
        "text-75":
            "10.3. Актуальная версия Политики в свободном доступе расположена в сети Интернет по адресу: https://merlinface.com/docs/privacy-policy",
    },
    "subscriptions": {
        "choose-type": "Выберите, интересующую категорию подписки",
        "cancel": "Подписка успешно отменена!",
        "success": "Подписка успешно оформлена!",
        "choose-plan": "Выберите тарифный план:",
        "junior":
            "Подписка Junior включает в себя преимущества тарифа Gold по всем 4 категориям: <br/> Профессия и самореализация, самооценка и уверенность, мотивация и энергия, отношения и семья",
        "before-charges": "до начисления",
        "to-support": "обратитесь в службу поддержки",
        "left": "осталось",
        "status": "Статус подписки",
        "failed-to-renew": "не удалось продлить подписку",
        "auto-renew-active": "авто продление активно",
        "active-to": "активна до",
    },
    "RetakeOnboarding": {
        "DEFAULT": "Определить мой психотип",
        "PROFESSION_ORIENTATION": "Пройти проф.типирование",
    },

    "ConnectSocial": {
        "title": "Подключите соц.сети",
        "enabled": "Подключено",
        "enable": "Подключить",
        "enterEmail": "Введите Ваш e-mail адрес",
        "codeSend": "Вам отправлен код на почту. Введите его в это поле.",
        "youGetCode": "Вы получите письмо с кодом подтверждения",
        "retryEmail":
            "Если по какой-то причине письмо не пришло, то Вы сможете выслать код снова <span> через {timer} сек...</span>",
        "codeError": "Код не подходит",
        "retrySend": "ОТПРАВИТЬ КОД ЕЩЕ РАЗ",
    },

    "FriendActions": {
        "requestSend": "Заявка в друзья отправлена",
        "cancel": "Отменить",
        "incomingRequest": "Входящая заявка в друзья, принять?",
        "accept": "Принять",
        "decline": "Отклонить",
        "add": "Добавить",
        "sendMessage": "Написать сообщение",
        "addToFriends": "Добавить в друзья",
        "partnerRequestSend": "Заявка на партнерство отправлена!",
        "makePartner": "Сделать партнером",
    },

    "RecommendedPeopleCtrl": {
        "title": "Рекомендации людей",
    },

    "AppLoading": {
        "title": "Проверьте интернет соединение<br/>и перезагрузите страницу",
    },
    "ChooseFriendPhotos": {
        "analyze": "Анализировать",
    },
    "ServiceCollapse": {
        "openNow": "Открыть сейчас",
        "confirmTitle": "Открытие рекомендации",
        "confirmText":
            "Для того, чтобы открыть рекомендацию прямо сейчас, нужно подписаться на PLATINUM-тариф",
        "confirmBtn": "Подписаться",
    },
    "LandingHeader": {
        "advantages": "Что вы получите",
        "steps": "Как это работает",
        "mission": "Наша миссия",
        "enter": "Вход",
    },
    "LandingBanner": {
        "title":
            "Быстрый доступ к ChatGPT 4.0, DALLE-3, Midjourney, FaceSwap, Stable Diffusion, YandexGPT и Pika в&nbsp;одном&nbsp;месте",
        "list": [
            "Создавайте захватывающие и уникальные тексты",
            "Полностью оригинальные изображения",
            "Анимации, которые привлекут внимание вашей аудитории",
            "Своих GPT-ассистентов для любых задач и заработка",
        ],
        "description":
            "Используйте нейросети, чтобы начать зарабатывать в социальных сетях уже сегодня ",
        "btn": "Попробовать",
    },
    "LandingAbout": {
        "title":
            "<span>Merlinface —</span> это платформа для старта в заработке на нейротехнологиях",
        "list": [
            {
                "1": "Найдите идеи контента для ваших социальных сетей",
                "2": "Создайте сторителлинг, который по новому раскроет вас перед аудиторией",
            },
            {
                "3": "Нейросети помогут сэкономить на зарплатах команде",
                "4": "Создавайте воронки для социальных сетей",
            },
            {
                "5": "Вы сможете вести несколько проектов одновременно и не выгорать",
                "6": "",
                "7": "Создавайте свои новые фотографии без фотографа и трат на одежду",
            },
        ],
    },
    "LandingAdvantages": {
        "title": "Что Вы получите?",
        "showVideo": "Смотреть видео",
        "list": [
            {
                "id": 9,
                "active": true,
                "code": "gpt",
                "title": "Ответ на любой вопрос от нейросети",
                "description":
                    "В нашей база содержится более 1000 разных профессий. Merlin AI поможет выбрать те несколько, в которых вы реализуйтесь в высокой вероятностью",
                big: true,
            },
            {
                "id": 10,
                "active": false,
                "code": "image",
                "title": "Возможность создавать картинки на основе текста",
                "description":
                    "В нашей база содержится более 1000 разных профессий. Merlin AI поможет выбрать те несколько, в которых вы реализуйтесь в высокой вероятностью",
                big: true,
            },
            {
                "id": 1,
                "active": false,
                "code": "professional",
                "title": "Профессиональное типирование",
                "description":
                    "В нашей базе содержится более 1000 разных профессий. Merlin AI поможет выбрать 3, в которых вы добьётесь успеха.",
            },
            {
                "id": 2,
                "active": false,
                "code": "psychotype",
                "title": "Психологическое типирование",
                "description":
                    "Вы получите список всех сильных и слабых сторон, а также как их развивать.",
            },
            {
                "id": 3,
                "active": false,
                "code": "person-description",
                "title": "Описание вашего характера и особенностей поведения",
                "description":
                    "Каждый третий пользователь нашего сайта находит любимое дело.",
            },
            {
                "id": 5,
                "active": false,
                "code": "recommendations",
                "title": "Личные рекомендации",
                "description":
                    "Merlin AI подготовил для вас около 150 индивидуальных рекомендаций, которые позволят добиться успеха быстро",
            },
            {
                "id": 6,
                "active": false,
                "code": "person-recommendations",
                "title": "Рекомендации других участников платформы",
                "description":
                    "Merlin AI регулярно будет подбирать для Вас мотиваторов или людей с высокой деловой совместимостью среди которых можно найти делового партнера, друга или партнера для отношений",
            },
            {
                "id": 7,
                "active": false,
                "code": "messages",
                "title": "Ссылки на обучение по рекомендованным профессиям\n",
                "description":
                    "Вы сможете общаться с другими пользователями, которые также заинтересованы найти себе партнера для бизнеса, отношений или мотивации",
            },
            {
                "id": 8,
                "active": false,
                "code": "celebrity",
                "title": "Доступ к базе знаменитостей",
                "description":
                    "Вы можете получить возможность встретится с ними лично и начать сотрудничество, а также сравниться на совместимость.",
            },
        ],
    },
    "LandingMission": {
        "title": "Наша <span>миссия</span>",
        "description":
            "Предоставить блогерам и предпринимателям мощный инструмент для увеличения заработка и снижения издержек с помощью передовых нейротехнологий.",
        "memberCount": "Человек уже в сервисе",
        "btn": "Присоединиться",
    },
    "LandingSteps": {
        "title": "Как это работает?",
        "steps": [
            {
                "number": 1,
                "title": "Нажимайте кнопку \"Начать\" внизу",
            },
            {
                "number": 2,
                "title": "Подтвердите номер телефона",
            },
            {
                "number": 3,
                "title": "Войдите в чат и начните общение с нейросетью",
            },
            {
                "number": 4,
                "title":
                    "Решайте реальные задачи и выполняйте задания прямо в чате",
            },
            {
                "number": 5,
                "title":
                    "Получайте список подходящих вам профессий от искусственного интеллекта",
            },
        ],
        "result": {
            "title": "Получаете результат",
            "list": [
                "Доступ к популярным нейросетям в одном месте",
                "Возможность получать ответы на любые вопросы от Merlin AI",
                "Возможность создавать картинки на основе любого текста",
            ],
        },
        "btn": "Начать",
    },
    "LandingReviews": {
        "title": "<span>Отзывы</span> от участников",
    },
    "MerlinLimit": {
        "title": "Лимит исчерпан",
        "desc": "Чтобы продолжить общаться с Merlin AI перейдите на любой платный тариф",
        "btn": "Смотреть тарифы",
    },
    "SubscriptionsData": {
        "PROFESSION": {
            "TITLES": [
                {
                    "id": "PROF_OPTION_1",
                    "name": "Ваши персональные описания для самореализации",
                },
                {
                    "id": "PROF_OPTION_2",
                    "name": "Персональные рекомендации для самореализации",
                },
                {
                    "id": "PROF_OPTION_3",
                    "name": [
                        "Доступные индикаторы:",
                        "Деловая совместимость",
                        "Деловые взаимоотношения",
                        "Физическая агрессия",
                        "Эмоциональная агрессия",
                    ],
                },
                {
                    "id": "PROF_OPTION_4",
                    "name": "Проверка деловой совместимости со знаменитостью",
                },
                {
                    "id": "PROF_OPTION_5",
                    "name": "Подбор бизнес-партнеров с Merlinface",
                },
            ],
            "FREE": [
                {
                    "id": "PROF_OPTION_1",
                    "value": "30% открыто",
                },
                {
                    "id": "PROF_OPTION_2",
                    "value": "1 в неделю",
                },
                {
                    "id": "PROF_OPTION_3",
                    "value": "1 из указанных в день",
                },
                {
                    "id": "PROF_OPTION_4",
                    "value": "—",
                },
                {
                    "id": "PROF_OPTION_5",
                    "value": [
                        "1 в неделю",
                        "1 мне подбирают",
                        "к 1 вы попадаете",
                    ],
                },
            ],
            "GOLD": [
                {
                    "id": "PROF_OPTION_1",
                    "value": "полностью открыто",
                },
                {
                    "id": "PROF_OPTION_2",
                    "value": "1 в три дня",
                },
                {
                    "id": "PROF_OPTION_3",
                    "value": "1 из указанных в час",
                },
                {
                    "id": "PROF_OPTION_4",
                    "value": "1 в месяц",
                },
                {
                    "id": "PROF_OPTION_5",
                    "value": [
                        "1 в три дня",
                        "1 мне подбирают",
                        "к 3 вы попадаете",
                    ],
                },
                {
                    "id": "PROF_OPTION_6",
                    "value": "1 в неделю",
                },
            ],
            "PLATINUM": [
                {
                    "id": "PROF_OPTION_1",
                    "value": "полностью открыто",
                },
                {
                    "id": "PROF_OPTION_2",
                    "value": ["1 в день"],
                },
                {
                    "id": "PROF_OPTION_3",
                    "value": "не ограниченное количество",
                },
                {
                    "id": "PROF_OPTION_4",
                    "value": "1 в неделю",
                },
                {
                    "id": "PROF_OPTION_5",
                    "value": [
                        "1 в день",
                        "1 мне подбирают",
                        "к 5 вы попадаете",
                    ],
                },
                {
                    "id": "PROF_OPTION_6",
                    "value": "1 в неделю",
                },
            ],
        },
        "SELF_ESTEEM": {
            "TITLES": [
                {
                    "id": "ESTEEM_OPTION_1",
                    "name": "Индивидуальные описания для повышения самооценки",
                },
                {
                    "id": "ESTEEM_OPTION_2",
                    "name": "Персональные рекомендации для повышения самооценки",
                },
                {
                    "id": "ESTEEM_OPTION_3",
                    "name": [
                        "Доступные индикаторы",
                        "Схожесть характеров",
                        "Деловые взаимоотношения",
                        "Отношения с людьми",
                    ],
                },
                {
                    "id": "ESTEEM_OPTION_5",
                    "name": "Подбор людей для обмена опытом",
                },
            ],
            "FREE": [
                {
                    "id": "ESTEEM_OPTION_1",
                    "value": "30% открыто",
                },
                {
                    "id": "ESTEEM_OPTION_2",
                    "value": "1 в неделю",
                },
                {
                    "id": "ESTEEM_OPTION_3",
                    "value": "1 из указанных в день",
                },
                {
                    "id": "ESTEEM_OPTION_4",
                    "value": "—",
                },
                {
                    "id": "ESTEEM_OPTION_5",
                    "value": [
                        "1 в неделю",
                        "1 мне подбирают",
                        "к 1 вы попадаете",
                    ],
                },
            ],
            "GOLD": [
                {
                    "id": "ESTEEM_OPTION_1",
                    "value": "полностью открыто",
                },
                {
                    "id": "ESTEEM_OPTION_2",
                    "value": "1 в три дня",
                },
                {
                    "id": "ESTEEM_OPTION_3",
                    "value": "1 из указанных в час",
                },
                {
                    "id": "ESTEEM_OPTION_4",
                    "value": "1 в месяц",
                },
                {
                    "id": "ESTEEM_OPTION_5",
                    "value": [
                        "1 в три дня",
                        "1 мне подбирают",
                        "к 3 вы попадаете",
                    ],
                },
                {
                    "id": "ESTEEM_OPTION_6",
                    "value": "1 в неделю",
                },
            ],
            "PLATINUM": [
                {
                    "id": "ESTEEM_OPTION_1",
                    "value": "полностью открыто",
                },
                {
                    "id": "ESTEEM_OPTION_2",
                    "value": ["1 в день"],
                },
                {
                    "id": "ESTEEM_OPTION_3",
                    "value": "не ограниченное количество",
                },
                {
                    "id": "ESTEEM_OPTION_4",
                    "value": "1 в неделю",
                },
                {
                    "id": "ESTEEM_OPTION_5",
                    "value": [
                        "1 в день",
                        "1 мне подбирают",
                        "к 5 вы попадаете",
                    ],
                },
                {
                    "id": "ESTEEM_OPTION_6",
                    "value": "1 в неделю",
                },
            ],
        },
        "MOTIVATION": {
            "TITLES": [
                {
                    "id": "MOTIVATION_OPTION_1",
                    "name": "Ваши персональные описания для повышения мотивации",
                },
                {
                    "id": "MOTIVATION_OPTION_2",
                    "name": "Персональные рекомендации для повышения мотивации",
                },
                {
                    "id": "MOTIVATION_OPTION_3",
                    "name": "Подбор людей-мотиваторов",
                },
            ],
            "FREE": [
                {
                    "id": "MOTIVATION_OPTION_1",
                    "value": "30% открыто",
                },
                {
                    "id": "MOTIVATION_OPTION_2",
                    "value": "1 в неделю",
                },
                {
                    "id": "MOTIVATION_OPTION_3",
                    "value": [
                        "1 в месяц",
                        "1 мне подбирают",
                        "к 1 вы попадаете",
                    ],
                },
            ],
            "GOLD": [
                {
                    "id": "MOTIVATION_OPTION_1",
                    "value": "полностью открыто",
                },
                {
                    "id": "MOTIVATION_OPTION_2",
                    "value": "1 в три дня",
                },
                {
                    "id": "MOTIVATION_OPTION_3",
                    "value": [
                        "1 в три дня",
                        "1 мне подбирают",
                        "к 1 вы попадаете",
                    ],
                },
            ],
            "PLATINUM": [
                {
                    "id": "MOTIVATION_OPTION_1",
                    "value": "полностью открыто",
                },
                {
                    "id": "MOTIVATION_OPTION_2",
                    "value": ["1 в день"],
                },
                {
                    "id": "MOTIVATION_OPTION_3",
                    "value": [
                        "1 в день",
                        "1 мне подбирают",
                        "к 1 вы попадаете",
                    ],
                },
            ],
        },
        "RELATIONS": {
            "TITLES": [
                {
                    "id": "RELATIONS_OPTION_1",
                    "name": "Ваши особенности при выстраивании отношений",
                },
                {
                    "id": "RELATIONS_OPTION_2",
                    "name": "Индивидуальные рекомендации для улучшения отношений",
                },
                {
                    "id": "RELATIONS_OPTION_3",
                    "name": [
                        "Доступные индикаторы:",
                        "Совместимость в отношениях",
                        "Отношения с людьми",
                        "Общая агрессия",
                        "Физическая агрессия",
                        "Эмоциональная агрессия",
                        "Социальная агрессия",
                    ],
                },
                {
                    "id": "RELATIONS_OPTION_4",
                    "name": "Сравнение на совместимость со знаменитостью",
                },
                {
                    "id": "RELATIONS_OPTION_5",
                    "name": "Подбор партнеров для отношений",
                },
            ],
            "FREE": [
                {
                    "id": "RELATIONS_OPTION_1",
                    "value": "30% открыто",
                },
                {
                    "id": "RELATIONS_OPTION_2",
                    "value": "1 в неделю",
                },
                {
                    "id": "RELATIONS_OPTION_3",
                    "value": "1 из указанных в день",
                },
                {
                    "id": "RELATIONS_OPTION_4",
                    "value": "—",
                },
                {
                    "id": "RELATIONS_OPTION_5",
                    "value": [
                        "1 в неделю",
                        "1 мне подбирают",
                        "к 1 вы попадаете",
                    ],
                },
            ],
            "GOLD": [
                {
                    "id": "RELATIONS_OPTION_1",
                    "value": "полностью открыто",
                },
                {
                    "id": "RELATIONS_OPTION_2",
                    "value": "1 в три дня",
                },
                {
                    "id": "RELATIONS_OPTION_3",
                    "value": "1 из указанных в час",
                },
                {
                    "id": "RELATIONS_OPTION_4",
                    "value": "1 в месяц",
                },
                {
                    "id": "RELATIONS_OPTION_5",
                    "value": [
                        "1 в три дня",
                        "1 мне подбирают",
                        "к 3 вы попадаете",
                    ],
                },
                {
                    "id": "RELATIONS_OPTION_6",
                    "value": "1 в неделю",
                },
            ],
            "PLATINUM": [
                {
                    "id": "RELATIONS_OPTION_1",
                    "value": "полностью открыто",
                },
                {
                    "id": "RELATIONS_OPTION_2",
                    "value": "1 в день",
                },
                {
                    "id": "RELATIONS_OPTION_3",
                    "value": "не ограниченное количество",
                },
                {
                    "id": "RELATIONS_OPTION_4",
                    "value": "1 в неделю",
                },
                {
                    "id": "RELATIONS_OPTION_5",
                    "value": [
                        "1 в день",
                        "1 мне подбирают",
                        "к 5 вы попадаете",
                    ],
                },
                {
                    "id": "RELATIONS_OPTION_6",
                    "value": "1 в неделю",
                },
            ],
        },
    },
    "TariffJunior": {
        "title-1": "Подписка JUNIOR активна",
        "title-2": "Junior",
        "desc": "Подписка Junior включает в себя преимущества тарифа Gold по всем 4 категориям: <br/>Профессия и самореализация, самооценка и уверенность, мотивация и энергия, отношения и семья",
        "cancelSubscription": "Отменить подписку",
        "confirm": {
            "title": "Отмена подписки",
            "desc": "Вы подтверждаете, что хотите отменить подписку JUNIOR? Все преимущества будут доступны до конца оплаченного периода",
            "btn": "Отменить подписку",
        },
    },
    "SubscriptionPeriodLabel": {
        "MONTH": "в месяц",
        "ANNUAL": "в год",
    },
    "SubscriptionPeriodOptions": {
        "MONTH": "ежемесячная",
        "ANNUAL": "ежегодная",
    },
    "Tips": {
        "close": "Закрыть подсказку",
        "prev": "Назад",
        "next": "Дальше",
        "disable": "Отключить подсказки",
        "categories": {
            "PROFESSIONAL": "Профессия и предназначение",
            "RELATIONS": "Отношения и семья",
            "MOTIVATION": "Мотивация и эффективность",
            "SELFASSESSMENT": "Самооценка и уверенность",
        },
        "list": [
            {
                "name": "chat-1",
                "element": ".dialogs__chat",
                "title": "👋 Добро пожаловать в раздел «Сообщения»!",
                "subtitle":
                    "🤖 Merlin AI  — это наш искусственный интеллект. Ему можно задавать вопросы или попросить нарисовать картинку.",
                "timeout": 500,
                "next": "chat-2",
                "prev": false,
                "showOn": ["desktop", "mobile"],
                "hideAfterShow": true,
            },
            {
                "name": "chat-2",
                "element": ".dialogs__theme:not(.pinned)",
                "title": "Текстовый помощник",
                "subtitle":
                    "✏️ Чтобы начать переписку, просто выбери чат с Merlin AI и напишите свой вопрос.",
                "timeout": 100,
                "next": "chat-3",
                "prev": "chat-1",
                "showOn": ["desktop", "mobile"],
                "hideAfterShow": true,
            },
            {
                "name": "chat-3",
                "element": ".dialogs__theme",
                "title": "Создание изображений",
                "subtitle":
                    "📷 Чтобы создать картинку - перейдите в один из закрепленных чатов и напиши текстом то, что хотите создать.",
                "timeout": 100,
                "next": "chat-4",
                "prev": "chat-2",
                "showOn": ["desktop", "mobile"],
                "hideAfterShow": true,
            },
            {
                "name": "chat-4",
                "element": ".dialogs__new-theme",
                "title": "Новые чаты",
                "subtitle":
                    "➕ Чтобы создать новый чат, нажмите на кнопку «+ Новый чат». Вы можете создать множество чатов и в каждом из них общаться с Merlin AI",
                "timeout": 100,
                "next": "chat-5",
                "prev": "chat-3",
                "showOn": ["desktop", "mobile"],
                "hideAfterShow": true,
            },
            {
                "name": "chat-5",
                "element": "[data-tip='my-profile']",
                "title": "Общение с друзьями",
                "subtitle":
                    "👫 Хотите общаться с друзьями? Завершите создания профиля на платформе Merlinface в разделе «Моя страница», ищите друзей и начинайте переписку!",
                "timeout": 100,
                "next": false,
                "prev": "chat-4",
                "showOn": ["desktop", "mobile"],
                "hideAfterShow": true,
            },
            {
                "name": "auth",
                "element": ".auth-form",
                "title": "Зарегистрируйтесь по номеру телефона",
                "subtitle":
                    "Это необходимо для того, чтобы доступ к  данным был только у вас, а также не нужно было придумывать пароль.",
                "timeout": 2000,
                "next": false,
                "prev": false,
                "showOn": ["desktop", "mobile"],
                "hideAfterShow": true,
            },
            {
                "name": "avatar",
                "element": "[data-tip='avatar']",
                "title": "Установите свой аватар",
                "subtitle":
                    "Без аватара алгоритм не будет Вас рекомендовать другим пользователям сайта.",
                "timeout": 2000,
                "next": "recommendations",
                "prev": false,
                "showOn": ["desktop", "mobile"],
                "hideAfterShow": true,
            },
            {
                "name": "recommendations",
                "element": "[data-tip='recommendations']",
                "title": "",
                "subtitle":
                    "Здесь вы найдёте рекомендации, которые помогут вам в разных целях.",
                "timeout": 2000,
                "next": "people-recommendations",
                "prev": "avatar",
                "showOn": ["desktop", "mobile"],
                "hideAfterShow": true,
            },
            {
                "name": "people-recommendations",
                "element": "[data-tip='people-recommendations']",
                "title": "",
                "subtitle":
                    "Здесь вы найдёте рекомендованных Вам людей по разным категориям",
                "timeout": 2000,
                "next": "search",
                "prev": "recommendations",
                "showOn": ["desktop", "mobile"],
                "hideAfterShow": true,
            },
            {
                "name": "sphere",
                "element": "[data-tip='sphere']",
                "title":
                    "Есть 4 основных направления, в которых у людей часто возникают проблемы",
                "subtitle":
                    "Я уже проанализировал ваши фото и сформировал персональное описание Осталось нажать на интересующую категорию.",
                "timeout": 200,
                "next": false,
                "prev": false,
                "showOn": ["desktop", "mobile"],
                "hideAfterShow": true,
            },
            {
                "name": "inside-sphere",
                "element": "[data-tip='sphere']",
                "title": "Открытые и закрытые категории сферы",
                "subtitle":
                    "Желтым цветом показаны те, то уже открыты для прочтения. Темные можно открыть за оформление подписки на интересующие категории.",
                "timeout": 200,
                "next": false,
                "prev": false,
                "showOn": ["desktop", "mobile"],
                "hideAfterShow": true,
            },
            {
                "name": "search",
                "element": "[data-tip='search']",
                "title": "",
                "subtitle":
                    "Через окно поиска можно найти любую российскую знаменитость и людей по имени или фамилии.",
                "timeout": 2000,
                "next": "notifications",
                "prev": "people-recommendations",
                "showOn": ["desktop", "mobile"],
                "hideAfterShow": true,
            },
            {
                "name": "notifications",
                "element": "[data-tip='notifications']",
                "title": "Колокольчик - кнопка уведомлений",
                "subtitle":
                    "Там отображаются сообщения о разных бонусах за активные действия внутри социальной сети, уведомления если с вами кто-то проверил совместимость.",
                "timeout": 2000,
                "next": "subscriptions",
                "prev": "search",
                "showOn": ["desktop", "mobile"],
                "hideAfterShow": true,
            },
            {
                "name": "subscriptions",
                "element": "[data-tip='subscriptions']",
                "title": "",
                "subtitle":
                    "Здесь вы найдёте список возможных подписок и Ваши активные подписки.",
                "timeout": 2000,
                "next": false,
                "prev": "notifications",
                "showOn": ["desktop", "mobile"],
                "hideAfterShow": true,
            },
            {
                "name": "multi-compare",
                "element": "[data-tip='multi-compare']",
                "title": "Выберите категории",
                "subtitle":
                    "Вы можете выбрать несколько категорий, по которым хотите сравниться со своими друзьями. Доступны только те сравнения, по которым у вас активирован тариф PLATINUM.",
                "timeout": 2000,
                "next": false,
                "prev": false,
                "showOn": ["desktop", "mobile"],
                "hideAfterShow": false,
            },
            {
                "name": "choose-avatar",
                "element": "[data-tip='choose-avatar']",
                "title": "Установите свой аватар",
                "subtitle":
                    "Тогда я смогу рекомендовать Вас другим людям. Я не смогу продвигать Вас без фотографии.",
                "timeout": 200,
                "next": false,
                "prev": false,
                "showOn": ["desktop", "mobile"],
                "hideAfterShow": false,
            },
            {
                "name": "recommendations-modal",
                "element": "[data-tip='recommendations-modal']",
                "title": "Личные рекомендации",
                "subtitle":
                    "У Вас будет открываться одна рекомендация по истечению счетчика. Счётчик на следующую рекомендацию не будет запускаться, пока Вы не прочитаете последнюю доступную.",
                "timeout": 500,
                "next": false,
                "prev": false,
                "showOn": ["desktop", "mobile"],
                "hideAfterShow": true,
            },
        ],
    },
    "WelcomeLayout": {
        "ctrl": {
            "step-1": "Тест с вопросами",
            "step-2": "Тест фотографиями",
            "step-3": "Тест фотографиями",
            "step-4": "Тест профессий",
        },
        "back-to-main": "Вернуться на главную",
        "back-to-main-confirm-title": "Подтвердите действие",
        "back-to-main-confirm-desc":
            "Вы действительно хотите вернуться на главную страницу? Весь пройденный прогресс будет безвозвратно потерян.",
        "back-to-main-confirm-apply": "Вернуться на главную",
    },
    "WelcomeWant": {
        "title": "Поздравляю, первый шаг пройден.",
        "description":
            "Меня зовут Merlin и я помогу получить то, что Вам действительно нужно. <br/>Укажите, что Вы хотите получить:",
    },
    "WelcomeTest": {
        "title-1": "Время прохождения теста 15 минут",
        "title-2": "Мы определили 12 сфер, которые Вам подходят!",
        "title-3": "ВЫ МОЛОДЕЦ! До новых знаний о себе осталось 2 этапа",
        "subtitle-1": "Теперь небольшой психологический тест на ",
        "subtitle-2": "Продолжим отсекать лишнее. Ответьте еще на",
        "subtitle-3":
            "Последние {count} вопроса для определения типа Вашей личности. <br/> Чтобы определить сферы, которые Вам подходят",
        "question-destination-1": "вопрос",
        "question-destination-2": "вопроса",
        "question-destination-3": "вопросов",
        "choose-title": "Выберите вариант, который лучше Вас описывает:",
        "next-question": "Следующий вопрос",
        "quote-1": "В каждом вопросе Вы найдёте себя",
        "quote-2":
            "Иметь силу выбрать то, что тебе по душе, и не отступаться. Иначе лучше умереть. (с) Альбер Камю",
        "quote-3":
            "Совершенный человек всё ищет в себе, ничтожный - в других. (с) Конфуций",
    },
    "WelcomePhotoTest": {
        "title": "До результата остался 1 этап",
        "subtitle": "ПСИХОЛОГИЧЕСКИЙ ТЕСТ на определение ваших сильных сторон",
        "choose-title": "Какое лицо Вам нравится больше?",
        "next-btn": "Следующие фотографии",
    },
    "WelcomeCheckPay": {
        "title-success": "Оплата прошла успешно",
        "title-error": "Ошибка при оплате",
        "description-success":
            "Вы будете переадресованы на следующий шаг через 5 секунд...",
        "description-error": "Чтобы продолжить, необходимо произвести оплату",
        "btn-success": "Продолжить",
        "btn-error": "Повторить попытку",
    },
    "WelcomeAnalyze": {
        "title": "Анализирую ответы",
    },
    "WelcomePhotos": {
        "title": "УЛУЧШИТЕ СВОЙ РЕЗУЛЬТАТ",
        "subtitle":
            "Ваши ответы анализирует нейросетевой алгоритм.<br/> Загрузите свои фотографии, чтобы получить более точный подбор профессий.",
        "upload-photos-title":
            "Загрузите свои фотографии, чтобы получить более точный подбор профессий",
        "next-btn": "Дальше",
        "skip-btn": "Пропустить",
    },
    "WelcomeAnalyzeReady": {
        "title": "У меня получилось убрать 170 не подходящих профессий.",
        "subtitle": "За 10 минут мы выберем лучшие профессии для Вас.",
        "desc": "Не теряйте время на сферы, которые не подходят.<br/> Тест поможет ускорить процесс трудоустройств<br/> 90% людей прошедших тест нашли любимое дело и реализовали себя",
        "quote":
            "Любое предназначение требует реализации. (с) Аристарх Ромашин",
        "start-btn": "Начать",
    },
    "WelcomeProfessionVote": {
        "title-1": "",
        "title-2": "Отлично! Осталось {count} {decl} из 1150",
        "title-3": "Отлично! Мы уже близко",
        "title-4": "Нажмите на новую профессию и мы продолжим",
        "subtitle-1":
            "В этом круге я выделил сферы деятельностей, в которых Вы бы могли максимально себя раскрыть.<br/>Я оставил {count} {decl} из 1150. Помогите мне подобрать лучшую для Вас. <br/><br/>Нажмите на раздел, который выделен желтым",
        "subtitle-2": "Нажмите на раздел, который выделен желтым",
        "subtitle-3": "Осталось {count} {decl} из 1150",
        "subtitle-4": "Осталось всего {count} {decl} из 1150",
        "profession-declination-1": "профессию",
        "profession-declination-2": "профессии",
        "profession-declination-3": "профессий",
    },
    "ProfessionVotePopup": {
        "help-1":
            "Отлично. я запомнил Ваш ответ. Теперь заполните следующие несколько профессий и нажмите Рассчитать.",
        "help-2":
            "Если Вы не знаете что это за профессия, то просто нажмите на название.",
        "help-3":
            "Тут написано, почему Вам может быть интересно этим заниматься и какие Ваши качества могут помочь Вам в этом.",
        "help-4":
            "Это пример профессии, в которой Вы бы могли отлично развиться. Оцените, на сколько она для Вас интересна. <br/><br/>Оценивайте вдумчиво, я использую Ваши ответы для подбора лучшей профессии <br/><br/>Проголосуйте за первую профессию",
        "error": "Пожалуйста, проголосуйте за следующие профессии:",
        "next-btn": "Продолжить",
        "calc-btn": "Рассчитать",
    },
    "OnboardDescriptionPopupRadio": {
        "list": [
            {
                "id": 1,
                "text": "Никогда не интересовало",
                "showSub": false,
            },
            {
                "id": 2,
                "text": "Никогда не пробовал, но мне интересно",
            },
            {
                "id": 3,
                "text": "Изучаю сейчас эту сферу",
            },
            {
                "id": 4,
                "text": "Я пробовал, что-то не получилось",
            },
            {
                "id": 5,
                "text": "Активно этим занимаюсь и у меня получается",
            },
            {
                "id": 6,
                "text": "Я специалист в этом с большим опытом",
            },
        ],
    },
    "WelcomeProfPay": {
        "title-1": "Из 1150 профессий Вам подходят 3",
        "title-2": "Выберите способ оплаты",
        "desc-title":
            "{count} человек нашли любимое дело и повысили свой доход",
        "desc": "👉 87% прошедших этот тест рекомендуют его свои близким.<br/><br/>👉 Тестом пользуются топ-менеджеры 38 ведущих компаний страны.<br/><br/>👉 Получите личные рекомендации как добиться успеха в профессии.",
        "open-btn": "Открыть за 990 руб.",
        "motivation": "Вы уже вложили 15 минут, остался последний шаг!",
        "recreate-text": {
            "btn": "Пройти тест заново",
            "confirm-title": "Начать тест заново?",
            "confirm-desc":
                "Если Вы считаете, что прошли тест не корректно - Вы можете пройти его заново еще раз. Внимание: прогресс пройденного теста будет безвозвратно удалён.",
            "confirm-btn": "Пройти тест заново",
        },
        "partners": {
            "title": "Одобрено нашими партнёрами:",
            "list": [
                "Министерство образования РФ",
                "Фонд содействия инновациям",
                "B20 Indonesia 2022",
                "Сколково",
                "Merlin AI - оф. партнёр Телеграмма",
            ],
        },
        "paymentMethods": {
            "all": "Показать все способы оплаты",
            "phone": {
                "title": "1. По номеру телефона",
                "copy": "скопировать",
                "copied": "скопировано",
                "copy-number": "Скопировать номер",
            },
            "link": {
                "title": "1. По ссылке",
                "btn": "Перейти по ссылке",
            },
            "kassa": {
                "title": "2. Через Робокассу",
                "btn": "Оплатить",
            },
            "qr": {
                "title": "4. По QR коду",
                "btn": "Выбрать",
            },
        },
        "after-pay":
            "После оплаты поставьте галочку, чтобы мы проверили платёж",
        "after-pay-label": "Оплачено 499 руб.",
        "after-pay-btn": "Продолжить",
    },
    "WelcomeProfessions": {
        "title":
            "Мы определили сферы, к которым<br/> Вы наиболее предрасположены",
        "title-1":
            "Великолепно!<br/>Мы определили профессии, которые подходят Вам лучше всего",
        "title-2": "Отлично!<br/>Теперь",
        "title-3": "Здорово!<br/>Теперь",
        "subtitle-1":
            "Нажмите на профессию, которая интересует Вас <b class='gold'>больше</b> других",
        "subtitle-2":
            "Чем больше связей - тем больше подходит сфера<br><br>Нажмите на любую сферу и увидите подходящие профессии",
        "title-helper": "профессии из сферы",
        "these": "Эти",
        "merlin-help":
            "Я помогу Вам их освоить. На Merlinface есть крутые специалисты, которые уже успешны в этом. Я Вас познакомлю с ними. <br/><br/>Но прямо сейчас у меня есть еще кое-что...",
        "more-btn": "Узнать",
        "next-btn": "Продолжить",
    },
    "WelcomeBusinessCardInfo": {
        "info-title": "Заполните немного информации о себе",
        "edu-title": "Заполните информацию о вашем образовании",
        "career-title": "Заполните информацию о месте работы",
        "subtitle":
            "Эту информацию увидят другие пользователи при посещении вашей страницы. Среди них может оказаться ваш потенциальный деловой партнер",
        "all-fields-error": "Все поля обязательны к заполнению",
        "edu-fields-error": "Добавьте хотя бы одно место учёбы",
        "career-fields-error": "Добавьте хотя бы одно место работы",
    },
    "WelcomeBusinessCardPay": {
        "loading": "Загрузка...",
    },
    "WelcomeStart": {
        "title": "Как Вас зовут?",
        "subtitle": "",
    },
    "WelcomePersonInfo": {
        "title": "Очень приятно, ",
        "subtitle": "Заполните немного информации о себе:",
        "age": "Возраст",
        "age-placeholder": "Сколько Вам лет?",
        "age-error": "Укажите возраст от 14 до 99 лет",
        "choose-country": "Выберите страну",
        "choose-city": "Выберите город",
        "country-placeholder": "Поиск по странам",
        "city-placeholder": "Поиск по городам",
        "city-no-options":
            "Введите название города для поиска в выбранной стране",
        "social-title": "Ваш социальный статус:",
        "social-error": "Выберите социальный статус",
        "next-btn": "Продолжить",
    },
    "WelcomeTestComplete": {
        "title": "Ваш психотип",
        "your-type": "У нас на сайте {count} {dec} с Вашим психотипом",
        "your-partners":
            "{count} {decl} с высокой деловой совместимостью с Вами.",
        "celebrity":
            "{count} знаменитостей и успешных личностей Вашего психотипа.",
        "next-btn": "Продолжить",
        "typeDescriptions": {
            "Посредник":
                "Ваши естественные таланты делают вас замечательным посредником. Вы умеете сглаживать конфликты и находить общий язык с разными людьми. Ваши способности слушать и понимать потребности других делают вас ценным членом любой команды. Ваше внимание к деталям и стремление к гармонии приводят к построению крепких и продуктивных отношений.",
            "Коммуникатор":
                "Вы обладаете уникальным даром коммуникации. Ваши навыки позволяют вам эффективно общаться, выражать свои мысли и идеи, уважая при этом точки зрения других. Ваши способности сделать общение легким и приятным помогают вам с успехом решать профессиональные задачи.",
            "Коннектор":
                "Вы - замечательный коннектор, способный соединять людей и идеи. Ваши навыки социальной адаптации и внимание к окружающим позволяют вам быть в центре профессиональных сетей. Ваши организаторские способности помогают вам создавать успешные команды и проекты.",
            "Гедонист":
                "Вы находите радость и удовольствие в профессиональной жизни. Ваши навыки позволяют вам наслаждаться работой и добиваться отличных результатов. Вы цените комфорт, гармонию и находите способы сделать рабочий процесс максимально приятным и эффективным.",
            "Дипломат захватчик":
                "Вы обладаете уникальным даром общения и способностью влиять на людей. Ваши таланты позволяют вам найти общий язык в любых условиях и добиваться своего, что делает вас необычайно эффективным в профессиональной сфере. Вы ориентированы на достижения и успех, стремитесь к лидерству и активному воздействию на окружающий мир. Не менее важно, что вы умеете грамотно и тактично вести переговоры, что позволяет вам успешно решать самые сложные задачи.",
            "Лоббист":
                "У вас есть уникальные навыки убеждения и влияния на людей. Ваши способности позволяют вам легко налаживать контакты, располагая к себе окружающих и добиваясь своих целей. Вы умеете управлять процессами, строить стратегии и лоббировать интересы. Ваша способность влиять на других делает вас ценным специалистом в любой отрасли. Ваши инициативность и организаторские навыки позволяют вам успешно работать в сложных условиях и преодолевать препятствия.",
            "Политик":
                "Ваши способности к лидерству и влиянию позволяют вам быть успешным в политической сфере. Вы умеете принимать важные решения, разрешать конфликты и находить общий язык с людьми разных взглядов. Ваша активная жизненная позиция, стремление к переменам и способность вовлекать других в свои идеи делают вас эффективным политиком. Ваши коммуникабельность и уверенность в себе позволяют вам достигать своих целей и вносить значимый вклад в общественные процессы.",
            "Материалист":
                "Вы цените материальное благополучие и умеете работать на результат. Ваши прагматизм и стремление к конкретным целям помогают вам оставаться на плаву в любых обстоятельствах. Вы способны грамотно управлять ресурсами, планировать и эффективно реализовывать свои планы. Ваши навыки организации, оптимизации и принятия решений делают вас незаменимым специалистом в любой команде или проекте.",
            "Консерватор":
                "Ваши таланты и навыки прекрасно сочетаются с ролью консерватора. Вы цените стабильность, порядок и традиции, и можете внести эти ценности в любую профессиональную область. Вы обладаете способностью организовать и систематизировать, сохраняя при этом внимание к деталям. Ваши усилия и упорство в достижении целей создают надежные и стабильные структуры.",
            "Социализатор":
                "Ваша способность вовлекать людей и создавать сильные социальные связи делает вас идеальным социализатором. Ваши навыки в области коммуникации и социального взаимодействия помогают вам формировать и поддерживать сети, которые существенно повышают эффективность вашей работы.",
            "Приверженец":
                "Вы идеально подходите для роли приверженца. Ваши навыки и таланты позволяют вам быть верным и надежным в достижении общих целей. Ваше усердие, отзывчивость и умение работать в команде делают вас незаменимым участником любого проекта.",
            "Хранитель":
                "Ваша способность заботиться о других и поддерживать порядок делает вас идеальным хранителем. Ваши навыки позволяют вам сохранять ценности и ресурсы, в то время как ваша ответственность и дисциплинированность помогают вам обеспечивать стабильность и надежность в любой ситуации.",
            "Массовик затейник":
                "С вашими талантами и навыками вы как никто подходите для роли массовика затейника. Вы обладаете уникальной способностью поднимать настроение, организовывать и вовлекать людей в интересные идеи. Ваши креативность и энтузиазм помогают вам быстро адаптироваться к новым ситуациям и решать задачи с высоким уровнем мотивации. Ваша энергия и эмпатия делают вас любимым членом любой команды.",
            "Организатор":
                "Вы обладаете естественными навыками организации и планирования. Ваши способности к коммуникации и умение управлять ресурсами позволяют вам эффективно координировать людей и задачи. Вы умеете организовывать работу так, чтобы она была не только продуктивной, но и приятной для всех участников. Ваши лидерские качества и способность поддерживать командный дух делают вас важным элементом в любом проекте.",
            "Оптимист":
                "Ваш оптимизм и позитивный взгляд на жизнь дает вам преимущества в любой профессиональной области. Ваши умение находить положительные стороны в любой ситуации и способность поднимать настроение окружающим позволяют вам быстро находить решения и справляться с трудностями. Ваши энтузиазм и жизнерадостность вносят в команду позитивную энергию, что повышает общую эффективность.",
            "Активист":
                "Ваши энергия, активность и способность влиять на людей делают вас идеальным активистом. Вы не только способны активно участвовать в процессе, но и вовлекать в него других. Ваши навыки коммуникации и умение организовывать работу помогают вам успешно продвигать свои идеи и достигать поставленных целей. Ваши энтузиазм и уверенность в себе вдохновляют других и позволяют достигать высоких результатов.",
            "Идейный вдохновитель":
                "Ваша способность видеть мир в более широком контексте и ваш креативный подход к решению проблем делают вас идеальным идейным вдохновителем. Ваши идеи могут зажечь искру в сердцах людей, стимулируя их к новым свершениям. Ваша интуиция и острое чувство возможностей позволяют вам предвидеть тенденции и создавать инновационные решения.",
            "Романтик":
                "Ваши чувствительность и глубокое восприятие мира делают вас настоящим романтиком. Вы обладаете уникальной способностью видеть прекрасное в обыденном и транслировать свои эмоции в вашу работу. Ваша способность вдохновляться и вдохновлять других может быть ключом к новым идеям и перспективам.",
            "Меценат":
                "Вы обладаете естественной способностью к восприятию красоты и гармонии, а также способностью поддерживать и развивать творческие идеи. Ваше стремление помогать другим, поддерживать их творчество и уважение к искусству делают вас идеальным меценатом.",
            "Мечтатель":
                "Ваша способность генерировать идеи, глубоко проникаться эмоциями и видеть большую картину делает вас идеальным мечтателем. Вы не боитесь мечтать и позволять своим идеям летать. Ваша вдохновленность и воображение позволяют вам видеть перспективы и новые возможности, куда бы вы ни направили свои усилия.",
            "Идейный лидер":
                "Ваша способность генерировать и реализовывать новые идеи, в сочетании с умением вдохновлять и мотивировать других, делает вас идеальным идейным лидером. Вы умеете видеть дальше привычных рамок и открывать новые горизонты для себя и своей команды. Ваши прогрессивные мысли, креативный подход и непрерывное стремление к новизне становятся движущей силой для тех, кто следует за вами.",
            "Имиджмейкер":
                "Ваше творческое видение и острое чувство стиля позволяют вам создавать и поддерживать образы, которые влияют на людей. Вы обладаете уникальным умением выражать идеи и ценности через внешний образ, создавая мощные и запоминающиеся впечатления. Ваши чувство красоты, умение адаптироваться к новым трендам и творческий подход к визуальному стилю делают вас неотъемлемым элементом любой команды.",
            "Идейник":
                "Ваша способность генерировать и развивать оригинальные идеи делает вас идеальным идейником. Ваш креативный подход к решению проблем, нестандартное мышление и способность видеть возможности, где другие видят препятствия, помогают вам в создании новаторских решений и внедрении их в практику. Ваши идеи вдохновляют других и стимулируют изменения.",
            "Инициатор":
                "Ваша энергия и стремление к изменениям делают вас идеальным инициатором. Вы обладаете уникальной способностью запускать новые проекты, интегрировать разные идеи и вести людей к общей цели. Ваша способность начинать что-то новое, невзирая на препятствия, и ваша открытость новым идеям делают вас незаменимым в любом проекте, особенно в тех, которые требуют новаторских подходов.",
            "Проповедник":
                "Ваши убеждения, чувствительность к нюансам эмоционального состояния окружающих и ваше искреннее желание делать мир лучше делают вас идеальным проповедником. Ваша страсть к помощи другим и способность глубоко проникнуться их переживаниями позволяют вам эффективно взаимодействовать с людьми и оказывать значительное влияние на них. Вы видите суть вещей и можете ясно передать свои мысли и идеи.",
            "Моралист":
                "Вы обладаете глубокой этической осведомленностью и стремлением к правильности и справедливости, что делает вас идеальным моралистом. Ваше понимание человеческой природы, уважение к ценностям и принципам, а также ваше стремление улучшать мир вокруг вас делают вас незаменимым в любой команде или организации.",
            "Вдохновитель":
                "Ваша способность вдохновлять и мотивировать других, а также ваше эмпатическое отношение к окружающим, делают вас идеальным вдохновителем. Ваша глубокая чувствительность и понимание человеческой природы позволяют вам влиять на людей и помогать им раскрыть свой потенциал. Ваша способность сопереживать и внимательно слушать делает вас незаменимым в любой команде или организации.",
            "Педагог":
                "Ваше стремление к обучению и ваше глубокое понимание человеческой природы делают вас идеальным педагогом. Вы обладаете навыками проникновения в суть проблем и предложения решений, а также умением помочь другим раскрыть и развить свои способности. Ваша способность быть терпеливым, внимательным и чутким обеспечивает вам уважение и доверие со стороны учащихся.",
            "Эмоциональный лидер":
                "Ваши эмоциональная чувствительность, сильное влияние на других и умение устанавливать тесные связи делают вас идеальным эмоциональным лидером. Ваша способность улавливать и отражать эмоции окружающих, а также поддерживать и мотивировать вашу команду, может значительно повысить их продуктивность и уровень удовлетворенности.",
            "Энтузиаст":
                "Ваша энергия, открытость и оптимизм позволяют вам вдохновлять и мотивировать окружающих. Вы с легкостью вносите энтузиазм и положительные эмоции в любую работу, которую вы выполняете. Ваша способность видеть светлое будущее и делиться этим видением с другими делает вас неотъемлемым членом любой команды.",
            "Наставник":
                "Ваше чуткое восприятие потребностей других и ваше умение находить подход к людям делает вас идеальным наставником. Ваше терпение, эмпатия и способность передавать свои знания и опыт помогают вам обучать и вдохновлять других на достижение их целей.",
            "Артист":
                "Ваша креативность, эмоциональное выражение и уникальное видение мира делают вас идеальным артистом. Ваши способность к самовыражению и творческому мышлению, а также ваше умение видеть красоту вокруг и передавать ее другим, позволяют вам создавать уникальные и влиятельные произведения.",
            "Мастер":
                "Ваша детальная ориентация, практическое мышление и навыки решения сложных задач делают вас идеальным мастером. Вы внимательно изучаете все детали, стараясь достичь максимального качества в своей работе. Ваша способность работать с руками, улучшать и ремонтировать вещи очень ценна во многих профессиях.",
            "Оптимизатор":
                "Ваше стремление к эффективности, практичность и умение находить рациональные решения делают вас идеальным оптимизатором. Вы всегда ищете способы улучшить процессы, сделать работу проще и более эффективной. Ваша способность видеть \"большую картину\", анализировать сложные системы и оптимизировать их работу является вашим ключевым активом.",
            "Технарь":
                "Ваша техническая осведомленность, способность к аналитическому мышлению и умение разбираться в сложных системах делают вас идеальным технарём. Вы умеете работать с технологиями, понимать их принципы работы и применять их для решения практических задач. Ваша способность быстро учиться и адаптироваться к новым технологиям является большим преимуществом.",
            "Умелец":
                "Ваше умение работать руками, практичность и творческий подход к решению проблем делают вас идеальным умельцем. Вы обладаете способностью создавать, ремонтировать и улучшать вещи своими руками. Ваше внимание к деталям, умение работать с материалами и инструментами, а также ваша страсть к созданию чего-то нового и полезного являются вашими сильными сторонами.",
            "Напористый захватчик":
                "Ваша решительность, умение быстро принимать решения и внушительное лидерство делают вас идеальным \"напористым захватчиком\". Вы умеете видеть возможности, где другие видят препятствия, и всегда готовы принять вызов. Вы мотивируете и вдохновляете свою команду на достижение целей, даже если это требует больших усилий и рисков.",
            "Лидер":
                "Ваша смелость, уверенность в себе и способность мобилизовать людей делают вас идеальным лидером. Вы всегда уверены в своих решениях и умеете внушать доверие своей команде. Ваше естественное лидерство, смелость и умение взять на себя ответственность в трудных ситуациях являются вашими ключевыми качествами.",
            "Управленец":
                "Ваши организаторские навыки, умение принимать решения и смелость в действиях делают вас идеальным управленцем. Вы обладаете талантом к управлению ресурсами, людьми и процессами, ставя и достигая сложные цели. Ваша способность оставаться спокойным в сложных ситуациях и делать решительные шаги помогает вам достичь успеха в управленческой сфере.",
            "Легионер":
                "Ваша непреклонность, умение работать в команде и физическая выносливость делают вас идеальным легионером. Ваше стремление к действию, умение справляться с трудностями и воля к победе помогают вам достичь своих целей. Ваше желание всегда быть в первых рядах и ваша способность стоять на своем даже в самых сложных обстоятельствах выделяют вас среди остальных.",
            "Инспектор":
                "Ваши навыки точного и детального анализа, любовь к порядку и систематизации, а также стойкость и предсказуемость делают вас идеальным кандидатом для ролей, требующих тщательного контроля и осмотра. Ваша способность критически оценивать ситуацию и обеспечивать соблюдение стандартов и процедур приводит к высокому качеству работы.",
            "Контроллер":
                "Ваша способность сохранять спокойствие в условиях стресса, внимание к деталям и стремление к перфекционизму позволяют вам успешно контролировать и управлять сложными процессами. Вы умеете следить за соблюдением правил и стандартов, что способствует эффективности и безопасности ваших проектов.",
            "Стабилизатор":
                "Ваша устойчивость к переменам, надежность и преданность целям обеспечивают стабильность и последовательность в любой команде или проекте. Ваша способность устанавливать и поддерживать порядок, а также ваше стремление к стабильности и предсказуемости, делают вас неоценимым участником команды в любой сфере.",
            "Инженер":
                "Ваши навыки системного и аналитического мышления, творческий подход к решению проблем и любовь к деталям делают вас идеальным кандидатом для инженерных и технических ролей. Ваши способности к точной работе с деталями, умение понимать и улучшать сложные системы и ваша способность адаптироваться к новым технологиям гарантируют ваш успех в технической сфере.",
            "Управляющий":
                "Ваши навыки организации, планирования и управления, а также ваша способность к эффективной коммуникации и делегированию делают вас идеальным управляющим. Вы умеете координировать команды, управлять ресурсами и успешно достигать поставленных целей, даже в условиях неопределенности.",
            "Практик":
                "Ваш практический подход к работе, умение видеть и использовать реальные возможности, а также ваша склонность к конкретным и измеримым результатам говорят о том, что вы - настоящий практик. Ваша способность воплощать идеи в жизнь, делать практические шаги и действовать реалистично являются вашими ключевыми активами.",
            "Реализатор":
                "Ваша способность к эффективной реализации планов, ваша преданность целям и ваш практический подход к работе делают вас идеальным реализатором. Вы обладаете уникальным навыком воплощения идей в жизнь и достижения конкретных результатов, что важно в любой организации.",
            "Администратор":
                "Ваши организаторские навыки, внимание к деталям и умение координировать многозадачность делают вас отличным администратором. Ваша способность управлять ресурсами, устанавливать и поддерживать порядок, а также ваша способность обеспечивать эффективность процессов являются основой вашего профессионального успеха.",
            "Серый кардинал":
                "Ваша способность к стратегическому мышлению, склонность к анализу и умение просчитывать все возможные сценарии делают вас идеальным кандидатом для роли \"серого кардинала\". Вы умеете работать за кулисами, оказывая влияние на процесс принятия решений и оставаясь при этом незамеченным.",
            "Прогнозист":
                "Ваше внимание к деталям, аналитическое мышление и способность прогнозировать тенденции позволяют вам быть отличным прогнозистом. Вы умеете видеть большую картину, анализировать информацию и предвидеть будущие события или тенденции, что является основой для успешного стратегического планирования.",
            "Скептик":
                "Ваша способность к критическому мышлению, анализу и скептицизму делает вас хорошим скептиком. Вы всегда готовы поставить под сомнение устоявшиеся взгляды и идеи, анализируя их с разных сторон и выявляя их слабые места.",
            "Критик":
                "Ваша тонкая способность критически оценивать и анализировать информацию, а также ваше стремление к качеству и точности делают вас идеальным критиком. Вы умеете замечать недостатки и предлагать улучшения, что помогает в постоянном стремлении к совершенству и развитию.",
            "Новатор интузиаст":
                "Ваша неутомимая энергия, стремление к новому и непреходящий энтузиазм к открытиям выделяют вас в роли новатора-энтузиаста. Вы всегда ищете новые идеи, открыты для экспериментов и готовы к риску, чтобы внедрить что-то новое и прорывное. Это придаёт вам способность преодолевать преграды, пересматривать привычные рамки и выходить за границы общепринятого. Вы привносите живость и новаторство в любую команду, в которой участвуете, и ваши идеи часто становятся источником нововведений.",
            "Исследователь":
                "Ваша любознательность, интерес к постоянному самосовершенствованию и способность глубоко анализировать ситуации делают вас настоящим исследователем. Ваше стремление к знаниям и умение задавать правильные вопросы приводят к более глубокому пониманию ситуаций и проблем. Вы умеете находить взаимосвязи там, где другие их не видят, и это дает вам способность разрабатывать новые стратегии и подходы. Ваши навыки исследования и ваше стремление к пониманию суть вещей являются основой для развития и инноваций.",
            "Новатор":
                "Ваша непрекращающаяся потребность в новизне, склонность к нестандартному мышлению и готовность бросить вызов статус-кво делают вас настоящим новатором. Вы не просто принимаете вещи такими, какие они есть, но всегда ищете новые подходы и лучшие решения. Вы умеете видеть возможности, где другие видят преграды, и ваша способность к инновациям помогает привести вашу команду или организацию к успеху.",
            "Генератор":
                "Ваше творчество, способность к быстрой генерации идей и стремление к постоянному обновлению и прогрессу делают вас отличным генератором. Ваши идеи часто становятся каталитическими для изменений, и ваше стремление к творчеству помогает поддерживать инновационную культуру в команде. Вы обладаете уникальной способностью замечать возможности, игнорируемые другими, и эта ваша способность генерировать идеи может быть катализатором для роста и развития любой организации или проекта.",
            "Аналитик":
                "Ваш талант анализировать и раскладывать все на составные части делает вас незаменимым аналитиком. Вы всегда видите, как каждый элемент влияет на общую картину, и ваше умение объективно оценивать информацию и данные позволяет вам предлагать глубокие и обоснованные выводы. Ваши аналитические навыки помогают организациям принимать основанные на данных решения и разрабатывать эффективные стратегии.",
            "Систематизатор":
                "Ваша склонность к порядку, структурированию и систематизации делает вас превосходным систематизатором. Ваше умение приводить информацию в порядок, устанавливать связи и создавать четкие и логичные системы дает вам возможность преобразовывать хаос в структуру. Это помогает организациям улучшить их процессы, увеличить эффективность и повысить производительность.",
            "Перфекционист":
                "Ваше стремление к безупречности, тщательность и концентрация на деталях делают вас идеальным перфекционистом. Вы не останавливаетесь, пока работа не будет выполнена как можно лучше. Ваши высокие стандарты и непреклонное стремление к качеству влияют на все ваши проекты, что приносит ощутимые результаты в виде высококачественной работы.",
            "Рационализатор":
                "Ваше умение анализировать и оптимизировать делает вас отличным рационализатором. Вы всегда находите способы сделать вещи более эффективными и эффективными. Ваше стремление к оптимизации и ваш навык превращения сложного в простое помогают организациям повышать эффективность и улучшать качество их услуг или продуктов.",
            "Предприниматель новатор":
                "Ваша способность видеть большую картину, найти возможности там, где их не видят другие, и ваша открытость к новым идеям определяют вас как предпринимателя-новатора. Вы не боитесь рисковать, ваши амбиции и энергия помогают вам переводить свои идеи в реальность. Ваше предпринимательское мышление может быть катализатором роста и инноваций в любой организации или проекте.",
            "Алгоритмист":
                "Ваше умение обрабатывать информацию, создавать алгоритмы и систематизировать знания делают вас уникальным алгоритмистом. Вы привносите логику и структуру в любой процесс, позволяя организациям работать более эффективно и предсказуемо. Ваше мышление, ориентированное на алгоритмы, приводит к повышению производительности и качества работы.",
            "Интегратор":
                "Ваша способность связывать людей, идеи и ресурсы определяет вас как интегратора. Вы способны видеть, как различные элементы взаимодействуют и влияют друг на друга, что позволяет вам создавать более сильные и эффективные команды. Ваше стремление к интеграции помогает организациям создавать синергию и улучшать свою работу.",
            "Экспериментатор":
                "Ваша склонность к тестированию гипотез, проверке предположений и непрерывному улучшению делает вас умелым экспериментатором. Вы не боитесь ошибок и считаете их возможностью для обучения. Ваше умение проводить эксперименты и ваше терпение к неопределенности могут помочь организациям развиваться и адаптироваться в условиях постоянных изменений.",
        },
    },
    "WelcomeSubscribe": {
        "title":
            "Я буду продвигать тебя, помогу добиться успеха и заработка в выбранных направлениях",
        "subtitle":
            "Перед тем как перейти к своему профилю - прямо сейчас для тебя есть выгодное предложение<br/><br/> Что в него входит:",
        "pay": "Получить доступ ко всему за {price} руб/мес",
        "to-profile": "Перейти в профиль",
        "list": [
            {
                "id": 1,
                "icon": "people-recommendations",
                "text": "Каждый день Вас будут рекомендовать 5-и пользователям с сайта, с которыми у вас отличная деловая совместимость.",
            },
            {
                "id": 2,
                "icon": "text-recommendations",
                "text": "Получите глубокий анализ своего психотипа и его расшифровки",
            },
            {
                "id": 3,
                "icon": "brain",
                "text": "Получите описание Вашего характера и особенностей поведения.",
            },
            {
                "id": 4,
                "icon": "environment",
                "text": "Больше 10 рекомендаций для твоего психотипа по развитию в этом деле",
            },
            {
                "id": 5,
                "icon": "stars",
                "text": "У Вас будет доступ к базе знаменитостей и Вы можешь получить возможность встретится с ними лично или начать сотрудничество или узнать про их характер.",
            },
            {
                "id": 6,
                "icon": "photo",
                "text": "Вы сможете проверять любого человека на сайте и вне сайта на деловую совместимость.",
            },
        ],
    },
    "AILanding": {
        "header": {
            "titles": {
                "chatGPT":
                    "<b>Нейросеть</b> — это чат, пишущий ответы на вопросы онлайн",
                "faceSwap":
                    "<b>FaceSwap</b> — нейросеть для замены лица на фото онлайн",
                "midjourney":
                    "<b>Midjourney</b> — нейросеть для генерации картинок онлайн",
                "stableDiffusion":
                    "<b>Stable Diffusion</b> — нейросеть для генерации картинок онлайн",
                "pika": "<b>Pika</b> — нейросеть для создания видео онлайн",
            },
            "enterBtn": "Войти",
        },
        "footer": {
            "titles": {
                "chatGPT":
                    "<b>Нейросеть</b> — это чат, пишущий ответы на вопросы онлайн",
                "faceSwap":
                    "<b>FaceSwap</b> — нейросеть для замены лица на фото онлайн",
                "midjourney":
                    "<b>Midjourney</b> — нейросеть для генерации картинок онлайн",
                "stableDiffusion":
                    "<b>Stable Diffusion</b> — нейросеть для генерации картинок онлайн",
                "pika": "<b>Pika</b> — нейросеть для создания видео онлайн",
            },
            "copyright": "© «Merlinface.com» Все права защищены",
        },
        "hero": {
            "titles": {
                "chatGPT": "Задайте любой вопрос нейросети в чате на русском",
                "faceSwap": "Заменяйте лицо нейросетью на любом фото онлайн",
                "midjourney": "Генерация изображений по тексту за 30 секунд",
                "stableDiffusion":
                    "Создайте потрясающее изображение по описанию за секунды",
                "pika": "Генерация видео нейросетью по тексту или фотографии",
            },
            "descriptions": {
                "chatGPT":
                    "ChatGPT пишет моментальный ответ текстом онлайн, бесплатно и без VPN",
                "faceSwap":
                    "Автоматическая замена лиц на картинке онлайн и бесплатно",
                "midjourney":
                    "Создайте фотографию с помощью нейросети Midjourney без фотографа и судии, даже если никогда этого не делали",
                "stableDiffusion":
                    "Создайте фотографию с помощью нейросети Stable Diffusion без фотографа и судии, даже если никогда этого не делали",
                "pika": "Создание видеороликов для людей, которые хотят создавать их из головы по тексту",
            },
            "btns": {
                "chatGPT": "Задать вопрос",
                "faceSwap": "Сгенерировать фотографию",
                "midjourney": "Сгенерировать фотографию",
                "stableDiffusion": "Сгенерировать фотографию",
                "pika": "Попробовать Pika",
            },
        },
        "aiExamples": {
            "titles": {
                "main": "Последние созданные картинки и фотографии:",
                "midjourney":
                    "Изображения, сгенерированные нейросетью Midjourney",
                "stableDiffusion":
                    "Изображения, сгенерированные нейросетью Stable Diffusion",
            },
        },
        "simple": {
            "titles": {
                "chatGPT": "Генерировать нейросетью ответы очень просто",
                "midjourney":
                    "Генерировать изображения нейросетью Midjourney очень просто",
                "stableDiffusion":
                    "Генерировать изображения нейросетью Stable Diffusion очень просто",
            },
            "descriptions": {
                "chatGPT":
                    "Эта нейросеть онлайн предназначена для генерации текста и ответов на вопросы с помощью глубокого обучения. Искусственный интеллект хорошо разбирается в любой теме, так как обучен на миллионах текстов всех языков мира.",
                "midjourney":
                    "Достаточно ввести описание картинки, нажать кнопку «Отправить» и через несколько секунд вы получите уникальное изображение",
                "stableDiffusion":
                    "Достаточно ввести описание картинки, нажать кнопку «Отправить» и через несколько секунд вы получите уникальное изображение",
            },
            "btns": {
                "chatGPT": "Задать вопрос",
                "midjourney": "Сгенерировать картинку",
                "stableDiffusion": "Сгенерировать картинку",
            },
        },
        "advantages": {
            "titles": {
                "main": "Что умеет <b>MerlinGPT</b>?",
                "chatGPT":
                    "<b>MerlinGPT</b> — это нейросеть<br/>ChatGPT 3.5 и 4 прямо в чате",
                "faceSwap":
                    "Обменяйтесь лицами онлайн<br/>просто, быстро и весело",
            },
            "btns": {
                "chatGPT": "Задать вопрос",
                "faceSwap": "none",
                "main": "none",
            },
            "list": {
                "main": [
                    {
                        "icon": "🖋️",
                        "title": "Тексты",
                        "description":
                            "Пишите тексты для сайта, блога или социальных сетей",
                    },
                    {
                        "icon": "📋️",
                        "title": "HR",
                        "description":
                            "Составляйте описание вакансий и должностных инструкций",
                    },
                    {
                        "icon": "📝",
                        "title": "Белый лист",
                        "description":
                            "Решайте проблему “белого листа”, генерируя множество вариантов",
                    },
                    {
                        "icon": "🛍️",
                        "title": "Товары",
                        "description":
                            "Составляйте описание товаров для маркетплейсов",
                    },
                    {
                        "icon": "🪧",
                        "title": "Реклама",
                        "description":
                            "Придумывайте заголовки и тексты объявлений для Таргета и контекстной рекламы",
                    },
                    {
                        "icon": "🤖",
                        "title": "Скрипты",
                        "description":
                            "Создавайте скрипты для автоматических ответов бота в социальных сетях и Телеграме",
                    },
                    {
                        "icon": "🅰",
                        "title": "Перевод",
                        "description":
                            "Переводите тексты на разные языки, а также исправляйте ошибки в правописании",
                    },
                    {
                        "icon": "📑",
                        "title": "Выводы",
                        "description":
                            "Делайте изложения и выводы по книгам, статьям, составляйте планы обучения",
                    },
                    {
                        "icon": "📚",
                        "title": "Учеба",
                        "description":
                            "Облегчайте себе написание курсовых, рефератов, решение математических задач",
                    },
                    {
                        "icon": "✍️",
                        "title": "Рерайтинг",
                        "description":
                            "Переписывайте тексты в художественном, научном или любом другом стиле",
                    },
                    {
                        "icon": "👨‍💻",
                        "title": "Код",
                        "description":
                            "Пишите программный код или оптимизируйте существующий",
                    },
                    {
                        "icon": "🖥️",
                        "title": "Промты",
                        "description":
                            "Пишите промты для других генеративных нейросетей",
                    },
                ],
                "chatGPT": [
                    {
                        "icon": "🎨",
                        "title": "Создавайте<br/>контент",
                        "description":
                            "Создавайте уникальные изображения и текст для любых социальных сетей",
                    },
                    {
                        "icon": "⏱️",
                        "title": "Экономьте<br/>время",
                        "description":
                            "Экономьте ваше время для решения рутинных задач с помощью готовых шаблонов",
                    },
                    {
                        "icon": "📚",
                        "title": "Используйте<br/>в учебе",
                        "description":
                            "Ищите любую инфу, решайте тесты, создавайте рефераты в один клик",
                    },
                    {
                        "icon": "🖥️",
                        "title": "Создавайте<br/>код",
                        "description":
                            "Редактируйте и создавайте любой код, учитесь новому и исправляйте ошибки",
                    },
                    {
                        "icon": "📃",
                        "title": "Улучшайте<br/>текст",
                        "description":
                            "Редактируйте любой текст, увеличивайте оригинальность, создавайте лучший текст под любые задачи",
                    },
                ],
                "faceSwap": [
                    {
                        "icon": "✌️",
                        "title": "Прост<br/>в использовании",
                        "description":
                            "Смена лица на Merlinface – проще простого! Загрузите хорошее фото вашего лица и выберите шаблон. Затем дайте ИИ сделать свое дело — никакого сложного редактирования! Это очень просто и подходит для всех.",
                    },
                    {
                        "icon": "🔒",
                        "title": "Полная<br/>приватность",
                        "description":
                            "Ваша конфиденциальность – наш приоритет. Ваши загруженные изображения и другие данные защищены, и только вы можете их видеть.",
                    },
                    {
                        "icon": "🗲",
                        "title": "Стилизированные<br/>заготовки",
                        "description":
                            "Вы новичок? Не переживайте! Просто выберите следуйте нашим инструкциям. Вы можете стать кем угодно всего за несколько секунд!",
                    },
                    {
                        "icon": "🖼️",
                        "title": "Бесплатные<br/>использования",
                        "description":
                            "Мы начисляем по пять бесплатных кредитов ежедневно, чтобы вы могли использовать онлайн замену лиц. Кредиты отправляются на ваш аккаунт, чтобы вы могли наслаждаться процессом по максимуму.",
                    },
                    {
                        "icon": "🔨",
                        "title": "Дополнительные<br/>функции",
                        "description":
                            "Вы можете воспользоваться другими инструментами для редактирования фото на Merlinface. Например, вы можете вставить лицо друга, а наложить на лицо любой знаменитости.",
                    },
                    {
                        "icon": "😀",
                        "title": "Заменяйте лица<br/>в GIF",
                        "description":
                            "Наш инструмент для замены лиц в GIF-изображениях позволяет вам заменять лица в ваших любимых GIF-ах. Загрузите ваше фото, выберите GIF, в который хотите вставить лицо, наш ИИ сделает все остальное.",
                    },
                ],
            },
        },
        "question": {
            "titles": {
                "chatGPT": "Как задать вопрос чат-боту MerlinGPT онлайн?",
            },
            "descriptions": {
                "chatGPT":
                    "Это могут быть как быстрые ответы на вопросы так и более сложные запросы, которые требуют глубокого понимания и анализа большого количества информации. Чат-бот применяется в различных областях, от образования и нутрициологии до банковского дела и продаж.",
            },
        },
        "howWork": {
            "titles": {
                "chatGPT": "Как работает <b>MerlinGPT</b>?",
            },
            "separator": {
                "chatGPT":
                    "Мы подключаемся к платной версии Chat GPT от OpenAI, адаптируем их для России и делаем открытыми.",
            },
            "analog": {
                "chatGPT": [
                    "Платная версия от $20 с зарубежной карты",
                    "В пиковую загруженность не работает",
                    "Блокирует российских пользователей",
                    "Интерфейс на английском",
                ],
            },
            "merlin": {
                "chatGPT": [
                    "Поддерживает русский язык и интерфейс",
                    "Быстрые ответы без очереди и остановок на час",
                    "Доступ без VPN",
                    "Не нужен номер телефона и иностранная карта",
                ],
            },
            "btns": {
                "chatGPT": "Попробовать Merlin GPT",
            },
        },
        "faq": {
            "titles": {
                "chatGPT": "Часто задаваемые вопросы:",
                "stableDiffusion": "Часто задаваемые вопросы:",
                "main": "Часто задаваемые вопросы:",
            },
            "list": {
                main: [
                    {
                        title: "Можно ли полностью доверять ответам MerlinGPT?",
                        answer: "MerlinGPT — это мощный инструмент, который предоставляет информацию и помогает с решением различных задач. Однако, как и любое другое средство, он не идеален. Мы рекомендуем использовать ответы MerlinGPT как вспомогательные, а не окончательные. Всегда полезно проверять информацию из дополнительных источников, особенно если речь идет о важных решениях или специализированных темах. Ваше критическое мышление и собственные исследования помогут вам сделать наилучший выбор!",
                        isOpen: false,
                    },
                    {
                        title: "У вас есть GPT-4?",
                        answer: "На тарифе FREE используется модель gpt-4o-mini. На тарифах GOLD и PLATINUM используется самая мощная и современная модель: gpt-4o",
                        isOpen: false,
                    },
                    {
                        title: "Ваш чат умеет рисовать изображения?",
                        answer: "Для того чтобы рисовать изображения мы используем сразу несколько нейросетей: Midjourney, Stable Diffusion и DALLE-3. Вы можете выбрать любую на Ваш вкус.",
                        isOpen: false,
                    },
                    {
                        title: "Можно ли отменить подписку?",
                        answer: "Да, подписку можно отменить в любой момент, в Вашем личном кабинете",
                        isOpen: false,
                    },
                    {
                        title: "Это настоящий ChatGPT?",
                        answer: "Да - это настоящий ChatGPT, разработанный в компании OpenAI.",
                        isOpen: false,
                    },
                    {
                        title: "Как правильно задать вопрос в чат, чтобы ответ был максимально точным?",
                        answer: "Формулируйте вопрос так, чтобы в нем содержалась вся необходимая информация. Например, вместо \"Расскажи о здоровье\" лучше спросить \"Какие основные факторы влияют на здоровье сердечно-сосудистой системы?\"<br/><br/>Предоставьте дополнительную информацию, которая может помочь в ответе. Например, если вас интересует тема инвестиций, укажите, о каком виде инвестиций идет речь (акции, облигации, недвижимость и т. д.).<br/><br/>Старайтесь избегать сложных терминов или жаргона, если они не необходимы. Чем проще вопрос, тем легче на него ответить.",
                        isOpen: false,
                    },
                ],
                "chatGPT": [
                    {
                        "title": "Что такое чат-бот GPT 3.5 онлайн?",
                        "answer": "Ответ на вопрос",
                        "isOpen": false,
                    },
                    {
                        "title": "Какие проблемы решает чат нейросеть?",
                        "answer": "Ответ на вопрос",
                        "isOpen": false,
                    },
                    {
                        "title":
                            "Как пользоваться искусственным интеллектом на русском?",
                        "answer": "Ответ на вопрос",
                        "isOpen": false,
                    },
                    {
                        "title": "Как быстро нейросеть чат пишет текст?",
                        "answer": "Ответ на вопрос",
                        "isOpen": false,
                    },
                ],
                "stableDiffusion": [
                    {
                        "title": "Что такое нейросеть Stable Diffusion?",
                        "answer": "Ответ на вопрос",
                        "isOpen": false,
                    },
                    {
                        "title": "Это официальный сайт Stable Diffusion?",
                        "answer": "Ответ на вопрос",
                        "isOpen": false,
                    },
                    {
                        "title":
                            "Подходит ли Stable Diffusion для работы на русском языке?",
                        "answer": "Ответ на вопрос",
                        "isOpen": false,
                    },
                    {
                        "title":
                            "Как зарегистрироваться в Stable Diffusion самостоятельно?",
                        "answer": "Ответ на вопрос",
                        "isOpen": false,
                    },
                    {
                        "title": "Как купить Stable Diffusion?",
                        "answer": "Ответ на вопрос",
                        "isOpen": false,
                    },
                ],
            },
        },
        "steps": {
            "titles": {
                "stableDiffusion":
                    "Как сгенерировать изображение нейросетью Stable Diffusion онлайн?",
                "midjourney":
                    "Как сгенерировать изображение нейросетью Midjourney онлайн?",
            },
            "list": {
                "stableDiffusion": [
                    {
                        "title": "Авторизуйтесь на Merlinface.com",
                        "description": "none",
                    },
                    {
                        "title":
                            "В меню слева перейдите<br/>в раздел «Сообщения»",
                        "description":
                            "И в списке нейросетей найдите и кликните на Stable Diffusion.",
                    },
                    {
                        "title": "Пишите ваши запросы",
                        "description":
                            "Как только вы отправите сообщение в чат, сразу же начнется генерация картинки, обычно занимает несколько секунд.",
                    },
                    {
                        "title": "Получите изображение",
                        "description":
                            "Как только изображение будет сгенерировано, вы можете скачать его или написать другой запрос.",
                    },
                ],
                "midjourney": [
                    {
                        "title": "Авторизуйтесь на Merlinface.com",
                        "description": "none",
                    },
                    {
                        "title":
                            "В меню слева перейдите<br/>в раздел «Сообщения»",
                        "description":
                            "И в списке нейросетей найдите и кликните на Midjourney",
                    },
                    {
                        "title": "Пишите ваши запросы",
                        "description":
                            "Как только вы отправите сообщение в чат, сразу же начнется генерация картинки, обычно занимает несколько секунд.",
                    },
                    {
                        "title": "Получите изображение",
                        "description":
                            "Как только изображение будет сгенерировано, вы можете скачать его или написать другой запрос.",
                    },
                ],
            },
            "btns": {
                "stableDiffusion": "Попробовать Stable Diffusion",
                "midjourney": "Попробовать Midjourney",
            },
        },
    },
};
