<template lang="pug">
    .add-message-form
        ._buy(v-if="isChatWithMerlinAI" :class="{hidden: isBuyHidden && !isDesktopView}")
            transition(name="fade")
                course-banner(v-if="!courseBannerHidden && isCoursePage")._course-banner
            ._credit-cout {{ prefixText }} {{ creditsForNeural }} {{ getCreditWord(creditsForNeural) }}
            ui-button(type="bordered" size="small" @click.native="$modal.show('AISubscriptions')" v-if="!myUser.is_corporate_user")._buy-btn
                template(v-slot:content) Купить запросы
                    span._sale(v-if="!myUser.is_discount_active && myUser.discount_ends_at === null || myUser.is_discount_active") -50%
            ui-button(type="bordered" size="small" text="Ввести промокод" color="gray" @click.native="$modal.show('Promocode')" v-if="!myUser.is_corporate_user")._promocode-btn
            ._request-cout {{ myUser.is_corporate_user ? 'Осталось корпоративных кредитов: ' + myUser.chatMessageAttempts : 'Осталось кредитов: ' + myUser.chatMessageAttempts }}
        face-swap-changer(v-if="+chatFaceSwapThemeId === +themeId" :is-face-swap-loading="blockMerlinChat" :target-photo="uploadedImage" :target-loading="isImageLoading" @submit-form="sendMessage" @clear-photo="uploadedImage = null" @upload-photo="handleLabelClick('chat-file')")
        ._form(v-show="+chatFaceSwapThemeId !== +themeId")
            merlin-limit(v-if="myUser.chatMessageAttempts <= 0 && isChatWithMerlinAI")._merlin-limit
            input(type="file" id="chat-file" accept="image/*" @change="uploadPhoto")._file-input
            transition(name="fade")
                ._choose-upload-type(v-if="+chatMidjourneyThemeId === +themeId" @click="uploadTypeDropdownActive = true")
                    ._is-image-loading(v-if="isImageLoading")
                    ._uploaded-image-box(v-else-if="uploadedImage")
                        img(:src="uploadedImage")._uploaded-image
                        ._delete-image-btn(@click.stop.prevent="deleteImage")
                            svg-icon(icon-name="delete")._delete-image-icon
                    svg-icon(v-else icon-name="picture" added-class="chat__file-icon")
                    ._choose-upload-type-dropdown(v-if="uploadTypeDropdownActive" v-click-outside="() => uploadTypeDropdownActive = false")
                        label(for="chat-file" @click="fileType = 'describe'")._choose-upload-item Получить описание картинки (/describe)
                        label(for="chat-file" @click="fileType = 'upload'")._choose-upload-item Сгенерировать новую картинку на основе моей
            ._runway-seconds(v-if="activeThemeName === 'Runway' && !IS_NEURAL_FREE")
                ._runway-btn(@click="runwayDropdown = true")
                    svg-icon(icon-name="duration")._runway-seconds-icon
                    ._runway-seconds-text {{ runwayParam }}
                ._runway-dropdown(v-if="runwayDropdown" v-click-outside="() => runwayDropdown = false")
                    ._runway-dropdown-title Продолжительность видео
                    ._runway-dropdown-item(@click="runwayParam = '5 сек.'") 5 сек.
                    ._runway-dropdown-item(@click="runwayParam = '10 сек.'") 10 сек.
            ._runway-seconds(v-if="activeThemeName === 'Runway' && IS_NEURAL_FREE")
                ._runway-btn(@click="runwayDropdown = true")
                    span._runway-dropdown-aspect-preview.-small(:data-ratio="runwayParam") {{ runwayParam }}
                ._runway-dropdown(v-if="runwayDropdown" v-click-outside="() => runwayDropdown = false")
                    ._runway-dropdown-title Соотношение сторон
                    ._runway-dropdown-item(v-for="(params, key, index) in runwayAspectRatio" :key="index" @click="runwayParam = key")
                        ._runway-dropdown-aspect-preview-box
                            span._runway-dropdown-aspect-preview(:data-ratio="key") {{ key }}
                        span._runway-dropdown-aspect-resolution {{ params.width }}x{{ params.height }}
            label(for="chat-file" v-if="!isChatWithMerlinAI || +chatPikaThemeId === +themeId || +chatFaceSwapThemeId === +themeId || +chatFaceSwapThemeId === +themeId || +chatStableDiffusionVideoThemeId === +themeId || isGPTChat" :class="{disabled: blockMerlinChat}")._file
                ._is-image-loading(v-if="isImageLoading")
                ._uploaded-image-box(v-else-if="uploadedImage")
                    img(:src="uploadedImage")._uploaded-image
                    ._delete-image-btn(@click.stop.prevent="deleteImage")
                        svg-icon(icon-name="delete")._delete-image-icon
                svg-icon(v-else icon-name="picture" added-class="chat__file-icon")
            ._textarea-group
                textarea(
                    :placeholder="locale('inputPlaceholder')"
                    v-model="message"
                    v-model.trim="$v.message.$model"
                    @keyup="resizeTextarea"
                    @blur="textareaBlurHandler"
                    @keydown="sendMessageHandler"
                    ref="textarea"
                    :rows="textareaRows"
                    :disabled="blockMerlinChat || +chatFaceSwapThemeId === +themeId || +chatStableDiffusionVideoThemeId === +themeId"
                )._textarea
                button._emoji-btn(@click="showEmojiModal = !showEmojiModal")
                    svg-icon(icon-name="emoji")._emoji-icon
            transition(name="fadeIn")
                VEmojiPicker._emoji-popup(v-if="showEmojiModal" @select="selectEmoji" v-click-outside="() => showEmojiModal = false" :i18n="emojiI18n")
            button._send-btn(@click="sendMessage" :disabled="blockMerlinChat")
                svg-icon(icon-name="send" v-if="!blockMerlinChat")._send-icon
                ._send-loading(v-else)
</template>

<script>
import MerlinLimit from "../../merlin-limit/merlin-limit.vue";
import { maxLength, required } from "vuelidate/lib/validators";
import { VEmojiPicker } from "v-emoji-picker";
import gql from "graphql-tag";
import getAmazonPhotoUploadUrlQuery from "@/graphql/queries/getAmazonPhotoUploadUrl.query.graphql";
import sendMessageMutation from "@/graphql/mutations/sendMessage.mutation.graphql";
import UiButton from "../../../ui/ui-button/ui-button.vue";
import FaceSwapChanger from "@/components/chat/messages/add-message-form/face-swap-changer/face-swap-changer.vue";
import CourseBanner from "@/components/chat/messages/course-banner/course-banner.vue";

export default {
    name: "AddMessageForm",
    components: {
        CourseBanner,
        FaceSwapChanger,
        UiButton,
        MerlinLimit,
        VEmojiPicker,
    },
    props: {
        describePrompt: {
            type: String,
            required: false,
            default: "",
        },
        conversationId: {
            type: Number,
            required: true,
            default: 1,
        },
        isBuyHidden: {
            type: Boolean,
            required: true,
            default: false,
        },
    },
    data() {
        return {
            message: null,
            preMessageGpt: "Сыграй роль опытного копирайтера. Напиши статью для телеграм на 1500 знаков на тему \"как заработать на трендах с нейросетями\" для маркетологов и смм-специалистов по формуле AIDA(не используй подзаголовки из этой формулы). Статья должна содержать эмоджи. Обеспечь статью актуальными данными, приведи цитаты экспертов, и подкрепи информацию примерами из реального мира. Сделай логический вывод, вытекающий из текста о том, что нейросети помогут заработать больше и сэкономить время.",
            preMessageMidjorney: "Портрет крупным планом 30-летней женщины, одетой в элегантное светлое платье, уверенно держащей в руках планшет apple pro c текстовыми черновиками, символизирующими ее мастерство в использовании нейронных сетей для копирайтинга. Она стоит у безмятежного берега озера Гарда, на заднем плане - живописный пейзаж Италии. Ее глаза прямо смотрят в камеру, демонстрируя ее профессиональную харизму и вновь обретенный успех. Естественное освещение подчеркивает ее сосредоточенность и творческий потенциал, запечатленные в золотой час. Это высокодетализированная фотография высокого разрешения, сделанная фотоаппаратом Canon R5. --ar 32:31",
            isImageLoading: false,
            uploadedImage: null,
            showEmojiModal: false,
            emojiI18n: {
                search: "Поиск ...",
                categories: {
                    Activity: "Активность",
                    Flags: "Флаги",
                    Foods: "Еда",
                    Frequently: "Недавние",
                    Objects: "Объекты",
                    Nature: "Природа",
                    Peoples: "Люди",
                    Symbols: "Символы",
                    Places: "Места",
                },
            },
            uploadTypeDropdownActive: false,
            fileType: "",
            runwayDropdown: false,
            runwayParam: "",
            runwayAspectRatio: {
                "16:9": {
                    width: 1366,
                    height: 768,
                },
                "9:16": {
                    width: 768,
                    height: 1366,
                },
                "1:1": {
                    width: 1024,
                    height: 1024,
                },
                "4:3": {
                    width: 1280,
                    height: 960,
                },
                "3:4": {
                    width: 960,
                    height: 1280,
                },
                "21:9": {
                    width: 1344,
                    height: 576,
                },
            },
            textareaRows: 1,
            selectedGptModel: localStorage.getItem("selectedGptModel") ? localStorage.getItem("selectedGptModel") : null,
        };
    },
    computed: {
        isCoursePage: function () {
            return (
                ["Face Swap", "Pika", "Stable Diffusion"].indexOf(
                    this.activeThemeName,
                ) === -1
            );
        },
        activeThemeName: (state) => state.$store.getters["activeThemeName"],
        courseBannerHidden: (state) =>
            state.$store.getters["courseBannerHidden"],
        chatThemes: (state) => state.$store.getters["chatThemes"],
        chatId: (state) => state.$route.params.chatId,
        themeId: (state) => state.$route.params.theme_id,
        chatPikaThemeId: (state) => state.$store.getters["chatPikaThemeId"],
        chatFaceSwapThemeId: (state) =>
            state.$store.getters["chatFaceSwapThemeId"],
        chatStableDiffusionVideoThemeId: (state) =>
            state.$store.getters["chatStableDiffusionVideoThemeId"],
        chatMidjourneyThemeId: (state) =>
            state.$store.getters["chatMidjourneyThemeId"],
        isChatWithMerlinAI: (state) =>
            state.MERLIN_CONVERSATION_ID === state.chatId,
        subscriptionCategories: (state) =>
            state.$store.getters["user/subscriptionCategories"],
        blockMerlinChat: (state) => state.$store.getters["merlinChatBlocked"],
        faceSwapPhoto: (state) => state.$store.getters["faceSwapPhoto"],
        isGPTChat: function () {
            return (
                this.activeThemeName !== "Midjorney" &&
                this.activeThemeName !== "Stable Diffusion" &&
                this.activeThemeName !== "Stable Diffusion Video" &&
                this.activeThemeName !== "Face Swap" &&
                this.activeThemeName !== "DALLE-3" &&
                this.activeThemeName !== "Pika" &&
                this.activeThemeName !== "Runway" &&
                this.activeThemeName !== "Yandex GPT"
            );
        },
        creditsForNeural: function() {
            switch (this.activeThemeName) {
                case "Midjorney":
                    return 2;
                case "Stable Diffusion":
                case "Stable Diffusion Video":
                case "Face Swap":
                case "DALLE-3":
                case "Pika":
                case "Yandex GPT":
                    return 1;
                case "Runway":
                    if (this.runwayParam === "5 сек." && !this.IS_NEURAL_FREE) {
                        return 40;
                    } else if (this.runwayParam !== "5 сек." && !this.IS_NEURAL_FREE) {
                        return 80;
                    } else  {
                        return 15;
                    }
                case "ChatGPT":
                    if (this.selectedGptModel === "o1-preview") {
                        return 4; // ChatGPT o1-preview
                    } else {
                        return 1; // Default for other ChatGPT models
                    }
                default:
                    return this.selectedGptModel === "o1-preview" ? 4 : 1; // Default for unknown themes
            }
        },

        prefixText() {
            if (this.isGPTChat) {
                return "4000 символов -";
            } else {
                return "Генерация -";
            }
        }

    },
    watch: {
        runwayParam() {
            this.runwayDropdown = false;
        },
        describePrompt(text) {
            this.message = text;
            this.sendMessage();
        },
    },
    validations: {
        message: {
            required,
            maxLength: maxLength(6192),
        },
    },
    mounted() {
        if (!this.IS_NEURAL_FREE) {
            this.runwayParam = "5 сек.";
        } else {
            this.runwayParam = "16:9";
        }

        window.addEventListener("selectedGptModel-changed", (event) => {
            this.selectedGptModel = event.detail.storage;
        });
    },

    methods: {
        handleLabelClick(label) {
            document.getElementById(label).click();
        },
        deleteImage() {
            if (this.message === "/describe") {
                this.message = "";
            }
            this.uploadedImage = null;
        },
        async sendMessageHandler(event) {
            let keyCode;

            if (this.isMobileView) return;

            if (event.key !== undefined) {
                keyCode = event.key;
            } else if (event.keyIdentifier !== undefined) {
                keyCode = event.keyIdentifier;
            } else if (event.keyCode !== undefined) {
                keyCode = event.keyCode;
            }

            if ((keyCode === 13 || keyCode === "Enter") && !event.shiftKey) {
                event.preventDefault();
                await this.sendMessage();
            }
        },
        containsMerlinfaceLink(str) {
            // Регулярное выражение для поиска URL
            const urlRegex = /https?:\/\/[^ ]*/g;
            // Ищем все ссылки в строке
            const links = str.match(urlRegex);

            if (links) {
                // Проверяем каждую ссылку на наличие 'merlinface.com'
                for (let link of links) {
                    if (link.includes("merlinface.com")) {
                        return true;
                    }
                }
            }

            return false;
        },
        isOnlyUrl(str) {
            try {
                const url = new URL(str);
                return str.trim() === url.href.trim();
            } catch {
                return false;
            }
        },
        async sendMessage() {
            console.log("sdf");
            if (this.isChatWithMerlinAI && this.myUser.chatMessageAttempts <= 0)
                return;

            if (
                +this.chatFaceSwapThemeId !== +this.themeId &&
                +this.chatStableDiffusionVideoThemeId !== +this.themeId
            ) {
                this.$v.$touch();
                if (this.$v.$invalid) return false;
            }

            let message = this.message;
            let image = this.uploadedImage;
            this.showEmojiModal = false;

            if (message) {
                if (this.containsMerlinfaceLink(message)) {
                    this.$emit("merlinFaceLink");
                    return;
                }

                if (this.isOnlyUrl(message.trim())) {
                    if (+this.chatFaceSwapThemeId !== +this.themeId) {
                        this.$emit("onlyUrlPromptError");
                        return;
                    }
                }
            }

            if (+this.chatFaceSwapThemeId === +this.themeId) {
                message = " ";
                image = `${this.faceSwapPhoto}||${image}`;
            }

            if (+this.chatStableDiffusionVideoThemeId === +this.themeId) {
                message = " ";
            }

            this.message = "";
            document.querySelector(".add-message-form__textarea").style.height =
                "initial";
            this.$v.$reset();

            const isSubscribed = !!this.subscriptionCategories.filter(
                (tariff) => tariff.level !== "FREE",
            ).length;

            if (this.activeThemeName === "Runway") {
                message = `${this.runwayParam}||${message}`;
            }

            console.log("Ss", this.selectedGptModel);
            await this.$apollo
                .mutate({
                    mutation: gql(sendMessageMutation),
                    variables: {
                        conversationId: this.conversationId,
                        message,
                        image,
                        messageType: "PLAIN",
                        isSubscribed: isSubscribed,
                        themeId: this.themeId ? +this.themeId : 0,
                        psychoType: this.myPerson.leader_type || "",
                        selectedGptModel: this.selectedGptModel
                    },
                    client: "chatClient",
                })
                .then(() => {
                    const theme = this.chatThemes.find(
                        (theme) => +theme.id === +this.themeId,
                    );

                    if (theme) {
                        let target = "sendToGPT";

                        if (theme.title === "Pika") {
                            target = "sendToPika";
                        }

                        if (theme.title === "Midjorney") {
                            target = "sendToMidjorney";
                        }

                        if (theme.title === "Stable Diffusion") {
                            target = "sendToSD";
                        }

                        if (theme.title === "Face Swap") {
                            target = "sendToFaceSwap";
                        }

                        if (theme.title === "Yandex GPT") {
                            target = "sendToYandexGPT";
                        }

                        if (theme.title === "DALLE-3") {
                            target = "sendToDalle";
                        }

                        if (theme.title === "Runway") {
                            target = "sendToGen3";
                        }

                        this.yandexGoal(target, false);
                    }

                    this.message = "";
                    this.uploadedImage = "";
                    this.$emit("new-message");
                })
                .catch((error) => {
                    let errorStr = error.toString();
                    errorStr = errorStr.replace("Error: GraphQL error: ", "");

                    if (errorStr === "You must be logged in") {
                        return this.$store
                            .dispatch("auth/obtainNewToken", {
                                gatewayClient: this.$apollo,
                            })
                            .then((response) => {
                                const responseData = response.data.refreshToken;
                                this.$store.dispatch("auth/successAuth", {
                                    jwt: responseData.jwt,
                                    refresh_token: responseData.refresh_token,
                                    user_id: responseData.user_uuid,
                                });
                                this.message = message;
                                this.uploadedImage = image;
                                this.sendMessage();
                            })
                            .catch(() => {
                                if (window.navigator.onLine) {
                                    this.$store
                                        .dispatch("auth/signout")
                                        .then(() => {
                                            this.$router.push({
                                                name: "Login",
                                            });
                                            this.$router.go();
                                        });
                                }
                            });
                    }

                    switch (errorStr) {
                        case "GPT_MESSAGE_TO_LONG":
                            this.message = message;
                            this.gptMessageToLong = true;
                            setTimeout(() => {
                                this.gptMessageToLong = false;
                            }, 5000);
                            break;
                        case "STABLE_DIFFUSION_MESSAGE_DAILY_LIMIT_REACHED":
                            this.merlinLimit = true;
                            break;
                        case "MERLIN_MESSAGE_LIMIT":
                            this.merlinLimit = true;
                            break;
                        case "WAIT_5_SECONDS":
                            this.$msgBox(
                                this.localeError(
                                    "error",
                                    this.locale("manyMessage5seconds"),
                                ),
                            );
                            break;

                        case "REACH_THE_GOAL_MESSAGE_DAILY_LIMIT_REACHED":
                            this.$msgBox(
                                this.localeError("error"),
                                this.$options.filters.localizeError(
                                    "REACH_THE_GOAL_MESSAGE_DAILY_LIMIT_REACHED",
                                ),
                            );
                            this.disableSpecialMessage();
                            break;

                        case "BUSINESS_PROPOSAL_MESSAGE_DAILY_LIMIT_REACHED":
                            this.$msgBox(
                                this.$options.filters.localizeError("error"),
                                this.$options.filters.localizeError(
                                    "BUSINESS_PROPOSAL_MESSAGE_DAILY_LIMIT_REACHED",
                                ),
                            );
                            this.disableSpecialMessage();
                            break;

                        case "WAIT_60_SECONDS":
                            this.$msgBox(
                                this.localeError(
                                    "error",
                                    this.locale("manyMessage60seconds"),
                                ),
                            );
                            break;

                        case "RECEIVER_RESTRICT_MESSAGES":
                        case "SENDER_RESTRICT_MESSAGES":
                            this.$msgBox(
                                this.localeError(
                                    "error",
                                    this.locale("userDisable"),
                                ),
                            );
                            break;

                        default:
                            this.$msgBox(
                                this.localeError(
                                    "error",
                                    this.locale("sendError"),
                                ),
                            );
                    }
                });
        },
        async uploadPhoto(event) {
            const files = event.target.files;
            if (!files[0].size) {
                return;
            }
            this.isImageLoading = true;

            const url = await this.getPhotoUploadUrl();

            const data = new FormData();

            data.set("file", files[0]);

            let xhr = new XMLHttpRequest();
            xhr.open("POST", url, true);

            xhr.setRequestHeader(
                "Authorization",
                this.$store.getters["auth/token"],
            );

            xhr.setRequestHeader(
                "Content-Type",
                this.$store.getters["auth/token"],
            );

            const $this = this;

            xhr.onreadystatechange = function () {
                if (this.readyState === XMLHttpRequest.DONE) {
                    if (this.status === 200) {
                        let response = JSON.parse(this.responseText);

                        $this.isImageLoading = false;
                        $this.uploadedImage = response.url;
                        event.target.value = null;
                        if (
                            +$this.chatMidjourneyThemeId === +$this.themeId &&
                            $this.fileType === "describe"
                        ) {
                            $this.message = "/describe";
                        }
                    } else {
                        console.log(this.status, this.statusText);
                    }
                }
            };

            this.uploadTypeDropdownActive = false;
            xhr.send(files[0]);
        },
        async getPhotoUploadUrl() {
            return await this.$apollo
                .query({
                    query: gql(getAmazonPhotoUploadUrlQuery),
                })
                .then((r) => {
                    return r.data.getAmazonPhotoUploadUrl;
                });
        },
        resizeTextarea: function (e) {
            const area = e.target;
            area.style.height = "auto";
            area.style.height = area.scrollHeight + "px";
        },
        focusHandler() {
            if (this.isDesktopView) return;
            this.$store.dispatch("set", {
                name: "hideMobileMenu",
                value: true,
            });
        },
        textareaBlurHandler(e) {
            /*this.$store.dispatch("set", {
                name: "hideMobileMenu",
                value: false,
            });*/
            this.cursorPosition = e.target.selectionStart;
        },
        selectEmoji(emoji) {
            if (!this.message) {
                return (this.message = emoji.data);
            }
            const strBeforeCursor = this.message.substr(0, this.cursorPosition);
            const strAfterCursor = this.message.substr(this.cursorPosition);
            this.message = strBeforeCursor + emoji.data + strAfterCursor;
            this.showEmojiModal = false;
        },
        handleTextareaFocus() {
            if(this.activeThemeName === "Midjorney" || this.activeThemeName === "DALLE-3"){
                this.message = this.preMessageMidjorney;
                this.textareaRows = 5;
            }
            else if (this.isGPTChat  && !this.message) {
                this.message = this.preMessageGpt;
                this.textareaRows = 5;
            }
            else {
                this.message = null;
            }

        },
        getCreditWord(count) {
            if (count === 1) {
                return "кредит";
            } else if (count >= 2 && count <= 4) {
                return "кредита";
            } else {
                return "кредитов";
            }
        }
    },

};
</script>

<style lang="scss" src="./add-message-form.scss"></style>
