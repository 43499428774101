<template lang="pug">
    .message(:class="messageClass" v-if="message.themeId === themeId || !themeId")
        router-link(:to="userLink" v-if="!isPlanNotifications")._user-avatar
            user-photo(:photo="userAvatar" :micro="true")
        ._message-info
            router-link(:to="userLink" v-if="!isPlanNotifications")._message-name {{ userName }}
                span._message-time(:title="`ID: ${message.id}`" @click.prevent) {{ formatTimestamp(message.createdAt) }}
            ._message-content
                ._message-text
                    plan-notification(v-if="isPlanNotifications" :notification="text" :target="myUser.is_user_from_target")
                    template(v-else-if="isMidjourneyQueue") {{ midjourneyQueueMessage }} 
                    template(v-else-if="isRunwayWaitingStart") {{ runwayWaitingStartMessage }} 
                    template(v-else-if="isRunwayProgress") {{ runwayProgressMessage }} 
                    template(v-else-if="isMidjourneyBlocked") {{ midjourneyBlockedMessage }}
                    template(v-else-if="isFaceSwapQueue") {{ faceSwapQueueMessage }}
                    template(v-else-if="isRunWayMessage") {{ locale(text) }} {{ runwayVideoModel }}
                    template(v-else-if="isLocaleMessage") {{ locale(text, {0: 1}) }}
                    template(v-else-if="isMidjourneyParamError") {{ midjourneyParamErrorMessage }}
                    template(v-else-if="isMidjourneyParamsError") {{ midjourneyParamsErrorMessage }}
                    template(v-else-if="isMidjourneyPercents") {{ message.message }}
                    template(v-else-if="isGPTLoading")
                        span._gpt-loading
                    span(v-else v-html="htmlText")
                    ._stop-button(v-if="message.showStopButton && !isAssistant && activeThemeName !== 'Yandex GPT'" @click="stopGenerating()") Остановить генерацию
                    ._stop-button(v-if="activeThemeName !== 'Yandex GPT' && !isPlanNotifications && isNotAI && isLastMessage && message.senderId === MERLIN_CONVERSATION_ID && !blockMerlinChat  && !isAssistant" @click="regenerate()") Повторить
                ._message-image-content
                    ._message-image-box(v-if="message.image && message.image.url" :class="{'with-video': isRunwayPreloader}")
                        img(v-if="isDallePreloader" :src="dalleImage")._message-image
                        img(v-if="isStableDiffusionPreloader" :src="stableDiffusionImage")._message-image
                        img(v-else-if="isFaceSwapPreloader" :src="faceSwapImage")._message-image
                        img(v-else-if="isMidjourneyPreloader" :src="midjourneyImage")._message-image
                        img(v-else-if="isMidjourneyError" :src="midjourneyErrorImage")._message-image
                        img(v-else-if="isPikaPreloader" :src="pikaImage")._message-image
                        template(v-else-if="isRunwayPreloader")._message-video
                            image-preloader(:style="{'aspect-ratio': videoAspectRatio}")
                        //._message-image.-zoom(v-else)
                        image-skeleton(v-else :force="isShowImageForce" :src="message.small_image.url ? message.small_image.url : message.image.url" @click.native="$modal.show('Image', { image: message.image.url })")._message-image.-zoom
                    ._midjorney-upscale-btns(v-if="message.midjorneyData && message.message === 'generated-by-midjorney' && message.api_hash")
                        ui-button(type="bordered" size="small" v-for="i in 4" :key="i" :text="`${blockedUpscale[message.id].find(u => u.id === i) ? blockedUpscale[message.id].find(u => u.id === i).counter + locale('seconds') : locale('upscale', [i])}`" @click.native="sendUpscaleMessage(message.id, i)" :disabled="!!blockedUpscale[message.id].find(u => u.id === i) || !myUser.chatMessageAttempts")
                    ._midjorney-upscale-btns(v-if="message.midjorneyData && message.message === 'upscale-by-midjorney' && message.api_hash&& activeMjKeys.indexOf(message.type) >= 0")
                        //ui-button(type="bordered" size="small"  @click.native="zoomOut(message.id, 'low')")._midjourney-btn
                        //    template(#content)
                        //        span._midjourney-btn-content
                        //            svg-icon(icon-name="vary" added-catalog="midjourney")._midjourney-btn-icon
                        //            | Vary (Subtle)
                        //ui-button(type="bordered" size="small"  @click.native="zoomOut(message.id, 'high')")._midjourney-btn
                        //    template(#content)
                        //        span._midjourney-btn-content
                        //            svg-icon(icon-name="vary" added-catalog="midjourney")._midjourney-btn-icon
                        //            | Vary (Strong)
                        ui-button(type="bordered" size="small" @click.native="zoomOut(message.id, '2x')")._midjourney-btn
                            template(#content)
                                span._midjourney-btn-content
                                    svg-icon(icon-name="zoom-out" added-catalog="midjourney")._midjourney-btn-icon
                                    | Zoom Out 2x
                        ui-button(type="bordered" size="small" @click.native="zoomOut(message.id, '1.5x')")._midjourney-btn
                            template(#content)
                                span._midjourney-btn-content
                                    svg-icon(icon-name="zoom-out" added-catalog="midjourney")._midjourney-btn-icon
                                    | Zoom Out 1.5x
                        ._midjorney-upscale-btns.-four(v-if="message.midjorneyData && !message.prompt && message.message === 'upscale-by-midjorney'")
                            ui-button(type="bordered" size="small" @click.native="zoomOut(message.id, 'left')")._midjourney-btn
                                template(#content)
                                    span._midjourney-btn-content
                                        svg-icon(icon-name="left" added-catalog="midjourney")._midjourney-btn-icon
                            ui-button(type="bordered" size="small" @click.native="zoomOut(message.id, 'right')")._midjourney-btn
                                template(#content)
                                    span._midjourney-btn-content
                                        svg-icon(icon-name="right" added-catalog="midjourney")._midjourney-btn-icon
                            ui-button(type="bordered" size="small" @click.native="zoomOut(message.id, 'up')")._midjourney-btn
                                template(#content)
                                    span._midjourney-btn-content
                                        svg-icon(icon-name="up" added-catalog="midjourney")._midjourney-btn-icon
                            ui-button(type="bordered" size="small" @click.native="zoomOut(message.id, 'down')")._midjourney-btn
                                template(#content)
                                    span._midjourney-btn-content
                                        svg-icon(icon-name="down" added-catalog="midjourney")._midjourney-btn-icon

                    ._midjorney-upscale-btns(v-if="message.type === 'describe'")
                        ui-button(type="bordered" size="small" v-for="i in 4"  :key="i" :text="`Использовать #${i}`" @click.native="sendDescribeMessage(i)")
                    ._download-btn(v-if="message.message === 'generated-by-midjorney'")
                        ui-button(@click.native="midjourneyRegenerate(message.id)" size="small" type="bordered" text="Сгенерировать заново")
                    ._download-btn(v-if="isUseFaceSwap")
                        ui-button(@click.native="useFaceSwap(message.image)" size="small" text="Вставить моё лицо")
                    a(:href="message.image.url" :download="true" target="_blank" v-if="isImageDownloadable")._download-btn
                        ui-button(type="bordered" size="small" :text="locale('download')")
                ._message-video-box(v-if="message.video" :style="{'aspect-ratio': videoAspectRatio}")
                    video._message-video(controls="true" preload="false")
                        source(:src="message.video" type="video/mp4")
                a(v-if="message.video" :href="message.video" :download="true" target="_blank")._download-btn.-video
                    ui-button(size="small" :text="locale('download')")
                ._continue-button(v-if="message.continue" @click="continueGeneration()") Продолжить генерацию
        ._message-copy(@click="share(message)" v-if="isShareMessage")
            svg-icon(icon-name="share")._message-copy-icon
        ._message-copy(@click="copyToClip(message)" v-else)
            svg-icon(v-if="!message.copied" icon-name="copy")._message-copy-icon
            svg-icon(v-else icon-name="check")._message-check-icon
</template>

<script>
import UserPhoto from "../../../ui/user-photo/user-photo.vue";
import UiButton from "../../../ui/ui-button/ui-button.vue";
import gql from "graphql-tag";
import sendMessageMutation from "@/graphql/mutations/sendMessage.mutation.graphql";
import midjourneyRegenerateMutation from "@/graphql/mutations/midjourneyRegenerate.mutation.graphql";
import stopGeneratingMutation from "@/graphql/mutations/stopGenerating.mutation.graphql";
import continueGeneratingMutation from "@/graphql/mutations/continueGenerating.mutation.graphql";
import regenerateMessageMutation from "@/graphql/mutations/regenerateMessage.mutation.graphql";
import PlanNotification from "@/components/chat/messages/plan-notification/plan-notification.vue";
import ImageSkeleton from "@/components/image-skeleton/image-skeleton.vue";
import ImagePreloader from "@/components/chat/messages/image-preloader/image-preloader.vue";

export default {
    name: "ChatMessage",
    components: {
        ImagePreloader,
        ImageSkeleton,
        PlanNotification,
        UiButton,
        UserPhoto,
    },
    props: {
        activeMjKeys: {
            type: Array,
            required: true,
            default: () => [],
        },
        isLastMessage: {
            type: Boolean,
            required: false,
            default: false,
        },
        isAssistant: {
            type: Boolean,
            required: true,
            default: false,
        },
        conversationId: {
            type: Number,
            required: true,
            default: 1,
        },
        blockedUpscale: {
            type: Object,
            required: true,
            default: () => ({}),
        },
        message: {
            type: Object,
            required: true,
            default: () => ({}),
        },
    },
    data() {
        return {
            localeMessages: [
                "generated-by-stable-diffusion",
                "generated-by-midjorney",
                "generated-by-dalle",
                "upscale-by-midjorney",
                "optimization-midjorney",
                "midjorney-moderator-error",
                "midjorney-moderator-error-1",
                "midjorney-moderator-error-2",
                "midjorney-single-image-error",
                "midjorney-image-filter-error",
                "midjorney-unknown-style-error",
                "midjorney-stress-error",
                "midjorney-unknown-error",
                "midjorney-params-position-error",
                "midjorney-sref-text-error",
                "midjorney-sref-error",
                "midjorney-cref-text-error",
                "midjorney-cref-error",
                "midjorney-image-timeout-error",
                "midjorney-duplicate-image-error",
                "midjorney-image-access-error",
                "midjorney-something-wrong-error",
                "generated-by-pika",
                "pika-unknown-error",
                "pika-generating",
                "gpt-error-try-again",
                "face-swap-generating",
                "midjourney-describe",
                "midjourney-invalid-image-link",
                "midjorney-purchase-error",
                "midjourney-queue",
                "face-swap-queue",
                "face-swap-detection-failed",
                "face-swap-detection-target-failed",
                "generated-by-face-swap",
                "assistant-server-error",
                "assistant-limit-error",
                "assistant-prompt-error",
                "midjorney-cw-sref-error",
                "midjorney-cw-cref-error",
                "dalle-moderation-error",
                "runway-waiting",
                "generated-by-runway",
                "runway-error",
                "midjorney-error"
            ],
            selectedGptModel: localStorage.getItem("selectedGptModel") ? localStorage.getItem("selectedGptModel") : null,
        };
    },
    computed: {
        runwayVideoModel: function () {
            if (this.isRunwayPreloader || this.text === "generated-by-runway") {
                const type = this.message.type.split("||");
                return type[0];
            }
            return "";
        },
        videoAspectRatio: function () {
            if (this.isRunwayPreloader || this.text === "generated-by-runway") {
                const type = this.message.type.split("||");
                return type[1].replace(":", "/");
            }
            return "16/9";
        },
        isShareMessage: function () {
            return (
                [
                    "generated-by-midjorney",
                    "generated-by-dalle",
                    "upscale-by-midjorney",
                    "generated-by-stable-diffusion",
                ].indexOf(this.message.message) >= 0
            );
        },
        isNotAI: function () {
            return (
                [
                    "Face Swap",
                    "Pika",
                    "Midjorney",
                    "Stable Diffusion",
                    "Stable Diffusion Video",
                    "DALLE-3",
                    "Runway",
                ].indexOf(this.activeThemeName) === -1
            );
        },
        activeThemeName: (state) => state.$store.getters["activeThemeName"],
        blockMerlinChat: (state) => state.$store.getters["merlinChatBlocked"],
        themeId: (state) => +state.$route.params.theme_id,
        userAvatar: (state) =>
            state.message.senderPersonId === state.myPerson.uuid
                ? state.myPerson.photos.avatar
                : state.message.senderPhoto,
        userName: (state) =>
            state.message.senderPersonId === state.myPerson.uuid
                ? state.locale("myMessageTitle")
                : state.formatUserName(state.message.senderName),
        isChatWithMerlinAI: (state) =>
            state.merlinConversationId === state.chatId,
        userLink: (state) =>
            state.isAssistant || state.isChatWithMerlinAI
                ? state.$route.path
                : `/person/${state.message.senderPersonId}`,
        text: (state) => state.message.message,
        htmlText: function () {
            let text = this.text.trim();
            const codeBlocks = [];
            const tableBlocks = [];
            let codeBlockCount = 0;
            let tableCount = 0;

            const regex = {
                h1: /^# (.*?)$/gm,
                h2: /^## (.*?)$/gm,
                h3: /^### (.*?)$/gm,
                bold: /\*\*(.*?)\*\*/g,
                italic: /\*(.*?)\*/g,
                code: /```(\w+)?\n([\s\S]*?)```/g,
                inlineCode: /`(.*?)`/g,
                table: /\|(.+)\|/gm,
            };

            if (regex.code.test(text)) {
                text = text.replace(regex.code, function (match, lang, code) {
                    // Экранирование специальных HTML символов
                    code = code.replace(/&/g, "&").replace(/</g, "&lt;").replace(/>/g, "&gt;");
                    // Добавление класса для языка, если он указан
                    const langClass = lang ? ` class="language-${lang}"` : "";

                    let i = ++codeBlockCount;
                    codeBlocks.push({
                        id: i,
                        value: `<code${langClass}>${code}</code>`,
                    });
                    return `code_block_${i}`;
                });
            }

            text = text.split("\n");

            text = text.map((item) => {
                if (item.indexOf("<code class") >= 0) {
                    return item;
                }

                if (regex.h1.test(item)) {
                    return item.replace(regex.h1, "<h1>$1</h1>");
                }

                if (regex.h2.test(item)) {
                    return item.replace(regex.h2, "<h2>$1</h2>");
                }

                if (regex.h3.test(item)) {
                    return item.replace(regex.h3, "<h3>$1</h3>");
                }

                if (regex.bold.test(item)) {
                    item = item.replace(regex.bold, "<b>$1</b>");
                }

                if (regex.italic.test(item)) {
                    item = item.replace(regex.italic, "<i>$1</i>");
                }

                if (regex.inlineCode.test(item)) {
                    item = item.replace(regex.inlineCode, "<code>$1</code>");
                }

                if (regex.table.test(item)) {
                    const table = tableBlocks.find((t) => t.id === tableCount);

                    if (item.indexOf("----") >= 0) {
                        item = item.replace(/-/g, "");
                    }

                    const value = item.replace(
                        regex.table,
                        (match, content) => {
                            const cells = content
                                .split("|")
                                .filter(Boolean)
                                .map((cell) => cell.trim());
                            const row = cells
                                .map((cell) => `<td>${cell}</td>`)
                                .join("");
                            return `<tr>${row}</tr>`;
                        },
                    );

                    /* Если такая таблица есть */
                    if (table?.id) {
                        console.log("Старая таблица");
                        table.value += value;
                    } else {
                        console.log("Новая таблица");
                        tableBlocks.push({
                            id: ++tableCount,
                            value,
                        });
                        return `table_block_${tableCount}`;
                    }
                    return "";
                } else {
                    tableCount++;
                }

                if (item) {
                    return `<p>${item}</p>`;
                }

                return "";
            });

            text = text.join("");

            codeBlocks.forEach((block) => {
                text = text.replace(`code_block_${block.id}`, block.value);
            });

            tableBlocks.forEach((block) => {
                text = text.replace(
                    `table_block_${block.id}`,
                    `<table>${block.value}</table>`,
                );
            });

            text = text.replace(/【.*?】/g, "");

            return text;
        },
        isPlanNotifications: function () {
            if (typeof this.text !== "string") return false;

            // return /^plan-notification\|\|\d+\|\|\d+$/.test(this.text);
            return /^plan-notification\|\|\d+\|\|\d+\|\|(true|false|1|0)$/.test(this.text);
        },
        isRunWayMessage: (state) => state.text === "generated-by-runway",
        isLocaleMessage: (state) => state.localeMessages.includes(state.text),
        isMidjourneyQueue: (state) =>
            state.text === "midjourney-queue" && +state.message.queue >= 0,
        isRunwayProgress: (state) => state.text.includes("runway-progress"),
        isRunwayWaitingStart: (state) => state.text.includes("runway-waiting-start"),
        isMidjourneyBlocked: (state) =>
            state.text.indexOf("midjourney-moderation-blocked") >= 0,
        isFaceSwapQueue: (state) =>
            state.text === "face-swap-queue" && +state.message.queue >= 0,
        isMidjourneyParamError: (state) =>
            state.text.indexOf("midjorney-parameters-error") >= 0,
        isMidjourneyParamsError: (state) =>
            state.text.indexOf("midjorney-expected-argument-error") >= 0,
        isMidjourneyPercents: (state) =>
            state.text === "midjourney-queue" && +state.message.queue < 0,
        isGPTLoading: (state) => state.text === "gpt-loading",
        midjourneyQueueMessage: (state) =>
            state.locale(state.text, [state.message.queue + 1]),
        runwayProgressMessage: (state) =>
            state.locale(state.text.split("||")[0], [
                state.text.split("||")[1],
            ]),
        runwayWaitingStartMessage: (state) =>
            state.locale(state.text.split("||")[0], [
                state.text.split("||")[1] + 15
            ]),
        midjourneyBlockedMessage: (state) =>
            state.locale(state.text.split("||")[0], [
                Math.ceil(+state.text.split("||")[1] / 60),
            ]),
        faceSwapQueueMessage: (state) =>
            state.locale(state.text, [state.message.queue + 1]),
        midjourneyParamErrorMessage: (state) =>
            state.locale(state.text.split("|")[0]) + state.text.split("|")[1],
        midjourneyParamsErrorMessage: (state) =>
            state.locale(state.text.split("|")[0], [state.text.split("|")[1]]),
        isStableDiffusionPreloader: (state) =>
            state.message.image.url === "stable-diffusion",
        isDallePreloader: (state) => state.message.image.url === "dalle",
        isFaceSwapPreloader: (state) => state.message.image.url === "face-swap",
        isMidjourneyPreloader: (state) =>
            state.message.image.url === "midjorney",
        isMidjourneyError: (state) =>
            state.text.includes("midjorney-error"),
        isPikaPreloader: (state) => state.message.image.url === "pika",
        isRunwayPreloader: (state) =>
            state.text === "runway-waiting" ||
            state.text.includes("runway-progress"),
        isShowImageForce: function () {
            if (this.message === "generated-by-face-swap") {
                return true;
            }
            return this.isLocaleMessage ? "" : this.isMidjourneyPercents;
        },
        stableDiffusionImage: (state) =>
            state.isStableDiffusionPreloader
                ? require(`../../../../assets/images/stable-diffusion-loading-${state.$i18n.locale}.jpg`)
                : state.message.image.url,
        dalleImage: (state) =>
            state.isDallePreloader
                ? require(`../../../../assets/images/dalle-${state.$i18n.locale}.jpg`)
                : state.message.image.url,
        faceSwapImage: (state) =>
            state.isFaceSwapPreloader
                ? require(`../../../../assets/images/face-swap-loading-${state.$i18n.locale}.jpg`)
                : state.message.image.url,
        midjourneyImage: (state) =>
            state.isMidjourneyPreloader
                ? require(`../../../../assets/images/midjorney-loading-${state.$i18n.locale}.jpg`)
                : state.message.image.url,
        midjourneyErrorImage: (state) =>
            state.isMidjourneyError
                ? require("../../../../assets/images/midjorney-error.png")
                : state.message.image.url,
        pikaImage: (state) =>
            state.isPikaPreloader
                ? require(`../../../../assets/images/pika-loading-${state.$i18n.locale}.jpg`)
                : state.message.image.url,
        isImageDownloadable: (state) =>
            [
                "generated-by-stable-diffusion",
                "generated-by-midjorney",
                "upscale-by-midjorney",
                "generated-by-face-swap",
                "generated-by-dalle",
            ].includes(state.text),
        isUseFaceSwap: (state) => state.text === "upscale-by-midjorney",
        messageClass: (state) => ({
            merlinChat: state.isChatWithMerlinAI,
            //last: messagesHistory.length - 1 === index,
            my: state.message.senderPersonId === state.myPerson.uuid,
            error: state.message.error,
            //special: message.businessProposal || message.reachTheGoal
        }),
        subscriptionCategories: (state) =>
            state.$store.getters["user/subscriptionCategories"],
    },
    mounted() {

        window.addEventListener("selectedGptModel-changed", (event) => {
            this.selectedGptModel = event.detail.storage;
        });
    },
    methods: {
        formatTimestamp(timestamp) {
            const date = new Date(timestamp / 1);
            const day = date.getDate().toString().padStart(2, "0");
            const month = (date.getMonth() + 1).toString().padStart(2, "0");
            const hours = date.getHours().toString().padStart(2, "0");
            const minutes = date.getMinutes().toString().padStart(2, "0");

            return `${day}.${month} ${hours}:${minutes}`;
        },
        continueGeneration() {
            this.$apollo
                .mutate({
                    mutation: gql(continueGeneratingMutation),
                    variables: {
                        messageId: +this.message.id,
                        conversationId: +this.conversationId,
                    },
                    client: "chatClient",
                })
                .then(() => {
                    console.log("Успешное продолжение");
                });
        },
        regenerate() {
            this.$apollo
                .mutate({
                    mutation: gql(regenerateMessageMutation),
                    variables: {
                        messageId: +this.message.id,
                        conversationId: +this.conversationId,
                        themeId: +this.themeId,
                    },
                    client: "chatClient",
                })
                .then(() => {
                    console.log("Regenerate");
                });
        },
        stopGenerating() {
            this.$apollo
                .mutate({
                    mutation: gql(stopGeneratingMutation),
                    variables: {
                        messageId: +this.message.id,
                    },
                    client: "chatClient",
                })
                .then(() => {
                    this.message.showStopButton = false;
                    this.$store.dispatch("set", {
                        name: "merlinChatBlocked",
                        value: false,
                    });
                });
        },
        async share(message) {
            console.log(navigator.canShare());
            await navigator.share({
                title: "Поделиться фотографией",
                text: `${message.prompt}`,
                url: `https://merlinface.com/share/photo/${message.id}`,
            });
        },
        copyToClip(str) {
            if (window.isSecureContext) {
                navigator.clipboard.writeText(str.message);
            } else {
                const textArea = document.createElement("textarea");
                textArea.value = str.message;
                document.body.appendChild(textArea);
                textArea.focus();
                textArea.select();
                try {
                    document.execCommand("copy");
                } catch (err) {
                    console.error("Unable to copy to clipboard", err);
                }
                document.body.removeChild(textArea);
            }
            str.copied = true;
            setTimeout(() => {
                str.copied = false;
            }, 2000);
        },
        sendUpscaleMessage(messageId, imageIndex) {
            if (this.isChatWithMerlinAI && !this.myUser.chatMessageAttempts)
                return;

            const message = messageId + "-" + imageIndex;
            let blocked = this.blockedUpscale[messageId];

            this.blockedUpscale[messageId].push({
                id: imageIndex,
                counter: 30,
            });

            const newBlockedUpscale = this.blockedUpscale[messageId].find(
                (u) => u.id === imageIndex,
            );
            if (newBlockedUpscale) {
                setInterval(() => {
                    newBlockedUpscale.counter--;
                }, 1000);
            }

            setTimeout(() => {
                blocked = blocked.splice(blocked.indexOf(imageIndex));
            }, 30000);

            const isSubscribed = !!this.subscriptionCategories.filter(
                (tariff) => tariff.level !== "FREE",
            ).length;

            this.$apollo
                .mutate({
                    mutation: gql(sendMessageMutation),
                    variables: {
                        conversationId: this.conversationId,
                        message,
                        image: null,
                        messageType: "UPSCALE_MIDJORNEY",
                        isSubscribed: isSubscribed,
                        themeId: this.themeId ? +this.themeId : 0,
                        selectedGptModel: this.selectedGptModel
                    },
                    client: "chatClient",
                })
                .then(() => {
                    this.$emit("upscale");
                    this.$emit("new-message");
                })
                .catch(() => {
                    this.$msgBox(
                        this.localeError("error", this.locale("sendError")),
                    );
                });
        },
        zoomOut(messageId, level) {
            if (this.isChatWithMerlinAI && !this.myUser.chatMessageAttempts)
                return;

            const message = `zoomOut-${messageId}-${level}`;

            const isSubscribed = !!this.subscriptionCategories.filter(
                (tariff) => tariff.level !== "FREE",
            ).length;

            this.$apollo
                .mutate({
                    mutation: gql(sendMessageMutation),
                    variables: {
                        conversationId: this.conversationId,
                        message,
                        image: null,
                        messageType: "ZOOM_OUT_MIDJORNEY",
                        isSubscribed: isSubscribed,
                        themeId: this.themeId ? +this.themeId : 0,
                        selectedGptModel: this.selectedGptModel
                    },
                    client: "chatClient",
                })
                .then(() => {
                    this.$emit("upscale");
                    this.$emit("new-message");
                })
                .catch(() => {
                    this.$msgBox(
                        this.localeError("error", this.locale("sendError")),
                    );
                });
        },
        detect() {
            this.$modal.show("face-verificator");
        },
        midjourneyRegenerate(id) {
            this.$apollo.mutate({
                mutation: gql(midjourneyRegenerateMutation),
                variables: {
                    id: +id,
                },
                client: "chatClient",
            });
        },
        useFaceSwap(image) {
            // Если у юзера нет анфаса, открываем окно
            if (this.IS_FACE_SWAP_NEED_PHOTOS || this.IS_FACE_SWAP_NEED_PLAN) {
                return this.$store.dispatch("set", {
                    name: "faceSwapVerification",
                    value: true,
                });
            }

            this.$apollo.mutate({
                mutation: gql(sendMessageMutation),
                variables: {
                    conversationId: this.conversationId,
                    message: " ",
                    image: `${this.myPerson.photos.anfas}||${image.url}`,
                    messageType: "FACE_SWAP",
                    isSubscribed: true,
                    themeId: this.themeId ? +this.themeId : 0,
                },
                client: "chatClient",
            });
        },
        sendDescribeMessage(index) {
            const all = this.text
                .replace("1️⃣", "")
                .replace("2️⃣", "")
                .replace("3️⃣", "")
                .replace("4️⃣", "")
                .split("\n\n");

            this.$emit(
                "describe-message",
                this.removeHtmlTags(all[index - 1].trim()),
            );
        },
        removeHtmlTags(input) {
            const doc = new DOMParser().parseFromString(input, "text/html");
            return doc.body.textContent || "";
        },
    },
};
</script>

<style lang="scss" src="./message.scss"></style>
