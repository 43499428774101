<template lang="pug">
    .user-nav(v-if="auth")
        router-link._item(:to="myLink" :class="{ 'user-nav__item_active': isMyProfile }" data-tip="my-profile" tag="a")
            ._item-icon
                svg-icon._icon.-user(icon-name="myProfile")
            ._item-text Главная

        router-link._item(:to="{ name: 'persons', params: { personType: 'celebrity' } }" active-class="user-nav__item_active" tag="a")
            ._item-icon
                svg-icon._icon.-star-users(icon-name="celebrity")
            ._item-text {{ locale("celebrities") }}
        router-link._item(:to="`/messages/${MERLIN_CONVERSATION_ID}`" active-class="user-nav__item_active" tag="a" exact)
            ._item-icon
                svg-icon._icon.-assistant(icon-name="neural" added-catalog="user-nav")
            ._item-text Нейросети
        router-link._item(to="/assistants/" active-class="user-nav__item_active" tag="a")
            ._item-icon
                svg-icon._icon.-assistant(icon-name="assistant" added-catalog="user-nav")
            ._item-text GPT-ассистенты
        router-link._item(:to="{ name: 'persons', params: { personType: 'friends' } }" active-class="user-nav__item_active" tag="a")
            ._item-icon
                svg-icon._icon.-friends(icon-name="friends")
            ._item-text Контакты
                span._item-new-counter(v-if="friendRequestsCounter > 0") &nbsp +{{ friendRequestsCounter }}

        router-link._item(v-if="celebrity" :to="{ name: 'persons', params: { personType: 'compare-with-me' } }" active-class="user-nav__item_active" tag="a")
            ._item-icon
                svg-icon._icon.-friends(icon-name="friends")
            ._item-text Контакты
        router-link._item(to="/messages/" active-class="user-nav__item_active" tag="a" exact)
            ._item-icon
                svg-icon._icon.-message(icon-name="messages")
            ._item-text {{ locale("messages") }}
                span._item-new-counter(v-if="unreadMessages > 0") &nbsp +{{ unreadMessages }}
        router-link._item(to="/analyze/" active-class="user-nav__item_active" tag="a" exact)
            ._item-icon
                svg-icon._icon.-assistant(icon-name="analyzeAi")
            ._item-text {{ locale("analyze") }}

        router-link._item(to="/recommended-people" active-class="user-nav__item_active" data-tip="people-recommendations" tag="a")
            ._item-icon
                svg-icon._icon.-recommended-people(icon-name="friends" added-catalog="user-nav")
            ._item-text {{ locale("recommendations") }}
        router-link._item(:to="{ name: 'settings' }" active-class="user-nav__item_active" tag="a" exact)
            ._item-icon
                svg-icon._icon.-settings(icon-name="user-nav-settings")
            ._item-text {{ locale("settings") }}

        router-link._item(to="/promocodes" tag="a" v-if="isCurator || isAdmin")
            ._item-icon
                svg-icon._icon.-promocode(icon-name="user-nav-promocode")
            ._item-text Мои промокоды
        ._item(@click="openCategoryMenu", v-if="isEditor")
            ._item-icon
                svg-icon._icon.-friends(icon-name="manage-users")
            ._item-text {{ locale("manager") }}



</template>

<script>
import {mapGetters} from "vuex";
import GlitchAnimation from "@/components/ui/glitch-animation/glitch-animation.vue";
import {friendMixins} from "@/mixins/friendMixins";

export default {
    name: "UserNav",
    components: {GlitchAnimation},
    mixins: [friendMixins],
    data: () => ({
        friendRequestsCounter: 0,
    }),
    computed: {
        ...mapGetters({
            myUuid: "user/uuid",
            unreadMessages: "getNewMessagesCounter",
            celebrity: "user/celebrity",
            auth: "auth/authStatus",
        }),
        myLink() {
            if (this.myPerson.public_url) {
                return `/${this.myPerson.public_url}`;
            }
            if (this.myPerson.uuid) {
                return `/person/${this.myPerson.uuid}`;
            }
            return "/";
        },
    },
    watch: {
        async "$route.path"() {
            await this.friendRequestsCount()
                .then((response) => {
                    console.log(response);
                    this.friendRequestsCounter =
                        response.data.friendRequestsCount;
                })
                .catch((e) => {
                    throw e;
                });
        },
    },
    async created() {
        await this.friendRequestsCount()
            .then((response) => {
                this.friendRequestsCounter = response.data.friendRequestsCount;
            })
            .catch((e) => {
                throw e;
            });
    },
    methods: {
        openCategoryMenu() {
            this.$bus.$emit("openCategoryMenu");
        }
    },
};
</script>

<style lang="scss">
@import "user-nav";
</style>
