<template lang="pug">
    welcome-info
        template(v-slot:title)
            span(v-if="errorCount <= 10") {{ locale("title") }}
        template(v-slot:subtitle) {{ locale("subtitle") }}
        template(v-slot:content)
            change-name(@complete="nextStep" v-if="errorCount <= 10")
            .create-error(v-else) Произошла ошибка при создании профиля, пожалуйста обратитесь в 
                a(:href="SUPPORT_LINK" target="_blank")._link службу поддержики
</template>
<script>
import WelcomeInfo from "@/components/welcome/welcome-info/welcome-info";
import ChangeName from "@/components/person/change-my-name/change-my-name";
import getMyUserQuery from "@/graphql/queries/getMyUser.query.graphql";
import gql from "graphql-tag";

export default {
    name: "WelcomeStart",
    components: { ChangeName, WelcomeInfo },
    data() {
        return {
            errorCount: 0,
        };
    },
    mounted() {
        console.log(this.myUser.display_name, "this.myUser.display_name");
        this.VKGoal("Konv_1_registration");
        if (this.myUser.display_name !== "Newbie") {
            this.completeStep();
        }
    },
    methods: {
        async nextStep() {
            await this.$store
                .dispatch("auth/obtainNewToken", {
                    gatewayClient: this.$apollo,
                })
                .then((response) => {
                    const responseData = response.data.refreshToken;
                    this.$store.dispatch("auth/successAuth", {
                        jwt: responseData.jwt,
                        refresh_token: responseData.refresh_token,
                        user_id: responseData.user_uuid,
                    });
                    return responseData.jwt;
                })
                .catch(() => {
                    if (window.navigator.onLine) {
                        this.$store.dispatch("auth/signout").then(() => {
                            this.$router.push({
                                name: "Login",
                            });
                            this.$router.go();
                        });
                    }
                });
            await this.completeStep();
        },
        async getMyUser() {
            await this.$apollo
                .query({
                    query: gql`
                        ${getMyUserQuery}
                    `,
                    fetchPolicy: "no-cache",
                })
                .then(async (r) => {
                    const user = r.data.getMyUser;
                    if (user.on_boarding_completed) {
                        return await this.$router.push(`/messages/${this.MERLIN_CONVERSATION_ID}`);
                    }
                    this.saveTelemetry(this.errorCount);
                    this.errorCount++;
                    setTimeout(() => {
                        this.getMyUser();
                    }, 1000);
                })
                .catch((e) => {
                    console.log(e);
                });
        },
        async completeStep() {
            const completedSteps = await this.getOnBoardingCompletedSteps();
            const funnelType =
                completedSteps.find((step) => step.step_number === 1) || 0;

            if (funnelType.step_result_int === 62) {
                return await this.getMyUser();
            }
            await this.$router.push("/welcome/personal-info");
        },
        saveTelemetry(count) {
            this.dispatch("telemetry", {
                gatewayClient: this.$apollo,
                type: "on_boarding_completed",
                message: `Попытка #${
                    count + 1
                } получить on_boarding_completed=true`,
            });
        },
    },
};
</script>

<style lang="scss">
.create-error {
    display: flex;
    flex-direction: column;
    text-align: center;
    width: 100%;
    line-height: 1.5;
    margin-top: 12px;
    color: #b54848;
    font-size: 18px;

    &__link {
        color: #b54848;
        text-decoration: underline;
    }
}
</style>
